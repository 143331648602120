import React, { useMemo, useRef, useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CalendarToday } from '@material-ui/icons';
import { format } from 'date-fns';
import { CheckOptions } from '../../CheckOptions';
import { useStyles } from '../AddEditCouponSections.styles';
import { DatePicker } from '../../DatePicker';
import { generateList } from '../../../../utils/hours';

function DateTimeConditions({
  form: { getValues, setValue, control, getErrors },
}) {
  const classes = useStyles();

  const startDateEl = useRef();
  const endDateEl = useRef();

  const [popoverElStart, setPopoverElStart] = useState(null);
  const [popoverElEnd, setPopoverElEnd] = useState(null);

  const handleSetCustomDate = (date, fieldName) => {
    setValue(fieldName, date);
  };

  const hours = useMemo(
    () =>
      generateList().map((h) => (
        <MenuItem key={h.label} value={h.value}>
          {h.label}
        </MenuItem>
      )),
    []
  );

  return (
    <Box className={classes.sectionContent}>
      <Box className={clsx(classes.mb24, classes.alignItemsCenter)}>
        <Box className={classes.mr24}>
          <Typography
            variant="h6"
            className={classes.fieldLabel}
            ref={startDateEl}
          >
            Date valid start
          </Typography>
          <TextField
            onClick={() => setPopoverElStart(startDateEl.current)}
            data-test-id="start-date"
            value={format(getValues('startDate'), 'MMM d, y')}
            fullWidth
            variant="outlined"
            className={classes.defaultInput}
            InputProps={{
              endAdornment: <CalendarToday color="disabled" />,
            }}
          />
        </Box>
        <Box>
          <Typography
            variant="h6"
            className={classes.fieldLabel}
            ref={endDateEl}
          >
            Date valid end
          </Typography>
          <TextField
            onClick={() => setPopoverElEnd(endDateEl.current)}
            data-test-id="end-date"
            value={format(getValues('endDate'), 'MMM d, y')}
            fullWidth
            variant="outlined"
            className={classes.defaultInput}
            InputProps={{
              endAdornment: <CalendarToday color="disabled" />,
            }}
          />
        </Box>
      </Box>
      <Box className={clsx(classes.mb24, classes.alignItemsCenter)}>
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="specificDays"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                onChange={onChange}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>Specific days</Typography>
        </Box>
        <CheckOptions
          onChange={(value) => setValue('days', value)}
          disabled={!getValues('specificDays')}
          options={[
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ]}
        />
      </Box>
      <Box className={classes.alignItemsCenter}>
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="specificHours"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                onChange={onChange}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>Specific hours</Typography>
        </Box>

        <Box className={classes.mr24}>
          <Typography
            variant="h6"
            className={clsx(classes.fieldLabel, {
              [classes.op20]: !getValues('specificHours'),
            })}
          >
            Time valid start
          </Typography>
          <Controller
            render={({ field }) => (
              <Select
                disabled={!getValues('specificHours')}
                {...field}
                displayEmpty
                variant="outlined"
                {...getErrors('startTime')}
                className={classes.defaultInput}
              >
                {hours}
              </Select>
            )}
            name="startTime"
            control={control}
          />
        </Box>
        <Box>
          <Typography
            variant="h6"
            className={clsx(classes.fieldLabel, {
              [classes.op20]: !getValues('specificHours'),
            })}
          >
            Time valid end
          </Typography>
          <Controller
            render={({ field }) => (
              <Select
                disabled={!getValues('specificHours')}
                {...field}
                displayEmpty
                variant="outlined"
                {...getErrors('endTime')}
                className={classes.defaultInput}
              >
                {hours}
              </Select>
            )}
            name="endTime"
            control={control}
          />
        </Box>
      </Box>
      <DatePicker
        popoverEl={popoverElStart}
        handleClosePopover={() => setPopoverElStart(null)}
        customDate={getValues('startDate')}
        setCustomDate={(date) => handleSetCustomDate(date, 'startDate')}
      />
      <DatePicker
        popoverEl={popoverElEnd}
        handleClosePopover={() => setPopoverElEnd(null)}
        customDate={getValues('endDate')}
        setCustomDate={(date) => handleSetCustomDate(date, 'endDate')}
      />
    </Box>
  );
}

DateTimeConditions.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
  ),
};

export default DateTimeConditions;
