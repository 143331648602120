import { api } from './Api';

export const getCouponsConfig = async (businessId) => {
  const response = await api.get(`/coupons/${businessId}/config`);
  return response;
};

export const getRestaurantCouponsConfig = async (restaurantId) => {
  const response = await api.get(`/coupons/config/restaurant/${restaurantId}`);
  return response;
};

export const getBusinessCoupons = async (businessId) => {
  const response = await api.get(`/coupons/${businessId}`);
  return response;
};

export const deleteCoupon = async (couponId) => {
  const response = await api.delete(`/coupons/${couponId}`);
  return response;
};

export const addCoupon = async (couponData) => {
  const response = await api.post('/coupons', {
    ...couponData,
  });
  return response;
};
export const addPromosCall = async (promoData) => {
  const response = await api.post(`/promos/${promoData?.id}`, {
    ...promoData?.promos,
  });
  return response;
};
