import React, { useCallback } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { AddRounded, DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { useStyles } from './Slider.styles';
import ImageUpload from '../ImageUpload/ImageUpload';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

export function Slider({
  handleChange,
  slider,
  tab,
  setFiles,
  handleAddSlider,
  files,
  handleRemoveSlider,
  handleDrop,
  getErrors,
  disabled = false,
  form: { control },
}) {
  const classes = useStyles();

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleSaveSliderImage = useCallback(
    (data, index) => {
      const newFiles = [...files];
      newFiles[index] = data[0];
      setFiles([...newFiles]);
      handleDrop(data, index);
    },
    [handleDrop, setFiles]
  );

  return (
    <>
      {slider.map((_, i) => (
        <TabPanel key={i} value={tab} index={i} className={classes.tabPanel}>
          <Box className={classes.sliderWrapper}>
            <Box className={classes.sliderInfoWrapper}>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" className={classes.formTextFieldLabel}>
                  Slider image
                </Typography>
                <ImageUpload
                  setFile={(data) => handleSaveSliderImage(data, i)}
                  file={files[i]}
                  disabled={disabled}
                  text="Add slider image"
                  componentSize={{ width: '256px', height: '160px' }}
                  imageSize={{ width: 512, height: 320 }}
                  name={`slider-image-${i}`}
                />
              </Box>
              <Box width="50%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    className={classes.formTextFieldLabel}
                  >
                    Slide title
                  </Typography>
                </Box>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="slideTitle"
                      placeholder="Type slide title"
                      disabled={disabled}
                      fullWidth
                      className={classes.formInputBox}
                      {...getErrors(`slider[${i}].sliderTitle`)}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  )}
                  control={control}
                  name={`slider[${i}].sliderTitle`}
                />
                <Typography variant="h6" className={classes.formTextFieldLabel}>
                  Slide subtitle
                </Typography>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="slideSubtitle"
                      placeholder="Type slide subtitle"
                      disabled={disabled}
                      fullWidth
                      className={classes.formInputBox}
                      {...getErrors(`slider[${i}].sliderSubtitle`)}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  )}
                  control={control}
                  name={`slider[${i}].sliderSubtitle`}
                />
              </Box>
            </Box>
          </Box>
        </TabPanel>
      ))}
      <Box className={classes.actions}>
        <Box className={classes.tabsContainer}>
          <Tabs
            className={classes.tabs}
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            style={{ width: '100%' }}
          >
            {slider.map((_, i) => (
              <Tab
                className={classes.tab}
                key={i}
                data-test-id={`slider-${i}`}
                label={`Slider ${i + 1}`}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
          {slider.length < 6 && (
            <Button
              data-test-id="add-slider-button"
              onClick={handleAddSlider}
              disabled={disabled}
              className={classes.addTabBtn}
            >
              <AddRounded />
              Add another slide
            </Button>
          )}
        </Box>
        <Box>
          {slider.length > 3 && !disabled && (
            <Button
              data-test-id={`remove-slider-${tab}`}
              onClick={() => handleRemoveSlider(tab, slider.length)}
              className={clsx(classes.deleteSliderButton)}
              disabled={disabled}
            >
              <DeleteOutline fontSize="small" />
              <span>Delete this slider</span>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

Slider.propTypes = {
  handleChange: PropTypes.func,
  slider: PropTypes.array,
  tab: PropTypes.number,
  setFiles: PropTypes.func,
  handleAddSlider: PropTypes.func,
  files: PropTypes.array,
  form: PropTypes.shape({ control: PropTypes.object }),
  handleRemoveSlider: PropTypes.func,
  getErrors: PropTypes.func,
  handleDrop: PropTypes.func,
  disabled: PropTypes.bool,
};
