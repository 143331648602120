import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './Steps.styles';
import { useAddRestaurantContext } from '../../../../context/AddRestaurantContext';
import { SigningIn } from '../../../layout/SigningIn';
import LoginForm from '../../LoginForm';
import RegisterForm from '../../RegisterForm';

export function AppSetup({ form }) {
  const classes = useStyles();
  const [appSetupTab, setAppSetupTab] = useState(form.getValues('appSetupTab'));
  const { appSetupData, logout } = useAddRestaurantContext();

  const formOptions = [
    <LoginForm
      key="0"
      form={form}
      fieldNames={{
        email: 'appEmail',
        password: 'appPassword',
      }}
    />,
    <RegisterForm
      key="1"
      form={form}
      fieldNames={{
        firstName: 'appUserFirstName',
        lastName: 'appUserLastName',
        phone: 'appUserPhoneNumber',
        email: 'appUserEmail',
        password: 'appUserPassword',
      }}
    />,
  ];
  const selectedForm = formOptions[appSetupTab];

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleChangeTab = (_, newTab) => {
    setAppSetupTab(newTab);
    form.setValue('appSetupTab', newTab);
  };

  if (appSetupData?.isLoading) {
    return <SigningIn />;
  }

  if (appSetupData?.isAuthenticated) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>Already Signed In</Typography>
        <Button
          data-test-id="q1HZG5T4LOj2FqBWT0F2Q"
          onClick={() => logout('appSetup')}
          className={classes.logOutButton}
        >
          Logout
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Box marginBottom="16px" marginTop="-24px">
        <Tabs
          value={appSetupTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{ width: '100%' }}
        >
          <Tab data-test-id="login-tab" label="Login" {...a11yProps(0)} />
          <Tab data-test-id="register-tab" label="Register" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Typography variant="h5" className={classes.formTitle}>
        Restaurant App Setup
      </Typography>
      <Box>{selectedForm}</Box>
    </>
  );
}

AppSetup.propTypes = {
  form: PropTypes.object,
};

export default AppSetup;
