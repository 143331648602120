import React, { forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import { Typography, Card, CardActions, IconButton } from '@material-ui/core';
import { Check, Warning, Close } from '@material-ui/icons';
import { SnackbarContent } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { closeSnackbar as closeSnackbarAction } from '../../../redux/ducks/SnackbarDucks';

import { useStyles } from './SnackMessage.styles';

const SnackMessage = forwardRef(({ id, message, variant }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const IconVariant = useMemo(
    () =>
      ({
        error: <Warning className={classes.errorIcon} />,
        success: <Check className={classes.successIcon} />,
        warning: <Warning className={classes.warningIcon} />,
      }[variant]),
    [variant]
  );

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card
        className={clsx(classes.card, {
          [classes.errorCard]: variant === 'error',
          [classes.successCard]: variant === 'success',
          [classes.warningCard]: variant === 'warning',
        })}
      >
        <CardActions classes={{ root: classes.actionRoot }}>
          <div className={classes.message}>
            {IconVariant}
            <Typography variant="subtitle2" className={classes.typography}>
              {message}
            </Typography>
          </div>
          <div className={classes.icons}>
            <IconButton
              data-test-id="hw3_pfncEeDMZc11RJ2-x"
              color="inherit"
              aria-label="notification close icon"
              onClick={() => {
                dispatch(closeSnackbarAction(id));
              }}
            >
              <Close />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

SnackMessage.displayName = 'SnackMessage';

SnackMessage.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

SnackMessage.defaultProps = {
  variant: 'success',
};

export default SnackMessage;
