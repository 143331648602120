import React, { useCallback, useState } from 'react';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './StatusModal.styles';
import Modal from '../../layout/Modal';
import { publishRestaurant } from '../../../services/RestaurantsService';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';
import useNotifications from '../../../hooks/useNotifications';
import { statusLabel } from '../../../utils/FormatUtils/formatResponse';

function StatusModal({ open, setOpen }) {
  const classes = useStyles();
  const { restaurant, setRestaurant } = useRestaurantMenu();
  const [status, setStatus] = useState(true);
  const { enqueueSnackbar } = useNotifications();

  const handleSaveStatus = useCallback(async () => {
    try {
      const { status: newStatus } = await publishRestaurant({
        id: restaurant.id,
        publish: status,
      });
      setRestaurant({
        ...restaurant,
        status: statusLabel[newStatus],
        publishStatus: newStatus,
      });
    } catch {
      enqueueSnackbar({
        message: 'Something went wrong!',
        options: {
          variant: 'error',
        },
      });
    } finally {
      setOpen(false);
    }
  }, [status, restaurant, setRestaurant]);

  return (
    <Modal
      open={open}
      onConfirm={handleSaveStatus}
      onClose={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      title="Edit restaurant status"
      confirmBtnText="Edit status"
    >
      <Typography className={classes.selectLabel}>Restaurant status</Typography>
      <FormControl className={classes.select}>
        <Select
          variant="outlined"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          data-test-id="select-wrapper"
        >
          <MenuItem value>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
    </Modal>
  );
}

StatusModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default StatusModal;
