import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    margin: '0 auto',
    padding: '48px 32px',
    maxWidth: '960px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '24px',
    border: '1px solid #E0E0E0',
    width: '100%',
    height: 'max-content',

    [theme.breakpoints.down(732)]: {
      margin: '0 8px',
      maxWidth: 'calc(100% - 16px)',
    },
  },
}));
