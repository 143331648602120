import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Typography, Box } from '@material-ui/core';

import clsx from 'clsx';
import * as yup from 'yup';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';

import { useStyles } from './SignInForm.styles';

const FormSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email'),
  password: yup.string().required('Password is required'),
});

function SignInForm({ onSubmit, isLoading }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.contents}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Form
          schema={FormSchema}
          className={classes.form}
          noValidate
          onSubmit={onSubmit}
          aria-label="login form"
        >
          <Input
            className={clsx(classes.input, classes.lowerCase)}
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            placeholder="Type your email"
            autoComplete="email"
            autoFocus
            inputProps={{ 'data-test-id': 'login:email-input' }}
          />
          <Input
            className={clsx(classes.input, classes.lowerCase)}
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            placeholder="Type your password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputProps={{ 'data-test-id': 'login:password-input' }}
          />
          <Box display="flex" justifyContent="center">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={isLoading}
              data-test-id="login:button"
              aria-label="sign in button"
            >
              Sign In
            </Button>
          </Box>

          <Grid
            className={classes.forgotPassword}
            container
            display="flex"
            justifyContent="center"
          >
            <Grid item>
              <Link to="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Box>
  );
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

SignInForm.defaultProps = {
  isLoading: false,
};

export default SignInForm;
