import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
} from '@material-ui/core';
import { Backup, Delete, Image as ImageIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useStyles } from './Steps.styles';
import storage from '../../../../utils/storage';
import { DELAY } from '../../../../constants/store';
import UserGallery from '../../../layout/UserGallery';

export function Preview({ file, handleDeletePhoto }) {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginRight="12px"
      key={file.id || file.name}
    >
      <div className={classes.dropzoneThumb}>
        {file?.mime.split('/')[0] === 'image' ? (
          <>
            {!isLoaded && <CircularProgress data-test-id="progress" />}
            <img
              data-test-id={`${file.id}`}
              alt=""
              src={file.url}
              onLoad={() => setIsLoaded(true)}
              className={clsx(classes.dropzoneImg, classes.uploadError, {
                [classes.showImage]: !isLoaded,
              })}
            />
          </>
        ) : (
          <ImageIcon data-test-id="icon" />
        )}
      </div>
      <Button
        data-test-id={`delete-${file.id}`}
        className={classes.dropzoneDelete}
        onClick={handleDeletePhoto}
      >
        <Delete />
        <p>DELETE</p>
      </Button>
    </Box>
  );
}

Preview.propTypes = {
  file: PropTypes.object,
  handleDeletePhoto: PropTypes.func,
};

export function UploadMenu({ form: { getValues, setValue, getErrors } }) {
  const classes = useStyles();
  const [files, setFiles] = useState(
    getValues('menu')?.length > 0 ? getValues('menu') : []
  );
  const [menuUrl, setMenuUrl] = useState(getValues('menu_url') || '');

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const handleChangeMenuUrl = (e) => {
    setMenuUrl(e.target.value);
    if (e.target.value === '') {
      setValue('menu_url', null);
    } else {
      setValue('menu_url', e.target.value);
    }
  };

  const handleCloseGallery = () => {
    setIsGalleryOpen(false);
  };

  const handleOpenGallery = () => {
    setIsGalleryOpen(true);
  };

  const handleDrop = async (data) => {
    const values = getValues('menu') || [];
    await setValue('menu', [...values, ...data]);
    setFiles((old) => [...old, ...data]);
    await storage.set(
      'restaurantInfo',
      {
        ...getValues(),
        loginOwnerPassword: '',
        OwnerPassword: '',
        appPassword: '',
        appUserPassword: '',
      },
      DELAY
    );
  };

  const handleDeletePhoto = useCallback(
    async (i) => {
      const newFiles = [...files];
      newFiles.splice(i, 1);
      setFiles(newFiles);
      setValue('menu', newFiles);
      await storage.set(
        'restaurantInfo',
        {
          ...getValues(),
          loginOwnerPassword: '',
          OwnerPassword: '',
          appPassword: '',
          appUserPassword: '',
        },
        DELAY
      );
    },
    [files, getValues, setValue]
  );

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Upload Menu
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Upload menu, so our experts can fill the menu for you and save your
        time.
      </Typography>
      <Box minHeight="96px" display="flex" flexDirection="column">
        <Box
          flex="1"
          display="flex"
          style={{
            cursor: 'pointer',
          }}
          data-test-id="open-gallery-btn"
          name="menuDiv"
          onClick={handleOpenGallery}
        >
          <section
            style={{
              display: 'flex',
              border: '1px solid #E0E0E0',
              borderRadius: '4px 0 0 4px',
              height: 96,
              width: 96,
              background: '#F4F4F4',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Backup />
          </section>
          <section
            style={{
              display: 'flex',
              border: '1px dashed #E0E0E0',
              borderLeft: 'none',
              borderRadius: '0 4px 4px 0',
              flex: 1,
              background: '#FDFDFD',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <p style={{ color: '#00000099' }}>
              Upload menu (pdf, jpg, png, word, etc.)
            </p>
          </section>
        </Box>
        {getErrors('menu')?.error && (
          <Typography className={classes.error}>
            You must upload at least one file
          </Typography>
        )}
        <Box className={classes.formContainerMenuUrl}>
          <Typography
            variant="h6"
            className={clsx(
              classes.formTextFieldLabel,
              classes.formInputLabelMenuUrl
            )}
          >
            Is your menu online? Enter the web address here
          </Typography>
          <TextField
            variant="outlined"
            placeholder="ex: www.restaurant.eatzy.net/menu"
            value={menuUrl}
            onChange={handleChangeMenuUrl}
            className={classes.formInputMenuUrl}
            name="site"
            data-test-id="menu-url-textfield"
          />
          {getErrors('menu_url')?.error && (
            <Typography className={classes.error}>
              {getErrors('menu_url')?.helperText}
            </Typography>
          )}
        </Box>
        <aside className={classes.dropzoneImgWrapper}>
          {files.map((file, i) => (
            <Preview
              key={i}
              file={file}
              handleDeletePhoto={() => handleDeletePhoto(i)}
            />
          ))}
        </aside>
      </Box>
      {isGalleryOpen && (
        <UserGallery
          open={isGalleryOpen}
          onClose={handleCloseGallery}
          setFile={handleDrop}
          width={800}
          height={450}
        />
      )}
    </>
  );
}

UploadMenu.propTypes = {
  form: PropTypes.object,
};

export default UploadMenu;
