import { Controller } from 'react-hook-form';
import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom({ inputRef, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="(###) ###-####"
      placeholder="Main Phone Number"
      mask="_"
      type="tel"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.any,
};

export function PhoneNumberInput({
  control,
  name,
  getErrors,
  classes,
  disabled = false,
}) {
  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          disabled={disabled}
          fullWidth
          className={classes.formInputBox}
          variant="outlined"
          {...getErrors(`${name}`)}
        />
      )}
      name={name}
      control={control}
    />
  );
}

PhoneNumberInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  getErrors: PropTypes.func,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
};
