import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './UsersSetup.styles';
import AddUserModal from '../../input/AddUserModal';
import Modal from '../Modal';
import {
  goToStep,
  selectUser,
  toggleModal,
  deleteUserRequest,
} from '../../../redux/ducks/AppUserDucks';
import { permissions } from '../../../constants/permissions';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';

function UserSkeleton() {
  const classes = useStyles();
  return (
    <Box className={classes.user}>
      <Skeleton width={100} />
      <Skeleton width={200} />
      <Skeleton width={150} />
      <Box className={classes.lastCell}>
        <Skeleton width={70} />
      </Box>
    </Box>
  );
}

function User({ userInfo, handleEditUser, handleDeleteUser }) {
  const classes = useStyles();
  return (
    <Box className={classes.user}>
      {/* <Checkbox className={classes.checkbox} /> */}
      <Typography className={classes.cell}>
        {userInfo?.firstName} {userInfo?.lastName}
      </Typography>
      <Typography className={clsx(classes.cell, classes.boldText)}>
        {userInfo?.email}
      </Typography>
      <Typography
        className={clsx(classes.cell, classes.boldText, classes.blueText)}
      >
        {permissions[userInfo?.permission].label}
      </Typography>
      <Box className={classes.lastCell}>
        <IconButton
          className={classes.actionBtn}
          data-test-id={`edit-user-${userInfo?.id}`}
          onClick={() => handleEditUser(userInfo)}
        >
          <Edit />
        </IconButton>

        <IconButton
          className={classes.actionBtn}
          data-test-id={`delete-user-${userInfo?.id}`}
          onClick={handleDeleteUser}
        >
          <Delete className={classes.deleteBtnIcon} />
        </IconButton>
      </Box>
    </Box>
  );
}

User.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    permission: PropTypes.string,
  }).isRequired,
  handleEditUser: PropTypes.func.isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
};

export function UsersSetup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const { restaurant } = useRestaurantMenu();

  const selectedUser = useSelector((state) => state.appUser.selectedUser);
  const userInfo = useSelector((state) => state.appUser.usersList);
  const isLoading = useSelector((state) => state.appUser.isLoading);

  const setStep = (newStep) => dispatch(goToStep({ step: newStep }));
  const setSelectedUser = (user) => dispatch(selectUser({ user }));
  const setAddUserModal = (open) => dispatch(toggleModal({ open }));

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setAddUserModal(true);
    setStep('setPermission');
  };

  const handleAddNewUser = () => {
    setSelectedUser({});
    setAddUserModal(true);
    setStep('search');
  };

  const handleDeleteUser = useCallback(() => {
    setDeleteDialog(false);
    dispatch(
      deleteUserRequest({
        restaurantId: restaurant.id,
        userId: selectedUser.userId,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        permission: selectedUser.permission,
      })
    );
  }, [selectedUser, restaurant]);

  const handleClickDeleteUserButton = (user) => {
    setSelectedUser(user);
    setDeleteDialog(true);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography className={classes.title}>Users</Typography>
        <Button
          data-test-id="0XZVZOIgKjljhTX-VFQfV"
          className={classes.addUserBtn}
          onClick={handleAddNewUser}
        >
          Add new user
          <Add className={classes.addUserBtnIcon} />
        </Button>
      </Box>
      {isLoading || userInfo.length > 0 ? (
        <Box className={classes.table}>
          <Box className={classes.tableHeader}>
            <Typography className={classes.columnName}>Name</Typography>
            <Typography className={classes.columnName}>E-mail</Typography>
            <Typography className={classes.columnName}>
              Permission access
            </Typography>
            <Typography className={classes.lastColumnName}>Actions</Typography>
          </Box>
          {isLoading ? (
            <>
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
            </>
          ) : (
            userInfo.map((userInfo) => (
              <User
                key={userInfo.id}
                userInfo={userInfo}
                handleEditUser={handleEditUser}
                handleDeleteUser={() => handleClickDeleteUserButton(userInfo)}
              />
            ))
          )}
        </Box>
      ) : (
        <Box className={classes.noUsersFoundText}>
          <Typography variant="h3">No users found</Typography>
        </Box>
      )}

      <AddUserModal />
      <Modal
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        onCancel={() => setDeleteDialog(false)}
        confirmBtnText="Delete user"
        onConfirm={handleDeleteUser}
        actionBtnColor="secondary"
        title="Delete user"
      >
        Are you sure you want to delete {selectedUser?.firstName}{' '}
        {selectedUser?.lastName}?
      </Modal>
    </Box>
  );
}

export default UsersSetup;
