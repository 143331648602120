import { makeStyles, darken } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    margin: '0 auto',
    padding: '48px 32px',
    maxWidth: '960px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '24px',
    border: '1px solid #E0E0E0',

    [theme.breakpoints.down(732)]: {
      margin: '0 8px',
      maxWidth: 'calc(100% - 16px)',
    },
  },
  content: {
    display: 'flex',
    '& > div:first-child': {
      marginRight: '48px',
    },

    [theme.breakpoints.down(735)]: {
      display: 'block',
      width: '100%',
      '& > div:first-child': {
        marginRight: '0px',
      },
    },
  },
  formTitle: {
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,

    '& > div > input': {
      padding: '14px 16px',
    },
  },
  autocompleteInput: {
    padding: '14px 16px',
  },
  addTagButton: {
    background: '#3C55E8',
    color: '#fff',
    borderRadius: '4px',
    fontWeight: 'medium',
    marginLeft: '30px',
    padding: '11px 28px',
    alignSelf: 'first baseline',
    minWidth: '122px',

    '&:hover': {
      background: darken('#3C55E8', 0.25),
    },

    [theme.breakpoints.down(735)]: {
      width: '163px',
      minWidth: 'unset',
      padding: '11px 10px',
      marginLeft: '10px',
    },
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    border: '1px solid #0000001F',
    background: '#FAFAFA',
    marginRight: '14px',
    borderRadius: '16px',
    textTransform: 'capitalize',
  },
  saveButton: {
    background: '#3C55E8',
    color: '#fff',
    borderRadius: '4px',
    fontWeight: 500,
    marginLeft: '30px',
    padding: '6px 12px',
    alignSelf: 'end',
    minWidth: '122px',
    textTransform: 'uppercase',

    '& > span > svg': {
      display: 'none',
    },

    '&:hover': {
      background: darken('#3C55E8', 0.25),
    },

    [theme.breakpoints.down(735)]: {
      minWidth: 'unset',
      width: '50px',
      height: '50px',
      position: 'fixed',
      bottom: '90px',
      right: '30px',
      borderRadius: '30%',
      zIndex: '999',
      '& > span > p': {
        display: 'none',
      },
      '& > span > svg': {
        display: 'block',
      },
    },
  },
  editButton: {
    background: 'rgb(238, 235, 253)',
    color: '#3C55E8',
    borderRadius: '4px',
    fontWeight: 500,
    marginLeft: '30px',
    padding: '6px 12px',
    alignSelf: 'end',
    minWidth: '122px',
    textTransform: 'uppercase',

    '&:hover': {
      background: darken('rgb(238, 235, 253)', 0.25),
    },

    [theme.breakpoints.down(735)]: {
      minWidth: 'unset',
      width: '50px',
      height: '50px',
      position: 'fixed',
      bottom: '30px',
      right: '30px',
      borderRadius: '30%',
      zIndex: '999',
      '& > span > p': {
        display: 'none',
      },
    },
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '472px',
    minHeight: '38px',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row-reverse',

      '& > h6': {
        marginRight: 8,
      },
    },
  },
}));
