import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

const NavigationContext = createContext();

const reducer = (state, action) => {
  const actions = {
    next: steps[state.index + 1],
    back: state.index > 0 ? steps[state.index - 1] : state,
    skip: steps[state.index + action.steps],
    goto: steps[action.step],
  };
  return actions[action.type];
};

export const stepsDefinition = {
  INFO: 0,
  LOGIN: 1,
  HOURS: 2,
  PRICE_LEVEL: 3,
  UPLOAD_MENU: 4,
  SEO_CONTENT: 5,
  APP_SETUP: 6,
  SETUP_SUCCESS: 7,
  STRIPE_CONNECT: 8,
  STRIPE_SUCCESS: 9,
};

export const steps = [
  {
    index: 0,
    id: 'INFO',
    name: 'Restaurant Info',
  },
  {
    id: 'LOGIN',
    name: 'Owner Info',
    index: 1,
  },
  {
    id: 'HOURS',
    name: 'Business Hours',
    index: 2,
  },
  {
    id: 'PRICE_LEVEL',
    name: 'Price Setup',
    index: 3,
  },
  {
    id: 'UPLOAD_MENU',
    name: 'Upload Menu',
    index: 4,
  },
  {
    id: 'SEO_CONTENT',
    name: 'Web Content',
    index: 5,
  },
  {
    id: 'APP_SETUP',
    name: 'App Setup',
    index: 6,
  },
  {
    id: 'SETUP_SUCCESS',
    name: 'Complete',
    index: 7,
  },
  {
    id: 'STRIPE_CONNECT',
    name: 'Connect',
    index: 8,
  },
  {
    id: 'STRIPE_SUCCESS',
    name: 'Connected',
    index: 9,
  },
];

export function NavigationProvider({
  children,
  initialStep = steps[stepsDefinition.INFO],
}) {
  const [step, dispatch] = useReducer(reducer, initialStep);

  const nextPage = () => dispatch({ type: 'next' });
  const previousPage = () => dispatch({ type: 'back' });
  const skipPage = (steps) => dispatch({ type: 'skip', steps });
  const goToStep = (step) => dispatch({ type: 'goto', step });

  return (
    <NavigationContext.Provider
      value={{ step, nextPage, previousPage, skipPage, goToStep }}
    >
      {children}
    </NavigationContext.Provider>
  );
}

export const useNavigation = () => useContext(NavigationContext);

NavigationProvider.propTypes = {
  children: PropTypes.node,
  initialStep: PropTypes.object,
};
