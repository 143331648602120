import React from 'react';
import { Box } from '@material-ui/core';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import PropTypes from 'prop-types';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadowIcon from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { useStyles } from './Map.styles';

const leafletMarkerIcon = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadowIcon,
  popupAnchor: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [26, 42],
  iconAnchor: [13, 42],
});

function LocationMarker({ position, setPosition, setValue, disabled }) {
  const map = useMapEvents({
    click(e) {
      if (!disabled) {
        setPosition(e.latlng);
        setValue('address.location', e.latlng);
      }
    },
  });

  if (position) map.flyTo(position, map.getZoom() > 13 ? map.getZoom() : 13);

  return position === null ? null : (
    <Marker icon={leafletMarkerIcon} position={position} />
  );
}

LocationMarker.propTypes = {
  position: PropTypes.object,
  setPosition: PropTypes.func,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
};

export function Map({
  position,
  setPosition,
  setValue,
  mapContainerClass,
  mapStyle,
  disabled = false,
}) {
  const classes = useStyles();
  return (
    <Box className={mapContainerClass || classes.mapContainer}>
      <MapContainer
        center={position || [0, 0]}
        zoom={position ? 13 : 0}
        className={mapStyle || classes.mapStyle}
        data-test-id="map-container"
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker
          position={position}
          setPosition={setPosition}
          setValue={setValue}
          disabled={disabled}
        />
      </MapContainer>
    </Box>
  );
}

Map.propTypes = {
  position: PropTypes.object,
  setPosition: PropTypes.func,
  setValue: PropTypes.func,
  mapContainerClass: PropTypes.string,
  mapStyle: PropTypes.string,
  disabled: PropTypes.bool,
};
