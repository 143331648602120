import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 480,

    [theme.breakpoints.down(732)]: {
      width: 350,
    },

    backgroundColor: theme.palette.background.paper,
    border: '1px solid #D5D5D5',
    boxShadow: theme.shadows[5],
    borderRadius: '4px',
  },
  header: {
    padding: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiTypography-root': {
      fontSize: '16px',
      color: '#666666',
    },

    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  body: {
    padding: '32px 14px',
  },
  footer: {
    padding: '12px 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    padding: '8px 14px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'bold',
    border: '1px solid #D5D5D5',
    borderRadius: '4px',

    color: '#666666',
  },
  select: {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      borderRadius: '2px',
    },
  },
  selectLabel: {
    marginLeft: '12px',
    marginBottom: '4px',
    fontSize: '12px',
    lineHeight: '15px',
  },
  formInputBox: {
    marginBottom: 24,
    background: '#FAFAFA',
    boxSizing: 'border-box',
    borderRadius: '2px',

    '& input': {
      padding: '14px 16px',
      borderRadius: '2px',
      border: '1px solid #B5B5B5',
      fontSize: '16px',
      color: '#000000',
    },

    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    color: '#B5B5B5',
  },
  checkboxLabel: {},
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  userNotfound: {
    fontSize: '14px',
    lineHeight: '140%',
  },
  emailInfo: {
    color: theme.palette.primary.main,
  },
  userInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
  },
  userIcon: {
    background: '#FAFAFA',
    border: '1px solid #D5D5D5',
    borderRadius: '4px',
    padding: '14px',
    marginRight: '16px',

    fontSize: '16px',
    lineHeight: '20px',
  },
  userInfo: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  op60: {
    opacity: '0.6',
  },
  formSelectInputBox: {
    background: '#FAFAFA',
    borderRadius: '2px',
    padding: '0px',
    fontSize: '16px',

    '&:hover fieldset': {
      border: '1px solid #D5D5D5 !important',
    },

    '& fieldset': {
      border: '1px solid #D5D5D5',
    },

    '& .MuiOutlinedInput-root:hover fieldset': {
      border: '1px solid #D5D5D5',
    },

    '& .MuiOutlinedInput-root fieldset': {
      border: '1px solid #D5D5D5',
    },

    '& .MuiInputBase-root': { borderRadius: '2px', fontSize: '16px' },
  },
}));
