/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Switch,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Cancel } from '@material-ui/icons';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { getLocation } from '../../../../services/LocationService';
import { useStyles } from './Steps.styles';
import { AddressModal } from '../../AddressModal/AddressModal';
import { Map } from '../../Map/Map';
import { PhoneNumberInput } from '../../PhoneNumberInput/PhoneNumberInput';

export function RestaurantInfo({
  form: {
    control,
    getErrors,
    setValue,
    getValues,
    reset,
    template,
    businessId,
    watch,
  },
}) {
  watch();
  const classes = useStyles();
  const [position, setPosition] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [searching, setSearching] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState('');
  const [address, setAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
  });
  const phoneRef = useRef(null);
  const [tags, setTags] = useState([]);

  const handleOpenModal = () => {
    setLocationError(false);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearchAddress = useCallback(
    async (address) => {
      setSearching(true);
      try {
        const response = await getLocation(address);
        setPosition(response.location);
        setValue('address', response);
        setFormattedAddress(response.formattedAddress);
        handleCloseModal();
        setSearching(false);
      } catch (err) {
        setValue('address', address);
        setLocationError(true);
        setSearching(false);
      }
    },
    [setValue]
  );

  useEffect(
    () => {
      const pos = getValues('address');
      const tags = getValues('tags');
      if (pos && (pos.location?.lat !== 0 || pos.location?.lng !== 0))
        setPosition(pos.location);
      pos && setAddress(pos);
      tags && setTags(getValues('tags'));
    },
    // eslint-disable-next-line
    [getValues]
  );

  useEffect(() => {
    if (businessId && businessId[0]) setValue('businessId', businessId[0].id);
  }, [businessId, setValue]);

  const handleAddTag = () => {
    if (getValues('rawTags')) {
      const newTags = tags;
      newTags.push(
        ...getValues('rawTags')
          .split(',')
          .map((word) => word.trim())
      );
      setTags(newTags);
      reset({ ...getValues(), tags: newTags, rawTags: '' });
    }
  };

  const handleDeleteTag = (i) => {
    const newTags = tags;
    newTags.splice(i, 1);
    setTags(newTags);
    reset({ ...getValues(), tags: newTags });
  };

  const getBusinessName = useCallback(
    (id) => {
      if (!businessId?.length) return null;
      return businessId?.filter((ev) => ev?.id === id)?.[0]?.name;
    },
    [businessId]
  );

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Restaurant Info
      </Typography>
      <Box className={classes.formContent}>
        <Box flex={1}>
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Restaurant name
          </Typography>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                placeholder="Restaurant Name"
                className={classes.formInputBox}
                {...getErrors('name')}
              />
            )}
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Restaurant address
          </Typography>
          <Controller
            name="address.formattedAddress"
            control={control}
            render={({ field: { value, ...props } }) => (
              <TextField
                data-test-id="rMpxqTONxRLoNlahFAWj_"
                {...props}
                value={formattedAddress}
                fullWidth
                variant="outlined"
                name="address.formattedAddress"
                placeholder="Restaurant Address"
                className={classes.formInputBox}
                onClick={handleOpenModal}
                onKeyDown={(e) => e.key !== 'Tab' && handleOpenModal()}
                onBlur={() => !openModal && handleCloseModal()}
                {...getErrors('address.formattedAddress')}
              />
            )}
          />
          <AddressModal
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            searching={searching}
            locationError={locationError}
            handleSearchAddress={handleSearchAddress}
            address={address}
            setAddress={setAddress}
          />
          <Typography
            ref={phoneRef}
            variant="h6"
            className={classes.formTextFieldLabel}
          >
            Phone number
          </Typography>
          <PhoneNumberInput
            control={control}
            name="phone"
            getErrors={getErrors}
            classes={classes}
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Business ID
          </Typography>
          <Controller
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  value={getValues('businessId')}
                  variant="outlined"
                  placeholder="businessId"
                  {...getErrors('businessId')}
                  className={clsx(classes.selectBox, {
                    [classes.placeholder]: !getValues('businessId'),
                    [classes.selectError]: getErrors('businessId')?.error,
                  })}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected || selected?.length === 0) {
                      return <em>Select business ID</em>;
                    }

                    return getBusinessName(selected);
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Select business ID</em>
                  </MenuItem>
                  {businessId &&
                    businessId?.map((ev) => (
                      <MenuItem value={ev?.id} key={ev?.id}>
                        {ev?.name}
                      </MenuItem>
                    ))}
                </Select>
                {getErrors('businessId')?.error &&
                  getErrors('businessId')?.helperText && (
                    <FormHelperText error className={classes.selectErrorFont}>
                      {getErrors('businessId').helperText}
                    </FormHelperText>
                  )}
              </>
            )}
            name="businessId"
            control={control}
          />
        </Box>
        <Map
          mapContainerClass={classes.mapContainer}
          mapStyle={classes.mapStyle}
          position={position}
          setPosition={setPosition}
          setValue={setValue}
        />
      </Box>
      <Box>
        <Box className={classes.switchWrapper}>
          <Controller
            name="haveSite"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ...props } }) => (
              <Switch
                color="primary"
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            I have an existing website
          </Typography>
        </Box>
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          What is the domain name of your website?
        </Typography>
        <Controller
          control={control}
          name="site"
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              placeholder="ex: www.chowfoodandbeverage.com"
              className={clsx(classes.formInputBox, classes.formInputEnd)}
              name="site"
              disabled={!watch('haveSite')}
              {...getErrors('site')}
            />
          )}
        />
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Cuisine category
        </Typography>
        <Box marginBottom="24px">
          <Controller
            render={({ field: { onChange, ref } }) => (
              <Autocomplete
                options={template?.categories || []}
                data-test-id="cuisine-autocomplete"
                freeSolo
                disablePortal
                blurOnSelect
                autoSelect
                ListboxProps={{
                  style: { maxHeight: '30vh', textTransform: 'capitalize' },
                }}
                renderInput={({ inputProps, ...params }) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Cuisine Type"
                    className={clsx(classes.formInputBox, classes.formInputEnd)}
                    style={{ marginBottom: 0 }}
                    inputProps={{
                      ...inputProps,
                      style: {
                        padding: '5px',
                        textTransform: 'capitalize',
                      },
                    }}
                    {...getErrors('cuisine')}
                  />
                )}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                ref={ref}
              />
            )}
            defaultValue={getValues('cuisine')}
            name="cuisine"
            control={control}
          />
        </Box>
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Tags
        </Typography>
        <Box display="flex" alignItems="center">
          <Box style={{ width: '50%' }}>
            <Controller
              render={({ field: { onChange, ref } }) => (
                <Autocomplete
                  options={template?.tags || []}
                  data-test-id="tags-autocomplete"
                  freeSolo
                  disablePortal
                  blurOnSelect
                  autoSelect
                  ListboxProps={{
                    style: { maxHeight: '30vh', textTransform: 'capitalize' },
                  }}
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Type tags like “Pizza”, “Tacos”, “Lobster Ro…”"
                      fullWidth
                      className={clsx(classes.formInputBox)}
                      style={{ marginBottom: 0 }}
                      inputProps={{
                        ...inputProps,
                        style: {
                          padding: '5px',
                          textTransform: 'capitalize',
                        },
                      }}
                      {...getErrors('tags')}
                    />
                  )}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  value={getValues('rawTags')}
                  ref={ref}
                />
              )}
              defaultValue={getValues('rawTags')}
              name="rawTags"
              control={control}
            />
          </Box>
          <Button
            data-test-id="add-tag"
            className={classes.addTagButton}
            onClick={handleAddTag}
          >
            ADD TAG
          </Button>
        </Box>
        <Box display="flex" marginTop="14px">
          {tags.map((tag, i) => (
            <Box data-test-id={`tag-${i}`} className={classes.tags} key={tag}>
              {tag}
              <IconButton
                data-test-id={`delete-tag-${i}`}
                style={{ padding: 0, marginLeft: '8px' }}
                onClick={() => handleDeleteTag(i)}
              >
                <Cancel style={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          ))}
        </Box>
        <Typography
          variant="h6"
          className={classes.formTextFieldLabel}
          style={{ marginTop: '68px' }}
        >
          Tagline or Brief Description
        </Typography>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              id="description"
              placeholder="Quick, healthy Vietnamese food with a modern twist"
              className={clsx(
                classes.formInputBox,
                classes.formInputEnd,
                classes.descriptionMultiline
              )}
              minRows={2}
              maxRows={2}
              multiline
              inputProps={{
                style: { fontSize: '16px' },
              }}
              {...getErrors('description')}
            />
          )}
        />
      </Box>
    </>
  );
}

RestaurantInfo.propTypes = {
  form: PropTypes.object,
};

export default RestaurantInfo;
