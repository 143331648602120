import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './CheckOptions.styles';

export function CheckOptions({
  options,
  isRadio,
  defaultValue,
  disabled,
  onChange,
}) {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);

  useEffect(() => {
    if (isRadio) onChange(...selectedOptions);
    else onChange(selectedOptions);
  }, [selectedOptions]);

  const handleOptionSelected = (op) => {
    if (disabled) return;

    if (isRadio) {
      setSelectedOptions([op]);
      return;
    }

    const alreadySelected = selectedOptions.some((option) => option === op);
    if (alreadySelected) {
      setSelectedOptions(selectedOptions.filter((option) => option !== op));
    } else {
      setSelectedOptions([op, ...selectedOptions]);
    }
  };

  return (
    <Box className={classes.container}>
      {options.map((op) => (
        <Box
          data-test-id="AY45IbjOgfTTONNkuufwT"
          key={op}
          onClick={() => handleOptionSelected(op)}
        >
          <Typography
            className={clsx(classes.option, {
              [classes.selected]: selectedOptions.includes(op),
              [classes.disabled]: disabled,
            })}
          >
            {op}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

CheckOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  isRadio: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckOptions.defaultProps = {
  options: [],
  isRadio: false,
  defaultValue: [],
  disabled: false,
  onChange: () => null,
};
