import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',

    [theme.breakpoints.down(670)]: {
      flexDirection: 'column',

      '& > div': {
        width: '100%',
      },
    },
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  addImageBtn: {
    marginTop: '13px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderRadius: '4px',
    border: '1px solid #D5D5D5',
  },
  addImageBtnText: {
    color: 'inherit',
    fontWeight: 'bold',
    marginTop: '3px',
  },
  addImageBtnIcon: {
    color: 'inherit',
    marginRight: '8px',
    fontSize: '18px',
  },
}));
