import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    backgroundColor: '#fafafa',
    paddingBottom: '40px',
    bottom: '0px',
    width: '716px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '16px 32px',
      zIndex: '1001',
    },
  },
  navigationButton: {
    padding: '19px 26px',
    background: 'rgba(67, 87, 239, 0.1)',
    color: '#3C55E8',
    marginRight: 32,
    textTransform: 'uppercase',

    '&:hover': {
      background: 'rgba(67, 87, 239, 0.15)',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '12px 14px',
      marginRight: '24px',
    },
  },
  hide: {
    visibility: 'hidden',
  },
  nextButton: {
    padding: '17px 107px',
    background: '#3C55E8',
    color: '#fff',
    textTransform: 'uppercase',

    '&:hover': {
      background: '#3C55E8',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '12px 32px',
    },
  },
  gone: {
    display: 'none',
  },
}));
