import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto 100px',
    maxWidth: 716,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down(732)]: {
      margin: '0 8px',
      maxWidth: 'calc(100% - 16px)',
    },

    '& > form': {
      display: 'flex',
      flex: 1,
    },
  },
  formTitle: {
    marginBottom: 32,
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
    fontSize: 12,
  },
  formInputBox: {
    borderRadius: 4,
    border: '1px solid #0000001F',
    marginBottom: 24,

    '& > * > *': {
      border: 'none',
    },

    '& > div': {
      paddingLeft: 16,
      borderRadius: 8,
    },

    '& > div > div > svg': {
      fill: 'rgba(42, 51, 40, 0.45)',
    },

    '& > div > input': {
      fontWeight: 500,
      fontSize: 14,
      padding: '14px 16px',
      paddingLeft: '0',
    },
  },

  component: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '100%',

    [theme.breakpoints.down(670)]: {
      marginBottom: '80px',
    },
  },
  container: {
    padding: '48px 32px',
    border: '1px solid #E0E0E0',
    background: '#fff',
    borderRadius: 24,
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',

    '&:not(:last-child)': {
      marginBottom: 24,
    },

    [theme.breakpoints.down(670)]: {
      overflowX: 'unset',
      overflowY: 'unset',
      maxHeight: 'none',
      padding: '40px 14px',
    },
  },
  removeJustify: {
    justifyContent: 'unset',
  },
}));
