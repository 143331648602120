import { combineReducers } from 'redux';
import user from './UserDucks';
import snackbar from './SnackbarDucks';
import coupon from './CouponDucks';
import appUser from './AppUserDucks';

export default combineReducers({
  user,
  snackbar,
  coupon,
  appUser,
});
