import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export function Error({ className }) {
  return (
    <Box className={className}>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography
          style={{
            fontSize: '24px',
            fontWeight: '600',
          }}
        >
          Error loading info. Try again later
        </Typography>
      </Box>
    </Box>
  );
}

Error.propTypes = {
  className: PropTypes.string,
};
