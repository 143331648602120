import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  coupons: null,
  deletedCoupon: false,
  couponToAdd: null,
  couponAdded: null,
  promoToAdd: null,
  promoAdded: null,
  menus: {},
  items: [],
  categories: [],
  isLoading: false,
  error: null,
  config: null,
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    couponsConfigRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    couponsConfigSuccess(state, action) {
      return {
        ...state,
        config: action.payload.config,
        isLoading: false,
        error: null,
      };
    },
    getRestaurantMenuRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getRestaurantMenuSuccess(state, action) {
      return {
        ...state,
        menus: {
          ...state.menus,
          ...action.payload.restaurantConfig,
        },
        items: [...state.items, ...action.payload.items],
        categories: [...state.categories, ...action.payload.categories],
        isLoading: false,
        error: null,
      };
    },
    getBusinessCouponsRequest(state) {
      return {
        ...state,
        deletedCoupon: false,
        couponToAdd: null,
        couponAdded: null,
        isLoading: true,
      };
    },
    getBusinessCouponsSuccess(state, action) {
      return {
        ...state,
        coupons: action.payload.coupons,
        isLoading: false,
        error: null,
      };
    },
    removeRestaurantMenu(state, action) {
      return {
        ...state,
        items: state?.items?.filter(
          (item) => item.restId !== action.payload.restaurantId
        ),
        categories: state?.categories?.filter(
          (category) => category.restId !== action.payload.restaurantId
        ),
      };
    },
    deleteCoupon(state) {
      return {
        ...state,
        deletedCoupon: false,
        isLoading: true,
      };
    },
    couponDeleted(state) {
      return {
        ...state,
        deletedCoupon: true,
        isLoading: false,
        error: null,
      };
    },
    addCoupon(state, action) {
      return {
        ...state,
        couponToAdd: action.payload.couponToAdd,
        couponAdded: null,
        isLoading: true,
      };
    },
    couponAdded(state, action) {
      return {
        ...state,
        couponAdded: action.payload,
        isLoading: false,
        error: null,
      };
    },
    addPromos(state, action) {
      return {
        ...state,
        promoToAdd: action.payload.promos,
        promoAdded: null,
        isLoading: true,
      };
    },
    promoAdded(state, action) {
      return {
        ...state,
        promoToAdd: null,
        promoAdded: action.payload,
        isLoading: false,
        error: null,
      };
    },
    error(state, action) {
      return {
        ...state,
        error: action?.payload?.message || 'Unknown Error',
        isLoading: false,
      };
    },
    clearErrors(state) {
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    },
  },
});

export const {
  couponsConfigRequest,
  couponsConfigSuccess,
  getRestaurantMenuRequest,
  getRestaurantMenuSuccess,
  getBusinessCouponsRequest,
  getBusinessCouponsSuccess,
  removeRestaurantMenu,
  deleteCoupon,
  couponDeleted,
  error,
  clearErrors,
  addCoupon,
  couponAdded,
  addPromos,
  promoAdded,
} = couponSlice.actions;

export default couponSlice.reducer;
