import md5 from 'md5';
import { api } from './Api';
import camelToSnake from '../utils/CaseUtils/camelToSnake';

export const postLogin = async ({ email, password }) => {
  const response = await api.post('/account/authorize', {
    username: email,
    password: md5(password),
  });
  return response;
};

export const registerAccount = async ({
  username,
  firstname,
  lastname,
  password,
  email,
  source,
  profile,
  phone,
}) => {
  const response = await api.post('/account/register', {
    username,
    firstname,
    lastname,
    password: md5(password),
    email,
    source,
    profile,
    phone,
  });
  return response;
};

export const fetchRefreshToken = async (refreshToken) => {
  const response = await api.post(
    '/account/refresh',
    {},
    {
      refreshToken,
      hasRefreshToken: true,
    }
  );
  return response;
};

export const deauthorizeAccount = async (refreshToken) => {
  const response = await api.post(
    '/account/deauthorize',
    {},
    {
      refreshToken,
      hasRefreshToken: true,
    }
  );
  return response;
};

export const getMe = async (type = 'appUser') => {
  const response = await api.get('/account/profile', {}, { type });

  return response;
};

export const forgotPassword = async (email) => {
  const response = await api.post('/account/reset/start', {
    email,
    callback: `${window.location.origin}/reset-password`,
  });
  return response;
};

export const resetPassword = async ({ email, resetCode, password }) => {
  const response = await api.post(
    '/account/reset/end',
    camelToSnake({
      email,
      resetCode,
      password: md5(password),
    })
  );
  return response;
};
