import React from 'react';
import PropTypes from 'prop-types';
import { GalleryModal } from '@eatzy/eatzy-ui';
import { useGallery } from '../../../context/GalleryContext';
import useNotifications from '../../../hooks/useNotifications';

function UserGallery({ open, onClose, setFile, width, height }) {
  const { enqueueSnackbar } = useNotifications();

  const notifyDefaultError = () =>
    enqueueSnackbar({
      message: 'Something went wrong',
      options: {
        variant: 'error',
      },
    });

  const callbacks = {
    'upload-success': () =>
      enqueueSnackbar({
        message: 'File was successfully uploaded',
        options: {
          variant: 'success',
        },
      }),
    'delete-success': () =>
      enqueueSnackbar({
        message: 'File was successfully deleted',
        options: {
          variant: 'success',
        },
      }),
    'upload-error': notifyDefaultError,
    'config-error': notifyDefaultError,
    'load-error': notifyDefaultError,
    'delete-error': notifyDefaultError,
  };

  return (
    <GalleryModal
      setFile={setFile}
      open={open}
      onClose={onClose}
      GalleryProviderHooks={[
        { name: 'User Uploads', hook: useGallery, main: true },
      ]}
      actionCallback={(e) => {
        const callback = callbacks[`${e.type}-${e.status}`];
        if (callback) callback();
      }}
      width={width}
      height={height}
    />
  );
}

UserGallery.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

UserGallery.defaultProps = {
  height: 400,
  width: 650,
};

export default UserGallery;
