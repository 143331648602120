import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { Check, Close, Edit } from '@material-ui/icons';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';
import { useStyles } from './EditWebSiteInfo.styles';
import { saveRestaurant } from '../../../services/RestaurantsService';
import { Slider } from '../../input/Slider/Slider';
import { Loading } from '../Loading/Loading';
import { useGetErrors } from '../../../hooks/useGetErrors/useGetErrors';
import WebContentForm from '../../input/WebContentForm';
import { WebsiteImages } from '../../input/WebsiteImages';

const FormSchema = yup.object().shape({
  meta: yup.object().shape({
    title: yup.string(),
    description: yup.string(),
    additional: yup.array().of(
      yup.object().shape({
        field: yup.string(),
        info: yup.string(),
      })
    ),
  }),
  slider: yup.array().of(
    yup.object().shape({
      sliderTitle: yup.string(),
    })
  ),
});

export function EditWebSiteInfo() {
  const { restaurant, edit, setLoading, setRestaurant, setEdit, loading } =
    useRestaurantMenu();
  const {
    control,
    getValues,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors },
    ...rest
  } = useForm({
    defaultValues: {
      logo: [],
      favicon: [],
      ...restaurant,
      color: { main: '' },
      meta: {
        description: '',
        keywords: '',
        about: '',
        ...restaurant?.meta,
      },
    },
    shouldUnregister: false,
    resolver: yupResolver(FormSchema),
  });

  const [initialImages, setInitialImages] = useState([
    getValues('logo'),
    getValues('favicon'),
    getValues('slider'),
  ]);
  const { getErrors, hasAnyError } = useGetErrors({ errors });

  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const slider = getValues('slider') ? getValues('slider') : [];
  const [files, setFiles] = useState(
    getValues('slider')
      ? getValues('slider').map((slide) =>
          slide.image ? slide.image[0] : slide.image
        )
      : []
  );

  const handleSliderDrop = async (data, i) => {
    setValue(`slider[${i}].image`, data);
  };

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  const handleAddSlider = async () => {
    const storageData = getValues();
    storageData.slider.push({
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    });
    setTab(storageData.slider.length - 1);
    reset(storageData);
  };

  const handleRemoveSlider = useCallback(
    async (i) => {
      const storageData = getValues();
      const newImages = [...files];
      newImages.splice(i, 1);
      storageData.slider.splice(i, 1);
      i >= storageData.slider.length && setTab(storageData.slider.length - 1);
      reset(storageData);
      setFiles(newImages);
    },
    [files, reset, getValues]
  );

  const handleImages = useCallback(async () => {
    const currentSlider = [...files];

    currentSlider.map(async (slide, index) => {
      if (slide || initialImages[2][index]?.image?.length > 0) {
        if (slide?.id !== initialImages[2][index]?.image[0]?.id) {
          const newFiles = files;
          newFiles[index] = slide;
          setFiles(newFiles);
        }
      }
    });
  }, [files, initialImages]);

  const handleImagesCancel = useCallback(async () => {
    const currentSlider = [...files];

    currentSlider.map(async (slide, index) => {
      if (slide || initialImages[2][index]?.image?.length > 0) {
        if (slide?.id !== initialImages[2][index]?.image[0]?.id) {
          const newFiles = [...files];
          newFiles[index] = initialImages[2][index]?.image[0];
          setFiles(newFiles);
        }
      }
    });
  }, [files, initialImages]);

  useEffect(() => {
    reset(restaurant);
  }, [restaurant, reset]);

  const handleSave = async () => {
    await trigger();
    if (!hasAnyError()) {
      setLoading(true);
      try {
        await handleImages();
        setInitialImages([
          getValues('logo'),
          getValues('favicon'),
          getValues('slider'),
        ]);
        const response = await saveRestaurant({
          id: restaurant?.id,
          data: getValues(),
        });
        setRestaurant(response);
        setLoading(false);
        setEdit(!edit);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancelEdit = useCallback(async () => {
    setTab(0);
    await handleImagesCancel();
    reset({
      ...restaurant,
      color: { main: '' },
      meta: {
        description: '',
        keywords: '',
        about: '',
        ...restaurant?.meta,
      },
      logo: initialImages ? initialImages[0] : undefined,
      favicon: initialImages ? initialImages[1] : undefined,
      slider: initialImages ? initialImages[2] : undefined,
    });
    setEdit(false);
  }, [
    initialImages,
    handleImagesCancel,
    reset,
    restaurant,
    setEdit,
    getValues,
  ]);

  if (loading)
    return (
      <Box className={classes.root}>
        <Loading />
      </Box>
    );

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Box className={classes.root}>
        <Box className={classes.formTitle}>
          <Typography variant="h2">Website Info</Typography>
          {edit ? (
            <Box display="flex">
              <Button
                className={classes.cancelButton}
                data-test-id="cancel"
                onClick={handleCancelEdit}
              >
                <Close fontSize="small" />
                <p>Cancel</p>
              </Button>
              <Button
                className={classes.saveButton}
                data-test-id="save-info"
                onClick={handleSave}
              >
                <Check fontSize="small" />
                <p>Save Changes</p>
              </Button>
            </Box>
          ) : (
            <Button
              className={classes.editButton}
              data-test-id="edit-info"
              onClick={handleEdit}
            >
              <Edit fontSize="small" />
              <p style={{ marginLeft: '8px' }}>Edit Info</p>
            </Button>
          )}
        </Box>
        <WebContentForm
          form={{
            control,
            getErrors,
            getValues,
            setValue,
            watch,
            ...rest,
          }}
          disabled={!edit}
        />
      </Box>
      <Box className={classes.root}>
        <Typography variant="h2" className={classes.formTitle}>
          Restaurant Homepage Slider
        </Typography>
        <Slider
          getErrors={getErrors}
          form={{ control, setValue }}
          tab={tab}
          slider={slider}
          files={files}
          setFiles={setFiles}
          handleChange={handleChange}
          handleRemoveSlider={handleRemoveSlider}
          handleAddSlider={handleAddSlider}
          handleDrop={handleSliderDrop}
          disabled={!edit}
        />
      </Box>
      <Box className={classes.root}>
        <Typography variant="h2" className={classes.formTitle}>
          Consumer website images
        </Typography>
        <WebsiteImages form={{ setValue, getValues }} disabled={!edit} />
      </Box>
    </form>
  );
}
