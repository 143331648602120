import { Box, Button, Divider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { CheckCircle, Error } from '@material-ui/icons';
import clsx from 'clsx';
import { getPublicConfig } from '../../../services/StripeService';
import ownerAuth from '../../../utils/ownerAuthentication';
import { useStyles } from './EditStripe.styles';
import { useAddRestaurantContext } from '../../../context/AddRestaurantContext';
import OwnerLogin from '../../input/OwnerLogin';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';
import useNotifications from '../../../hooks/useNotifications';
import { Loading } from '../Loading';
import StripeAccountInfo from '../StripeAccountInfo';

import buttonImg from '../../../assets/images/stripe/blue-on-light.png';

export function EditStripe() {
  const { enqueueSnackbar } = useNotifications();
  const { search } = useLocation();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [clientId, setClientId] = useState('');
  const [ownerProfile, setOwnerProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { logout, ownerData } = useAddRestaurantContext();
  const { restaurant, loading } = useRestaurantMenu();

  useEffect(() => {
    const getClientId = async () => {
      try {
        await ownerAuth.refreshToken(restaurant?.id);
        const retrivedOwnerProfile = await ownerAuth.getProfile(
          restaurant?.id,
          true
        );
        setOwnerProfile(retrivedOwnerProfile);
        const data = await getPublicConfig();
        setClientId(data.client_id);
      } catch (err) {
        await logout('owner');
      } finally {
        setIsLoading(false);
      }
    };

    if (!_.isNil(search) && !_.isEmpty(search)) {
      const params = qs.parse(
        search.indexOf('?') >= 0
          ? search.substring(search.indexOf('?') + 1)
          : search
      );

      let errorMessage = null;

      if (!_.isNil(params.error) && params.error !== '0') {
        setIsLoading(true);
        getClientId();

        errorMessage = params.error_description || `Error code ${params.error}`;

        enqueueSnackbar({
          message: errorMessage,
          options: {
            variant: 'error',
          },
        });
      }

      if (!errorMessage) {
        setError(errorMessage);
      }
    } else {
      setIsLoading(true);
      getClientId();
    }
  }, [search, logout, restaurant, enqueueSnackbar, ownerData.isAuthenticated]);

  const handleChangeUser = async () => {
    await logout('owner');
    setOwnerProfile({});
  };

  if (loading || isLoading) return <Loading />;

  if (restaurant?.owner?.stripe?.active) {
    return (
      <Box className={classes.root}>
        <Box className={classes.mb24}>
          <Typography className={classes.title}>Stripe Info</Typography>
          <Box className={classes.status}>
            <CheckCircle className={classes.success} />
            <Typography className={clsx(classes.statusText, classes.success)}>
              Stripe Connected
            </Typography>
          </Box>
        </Box>
        <Divider className={classes.mb24} />
        <StripeAccountInfo stripeInfo={restaurant.owner} />
      </Box>
    );
  }

  if (!ownerProfile?.id) {
    return (
      <Box className={classes.root}>
        <Box className={classes.mb24}>
          <Typography className={classes.title}>Stripe Info</Typography>
          <Box className={classes.status}>
            <Error className={classes.error} />
            <Typography className={clsx(classes.statusText, classes.error)}>
              Stripe Not Connected
            </Typography>
          </Box>
        </Box>
        <Divider className={classes.mb24} />
        <OwnerLogin email={restaurant?.owner?.email} />
      </Box>
    );
  }

  if (ownerProfile?.id !== restaurant?.owner?.id) {
    return (
      <Box className={classes.root}>
        <Box className={classes.mb24}>
          <Typography className={classes.title}>Stripe Info</Typography>
          <Box className={classes.status}>
            <Error className={classes.error} />
            <Typography className={clsx(classes.statusText, classes.error)}>
              Incorrect owner logged
            </Typography>
          </Box>
        </Box>
        <Divider className={classes.mb24} />
        <Button
          className={classes.changeUser}
          variant="contained"
          color="secondary"
          data-test-id="login-again"
          onClick={handleChangeUser}
        >
          please login as {restaurant?.owner?.firstname}{' '}
          {restaurant?.owner?.lastname}
        </Button>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.mb24}>
        <Typography className={classes.title}>Stripe Info</Typography>
        <Box className={classes.status}>
          <Error className={classes.error} />
          <Typography className={clsx(classes.statusText, classes.error)}>
            Connect with Stripe
          </Typography>
        </Box>
      </Box>
      <Divider className={classes.mb24} />
      <a
        data-test-id="connect-stripe"
        style={{ alignSelf: 'flex-start' }}
        href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&state=${
          ownerProfile.id
        }_edit_${restaurant?.id || 0}&scope=read_write&redirect_uri=${
          window.location.origin
        }/stripeconnect`}
      >
        <img alt="stripe button" src={buttonImg} />
      </a>

      {error && (
        <Typography className={classes.error}>Something went wrong</Typography>
      )}
    </Box>
  );
}

export default EditStripe;
