import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { api } from '../../../../services/Api';
import { AddRestaurantETL } from '../../../../utils/ExtractTransformLoad/AddRestaurantETL';
import storage from '../../../../utils/storage';
import ownerStore from '../../../../utils/ownerStore';
import appSetupStore from '../../../../utils/appSetupStore';
import ownerAuth from '../../../../utils/ownerAuthentication';
import { registerAppUser } from '../../../../services/AppUserService';
import useNotifications from '../../../../hooks/useNotifications';
import appSetupAuth from '../../../../utils/appSetupAuthentication';
import { useRestaurantMenu } from '../../../../context/RestaurantMenuContext';

export const submitAddRestaurant = async (restaurant) =>
  await api.put('/localareamng/restaurant', restaurant);

export function Complete({ form: { getValues, reset } }) {
  const { enqueueSnackbar } = useNotifications();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(true);
  const [isError, setIsError] = useState(false);
  const { setRestaurant } = useRestaurantMenu();

  useEffect(() => {
    const submit = async () => {
      const owner = await ownerAuth.getToken();
      const appuser = await appSetupAuth.getToken();
      const values = await AddRestaurantETL(getValues(), { owner });

      try {
        const response = await submitAddRestaurant(values);
        setRestaurant(response);
        if (appuser)
          await registerAppUser({
            token: appuser,
            id: response.id,
          });
        setIsLoadingSubmit(false);
        await storage.remove('restaurantInfo');

        const ownerInfo = await ownerStore.get('user');
        await ownerStore.set(`user-${response.id}`, ownerInfo);

        const appSetupInfo = await appSetupStore.get('user');
        await appSetupStore.set(`user-${response.id}`, appSetupInfo);

        await reset();
      } catch (err) {
        enqueueSnackbar({
          message: err.message
            ? `Error: ${err.message}`
            : 'Something went wrong! Try again later.',
          options: {
            variant: 'error',
          },
        });
        setIsError(true);
      }
    };

    submit();
    // eslint-disable-next-line
  }, []);

  if (isError) {
    return <Typography>Error Submitting Restaurant</Typography>;
  }

  if (isLoadingSubmit) {
    return (
      <Box display="flex" flexDirection="row">
        <CircularProgress /> <Typography>Creating new restaurant</Typography>
      </Box>
    );
  }

  return (
    <Typography variant="h5">
      You successfully created an account for your restaurant! Continue to
      connect your restaurant to a Stripe account.
    </Typography>
  );
}

Complete.propTypes = {
  form: PropTypes.object,
};

export default Complete;
