import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';

import * as yup from 'yup';
import { Done } from '@material-ui/icons';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';

import { useStyles } from './ForgotPasswordForm.styles';

const FormSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email'),
});

export function ForgotPasswordForm({ onSubmit, isLoading, resetRequested }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.contents}>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Form
          schema={FormSchema}
          className={classes.form}
          noValidate
          onSubmit={onSubmit}
          aria-label="forgot password form"
        >
          <Input
            className={classes.input}
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            placeholder="Type your email"
            autoComplete="email"
            autoFocus
            inputProps={{ 'data-test-id': 'forgot-password:email-input' }}
          />
          <Box display="flex" justifyContent="center">
            {!resetRequested ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                loading={isLoading}
                data-test-id="forgot-password:button"
                aria-label="forgot password button"
              >
                Reset password
              </Button>
            ) : (
              <Box
                alignItems="center"
                display="flex"
                marginTop={4}
                color="#4357EF"
              >
                <Done style={{ fill: '#4357EF', marginRight: '8px' }} />
                Reset code sent
              </Box>
            )}
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  backendError: PropTypes.string,
  handleClose: PropTypes.func,
  resetRequested: PropTypes.bool,
};
