import { darken, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    margin: '0 auto',
    padding: '32px 24px',
    maxWidth: '960px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '9px',
    border: '1px solid #E0E0E0',
    width: '100%',
    height: 'max-content',

    [theme.breakpoints.down(732)]: {
      margin: '0 8px',
      maxWidth: 'calc(100% - 16px)',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '24px',
    color: '#333333',
  },
  cancelBtn: {
    padding: '8px 14px',
    fontWeight: 'bold',
    fontSize: '14px',
    border: '1px solid #D5D5D5',

    color: '#666666',
  },
  saveBtn: {
    marginLeft: '10px',
    padding: '8px 32px',
    fontWeight: 'bold',
    fontSize: '14px',

    color: '#fff',
    backgroundColor: '#007AFF',

    '&:hover': {
      backgroundColor: darken('#007AFF', 0.2),
    },
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    height: '1px',
    flex: 1,
    backgroundColor: '#D5D5D5',
  },
  dividerTitle: {
    marginRight: '16px',
    fontWeight: 'bold',
    fontSize: '20px',

    color: '#000000',

    opacity: 0.9,
  },
  footer: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  sectionContent: {
    margin: '24px 0px',
    flexWrap: 'wrap',
  },
  defaultInput: {
    background: '#FAFAFA',
    borderRadius: '2px',
    width: '256px',
    padding: '0px',
    fontSize: '16px',

    '& .Mui-disabled': {
      color: 'red !important',
      fontSize: '16px',
      background: '#FAFAFA',

      '&:hover': {
        '& fieldset': {
          border: 'none',
        },
      },
      color: 'D5D5D5',
      '& fieldset': {
        border: 'none',
      },
    },

    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '&:hover fieldset': {
      border: '1px solid #D5D5D5 !important',
    },

    '& fieldset': {
      border: '1px solid #D5D5D5',
    },

    '& .MuiOutlinedInput-root:hover fieldset': {
      border: '1px solid #D5D5D5',
    },

    '& .MuiOutlinedInput-root fieldset': {
      border: '1px solid #D5D5D5',
    },

    '& .MuiInputBase-root': { borderRadius: '2px', fontSize: '16px' },
  },
  fieldLabel: {
    marginLeft: '14px',
    width: '182px',
  },
  flexRow: {
    display: 'flex',
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  alignItemsEnd: {
    alignItems: 'stretch',
    display: 'flex',
  },
  mr24: { marginRight: '24px' },
  placeholder: { color: '#aaa' },
  generateCodeBtn: {
    marginLeft: '14px',
    marginTop: '6px',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '15px',
    width: 'fit-content',

    color: '#007AFF',

    cursor: 'pointer',

    '&:hover': {
      color: darken('#007AFF', 0.15),
    },
  },
  subSectionTitle: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  subSectionDescription: {
    fontSize: '14px',
    lineHeight: '17px',

    color: '#535353',
  },
  ml6: {
    marginLeft: '6px',
  },
  mb24: {
    marginBottom: '24px',
  },
  startAdornment: {
    backgroundColor: '#007AFF',
    width: '50px',
    height: '53.5px',
    borderRadius: '2px 0px 0px 2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  startAdornmentIcon: { color: '#fff' },
  startAdornmentText: { color: '#fff', fontWeight: 'bolder', fontSize: '20px' },
  formInputBoxAutocomplete: {
    width: '256px',
    marginBottom: 24,
    background: '#FAFAFA',
    boxSizing: 'border-box',
    borderRadius: '2px',

    '& input, textarea': {
      padding: '14px 16px !important',
      border: '1px solid #D5D5D5',
      fontSize: '16px',
      color: '#000000',
    },

    '& .Mui-disabled': {
      fontSize: '16px',
      color: '#000000',
      borderRadius: '2px',
      border: '1px solid #FAFAFA',

      background: '#FAFAFA',
    },
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    border: '1px solid #0000001F',
    background: '#FAFAFA',
    marginRight: '14px',
    borderRadius: '16px',
    width: 'fit-content',
    '& > p': {
      whiteSpace: 'nowrap',
    },
  },
  tagsContainer: {
    width: '500px',
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingBottom: '10px',

    '&::-webkit-scrollbar': {
      height: '8px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '4px',
      background: '#00000017',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#00000055',
      borderRadius: '4px',
    },
  },
  op20: { opacity: 0.2 },
}));
