import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useStyles } from './Steps.styles';
import ImageUpload from '../../ImageUpload';

export function WebContent({
  form: { control, getErrors, getValues, setValue, watch },
  disabled = false,
}) {
  watch();
  const classes = useStyles();
  const [logo, setLogo] = useState(getValues('logo'));
  const [favicon, setFavicon] = useState(getValues('favicon'));

  useEffect(() => {
    setValue('meta.title', getValues('name'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue('logo', logo);
  }, [logo]);

  useEffect(() => {
    setValue('favicon', favicon);
  }, [favicon]);

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Website Content and SEO
      </Typography>
      <Box className={classes.webContent}>
        <Box width="50%">
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Site title
          </Typography>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                id="meta.title"
                placeholder="Site Title"
                style={{ marginBottom: 32 }}
                fullWidth
                variant="outlined"
                className={classes.formInputBox}
                disabled={disabled}
                {...getErrors('meta.title')}
              />
            )}
            defaultValue={getValues('name')}
            control={control}
            name="meta.title"
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Description
          </Typography>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                id="meta.description"
                minRows={6}
                maxRows={6}
                placeholder="Description"
                style={{ height: 141, marginBottom: 32 }}
                multiline
                fullWidth
                variant="outlined"
                className={classes.formInputBox}
                disabled={disabled}
                {...getErrors('meta.description')}
              />
            )}
            control={control}
            name="meta.description"
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Keywords
          </Typography>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Keywords"
                style={{ marginBottom: 32 }}
                id="meta.keywords"
                fullWidth
                variant="outlined"
                className={classes.formInputBox}
                disabled={disabled}
                {...getErrors('meta.keywords')}
              />
            )}
            control={control}
            name="meta.keywords"
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Restaurant Logo
          </Typography>
          <ImageUpload
            disabled={disabled}
            setFile={setLogo}
            file={logo[0]}
            text="Add restaurant logo"
            componentSize={{ height: '256px', width: '256px' }}
            imageSize={{ height: 330, width: 630 }}
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Restaurant Favicon
          </Typography>
          <ImageUpload
            disabled={disabled}
            setFile={setFavicon}
            file={favicon[0]}
            componentSize={{ height: '48px', width: '256px' }}
            imageSize={{ height: 96, width: 96 }}
          />
        </Box>
      </Box>
    </>
  );
}

WebContent.propTypes = {
  form: PropTypes.object,
  disabled: PropTypes.bool,
};

export default WebContent;
