import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { ColorizeRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { useStyles } from './WebContentForm.styles';
import ColorPicker from '../ColorPicker';
import ImageUpload from '../ImageUpload';

export function WebContentForm({
  form: { control, getErrors, getValues, setValue },
  disabled = false,
}) {
  const classes = useStyles();
  const [logo, setLogo] = useState(getValues('logo'));
  const [favicon, setFavicon] = useState(getValues('favicon'));
  const [color, setColor] = useState('#007AFF');

  const handleSetColor = (selectedColor) => {
    setColor(selectedColor.hex);
    setValue('colors.main', selectedColor.hex);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setValue('meta.title', getValues('name'));
  }, []);

  useEffect(() => {
    setValue('logo', logo);
  }, [logo]);

  useEffect(() => {
    setValue('favicon', favicon);
  }, [favicon]);

  return (
    <Box className={classes.content}>
      <Box flex={0.5}>
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Restaurant title
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              disabled={disabled}
              placeholder="Type the restaurant title"
              className={classes.formInputBox}
              {...getErrors('meta.title')}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
          )}
          name="meta.title"
          control={control}
        />

        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Description
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="standard"
              id="meta.description"
              placeholder="Type description"
              className={clsx(classes.formInputBox)}
              disabled={disabled}
              minRows={2}
              maxRows={2}
              multiline
              InputProps={{
                disableUnderline: true,
              }}
              {...getErrors('meta.description')}
            />
          )}
          name="meta.description"
          control={control}
        />

        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Restaurant keywords
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              disabled={disabled}
              placeholder="Type the restaurant keywords"
              className={classes.formInputBox}
              {...getErrors('meta.keywords')}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
          )}
          name="meta.keywords"
          control={control}
        />

        <Typography variant="h6" className={classes.formTextFieldLabel}>
          About us
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="standard"
              id="meta.about"
              placeholder="Tell your clients a little about your business"
              className={clsx(classes.formInputBox)}
              disabled={disabled}
              minRows={2}
              maxRows={2}
              multiline
              InputProps={{
                disableUnderline: true,
              }}
              {...getErrors('meta.about')}
            />
          )}
          name="meta.about"
          control={control}
        />
      </Box>
      <Box flex={0.3}>
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Restaurant Logo
        </Typography>
        <ImageUpload
          disabled={disabled}
          setFile={setLogo}
          file={logo[0]}
          text="Add restaurant logo"
          componentSize={{ height: '256px', width: '256px' }}
          imageSize={{ height: 330, width: 630 }}
        />
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Restaurant Favicon
        </Typography>

        <ImageUpload
          disabled={disabled}
          setFile={setFavicon}
          file={favicon[0]}
          componentSize={{ height: '48px', width: '256px' }}
          imageSize={{ height: 96, width: 96 }}
        />

        <Typography
          variant="h6"
          className={classes.formTextFieldLabel}
          style={{ marginTop: '32px' }}
        >
          Button color
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              style={{ width: '256px' }}
              placeholder="#000000"
              className={clsx(classes.formInputBox)}
              disabled={disabled}
              {...getErrors('colors.main')}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <Button
                    data-test-id="color-button"
                    disabled={disabled}
                    onClick={handleClick}
                    style={{ backgroundColor: color }}
                    className={
                      disabled
                        ? classes.inputAdornment
                        : classes.inputAdornmentEdit
                    }
                  >
                    <ColorizeRounded />
                  </Button>
                ),
              }}
            />
          )}
          name="colors.main"
          control={control}
        />
      </Box>
      <ColorPicker
        onClose={handleClose}
        anchorEl={anchorEl}
        color={color}
        setColor={handleSetColor}
      />
    </Box>
  );
}

WebContentForm.propTypes = {
  form: PropTypes.object,
  disabled: PropTypes.bool,
};

export default WebContentForm;
