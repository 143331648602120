import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 480,

    [theme.breakpoints.down(732)]: {
      width: 350,
    },

    backgroundColor: theme.palette.background.paper,
    border: '1px solid #D5D5D5',
    boxShadow: theme.shadows[5],
    borderRadius: '4px',
  },
  header: {
    padding: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiTypography-root': {
      fontSize: '16px',
      color: '#666666',
    },

    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  body: {
    padding: '32px 14px',
  },
  footer: {
    padding: '12px 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    padding: '8px 14px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'bold',
    border: '1px solid #D5D5D5',
    borderRadius: '4px',

    color: '#666666',
  },
  confirmBtn: {
    padding: '8px 32px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'bold',
    borderRadius: '4px',

    color: '#fff',

    marginLeft: '24px',
  },
}));
