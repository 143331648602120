import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { getRestaurant, getTemplate } from '../services/RestaurantsService';
import { getConfigFiles } from '../services/UploadService';

export const RestaurantMenuContext = createContext({});

export const useRestaurantMenu = () => useContext(RestaurantMenuContext);

export function RestaurantMenuProvider({ children }) {
  const [pages, setPages] = useState([]);
  const [selectedPage, setPage] = useState(0);
  const [selectedSubItem, setSelectedSubItem] = useState(0);
  const [restaurant, setRestaurant] = useState();
  const [template, setTemplate] = useState();
  const [mime, setMime] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const params = useParams();

  const component = useMemo(() => pages[selectedPage], [pages, selectedPage]);

  const reset = useCallback(() => {
    setPage(0);
    setPages([]);
  }, [setPages, setPage]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (params.restaurantId) {
          const response = await getRestaurant({ id: params.restaurantId });
          const templateResponse = await getTemplate();
          setTemplate(templateResponse);
          const mimeResponse = await getConfigFiles();
          setMime(mimeResponse);
          setRestaurant(response);
        }
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <RestaurantMenuContext.Provider
      value={{
        setPages,
        pages,
        selectedPage,
        setPage,
        component,
        reset,
        loading,
        setLoading,
        setRestaurant,
        error,
        setError,
        edit,
        setEdit,
        restaurant,
        selectedSubItem,
        setSelectedSubItem,
        template,
        mime,
      }}
    >
      {children}
    </RestaurantMenuContext.Provider>
  );
}

RestaurantMenuProvider.propTypes = {
  children: PropTypes.node,
};

export function Component({ components }) {
  const { search } = useLocation();
  const { component, setPages, reset, selectedSubItem, setPage } =
    useRestaurantMenu();

  useEffect(() => {
    setPages(components);
    if (search) {
      setPage(1);
    }

    return () => reset();
    // eslint-disable-next-line
  }, [search]);

  return (
    <>
      {component?.component
        ? component?.component
        : component?.subItems[selectedSubItem].component}
    </>
  );
}

Component.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      component: PropTypes.node,
    })
  ),
};
