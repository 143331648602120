import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Drawer, IconButton, Typography } from '@material-ui/core';
import { ExitToAppRounded, Menu as MenuIcon, Search } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { USER_LOGOUT } from '../../../constants/auth';
import { logoutRequest } from '../../../redux/ducks/UserDucks';
import { useStyles } from './Header.styles';
import { Menu } from '../Menu';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';

import logoImage from '../../../assets/images/logo.png';

function HomeLink() {
  const classes = useStyles();
  return (
    <>
      <img src={logoImage} alt="Logo" className={classes.logo} />
      <Link to="/" className={classes.link}>
        <p className={classes.title}>
          EATZY <span>CREATE</span>
        </p>
      </Link>
    </>
  );
}

export function Header({ menu: RestaurantMenu }) {
  const { pathname } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const logout = useCallback(() => {
    dispatch(logoutRequest(USER_LOGOUT));
  }, [dispatch]);

  const header = {
    '/add': 'Restaurant Setup',
    '/restaurants': 'Restaurants',
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [pathname]);

  const isDesktop = useIsDesktop();

  const handleCloseDrawer = () => {
    setOpenMenu(false);
  };

  return (
    <>
      {isDesktop ? (
        <header className={classes.root}>
          <Box display="flex" alignItems="center">
            <HomeLink />
          </Box>
          <Button
            data-test-id="bU_k6_TxLJSiyTyob6v-3"
            onClick={logout}
            className={classes.logOutButton}
          >
            Log out
          </Button>
        </header>
      ) : (
        <>
          <header className={classes.root}>
            <Box display="flex" alignItems="center">
              <IconButton
                data-test-id="menu-button"
                className={classes.iconButton}
                onClick={() => setOpenMenu(!openMenu)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flex="1"
            >
              <Typography variant="h3" style={{ marginLeft: '20px' }}>
                {header[pathname]}
              </Typography>
              <IconButton>
                <Search />
              </IconButton>
            </Box>
          </header>
          <Drawer
            open={openMenu}
            onClose={handleCloseDrawer}
            classes={{ paperAnchorLeft: classes.drawer }}
            aria-label="drawer menu"
          >
            <Box
              display="flex"
              alignItems="center"
              marginTop="32px"
              marginBottom="24px"
              marginLeft="16px"
              justifyContent="space-between"
            >
              <Box display="flex">
                <HomeLink />
              </Box>
              <Button
                data-test-id="Aqi9DYHa5C1lZyvjtLz5g"
                onClick={logout}
                className={classes.logOutButton}
              >
                <ExitToAppRounded />
              </Button>
            </Box>
            {RestaurantMenu ? <RestaurantMenu mobile /> : <Menu mobile />}
          </Drawer>
        </>
      )}
    </>
  );
}

Header.propTypes = {
  menu: PropTypes.func,
};
