import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { useStyles } from './LoginForm.styles';

export function LoginForm({ form: { control, getErrors }, fieldNames }) {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box flex={1}>
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Email
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              id={fieldNames.email}
              placeholder="Type your email"
              type="email"
              className={clsx(classes.formInputBox, classes.lowerCase)}
              variant="outlined"
              fullWidth
              {...getErrors(fieldNames.email)}
            />
          )}
          control={control}
          name={fieldNames.email}
        />
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Password
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Type your password"
              type="password"
              autoComplete="current-password"
              inputProps={{ 'data-test-id': 'login:password-input' }}
              className={classes.formInputBox}
              variant="outlined"
              fullWidth
              id={fieldNames.password}
              {...getErrors(fieldNames.password)}
            />
          )}
          control={control}
          name={fieldNames.password}
        />
      </Box>
    </Box>
  );
}

LoginForm.propTypes = {
  form: PropTypes.object,
  fieldNames: PropTypes.objectOf(PropTypes.string),
};

export default LoginForm;
