import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from './Steps.styles';
import storage from '../../../../utils/storage';
import { Slider } from '../../Slider/Slider';
import { DELAY } from '../../../../constants/store';

export function HomePageSlider({
  form: { control, getValues, reset, getErrors, setValue },
}) {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const slider = getValues('slider');
  const [files, setFiles] = useState(
    getValues('slider').map((slide) => slide.image[0])
  );
  const errors = getErrors('slider');

  const handleDrop = async (data, i) => {
    setValue(`slider[${i}].image`, data);
    await storage.set(
      'restaurantInfo',
      {
        ...getValues(),
        loginOwnerPassword: '',
        OwnerPassword: '',
        appPassword: '',
        appUserPassword: '',
      },
      DELAY
    );
  };

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  const handleAddSlider = async () => {
    const storageData = {
      ...getValues(),
      loginOwnerPassword: '',
      OwnerPassword: '',
      appPassword: '',
      appUserPassword: '',
    };
    storageData.slider.push({
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    });
    setTab(storageData.slider.length - 1);
    reset(storageData);
    await storage.set('restaurantInfo', storageData, DELAY);
  };

  const handleRemoveSlider = async (i) => {
    const storageData = {
      ...getValues(),
      loginOwnerPassword: '',
      OwnerPassword: '',
      appPassword: '',
      appUserPassword: '',
    };
    const newImages = [...files];
    newImages.splice(i, 1);
    storageData.slider.splice(i, 1);
    i >= storageData.slider.length && setTab(storageData.slider.length - 1);
    reset(storageData);
    setFiles(newImages);
    await storage.set('restaurantInfo', storageData, DELAY);
  };

  useEffect(() => {
    if (errors?.objecto) {
      for (let i = 0; i < errors?.objecto.length; i++) {
        if (errors?.objecto[i]) {
          setTab(i);
          break;
        }
      }
    }
  }, [errors]);

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Homepage Slider
      </Typography>
      <Slider
        handleChange={handleChange}
        slider={slider}
        tab={tab}
        setFiles={setFiles}
        handleAddSlider={handleAddSlider}
        handleRemoveSlider={handleRemoveSlider}
        handleDrop={handleDrop}
        files={files}
        form={{ control }}
        getErrors={getErrors}
      />
    </>
  );
}

HomePageSlider.propTypes = {
  form: PropTypes.object,
};

export default HomePageSlider;
