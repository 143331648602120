import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Cancel } from '@material-ui/icons';
import AutocompleteField from '../../AutocompleteField';
import { useStyles } from '../AddEditCouponSections.styles';
import { numberValidation } from '../../../../utils/numberValidation';

function ItemConditions({
  form: { getValues, setValue, control, getErrors },
  items,
  categories,
}) {
  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState(getValues('items') || []);
  const [selectedCategories, setSelectedCategories] = useState(
    getValues('categories') || []
  );

  const sectionDisabled = useMemo(
    () => getValues('restaurantConditions') !== 'Specific Restaurants',
    [getValues('restaurantConditions')]
  );

  const availableCategories = useMemo(() => {
    const availableRestaurants = getValues('specificRestaurants') || [];
    const restaurantIds = availableRestaurants.map(
      (restaurant) => restaurant.id
    );
    const result = categories.filter(
      (category) =>
        restaurantIds.length > 0 &&
        restaurantIds.filter((rId) => category.restaurants.includes(rId))
          .length > 0
    );
    return result;
  }, [getValues('specificRestaurants')]);

  const availableItems = useMemo(() => {
    const availableRestaurants = getValues('specificRestaurants') || [];
    const restaurantIds = availableRestaurants.map(
      (restaurant) => restaurant.id
    );
    const result = items.filter(
      (item) =>
        restaurantIds.length > 0 &&
        restaurantIds.some((rId) =>
          item.restaurants.some(
            (itemRId) => String(itemRId) === String(rId) // Convert both to strings for comparison
          )
        )
    );

    return result;
  }, [getValues('specificRestaurants')]);

  useEffect(() => {
    setValue(
      'itemRequired',
      !getValues('categoryRequired') &&
        getValues('itemRequired') &&
        !sectionDisabled
    );
  }, [getValues('categoryRequired'), sectionDisabled]);

  useEffect(() => {
    setValue(
      'categoryRequired',
      !getValues('itemRequired') &&
        getValues('categoryRequired') &&
        !sectionDisabled
    );
  }, [getValues('itemRequired'), sectionDisabled]);

  useEffect(() => setValue('items', selectedItems), [selectedItems]);

  useEffect(
    () => setValue('categories', selectedCategories),
    [selectedCategories]
  );

  const handleAddTag = (tag, selectedTags, setSelectedTags) => {
    const alreadyAdded = selectedTags.some(
      (selected) => selected.id === tag.id
    );
    if (!alreadyAdded) setSelectedTags([...selectedTags, tag]);
  };

  const handleRemoveTag = (tag, selectedTags, setSelectedTags) => {
    setSelectedTags(selectedTags.filter((selected) => selected.id !== tag.id));
  };

  return (
    <Box className={classes.sectionContent}>
      <Box
        className={clsx(classes.flexRow, classes.alignItemsEnd, classes.mb24)}
      >
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="itemRequired"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                data-test-id="items-switch"
                onChange={onChange}
                disabled={sectionDisabled}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>Items Required</Typography>
        </Box>
        <Box className={classes.mr24} style={{ marginTop: '18px' }}>
          <Typography
            variant="h6"
            className={clsx(classes.fieldLabel, {
              [classes.op20]: !getValues('itemRequired'),
            })}
          >
            Select items for discount:
          </Typography>
          <Box style={{ width: '256px' }}>
            <AutocompleteField
              data-test-id="sxVZKc6O97Xtu4_GA9NSX"
              disabled={!getValues('itemRequired')}
              options={availableItems}
              key={JSON.stringify(selectedItems)}
              onClick={(value) =>
                handleAddTag(value, selectedItems, setSelectedItems)
              }
              placeholder="Find item"
              getOptionLabel={(option) => option?.name}
            />
            {!!selectedItems.length && getValues('itemRequired') && (
              <Box
                display="flex"
                marginTop="14px"
                style={{ width: '256px' }}
                className={classes.tagsContainer}
              >
                {selectedItems.map((item, i) => (
                  <Box
                    data-test-id={`item-${i}`}
                    className={classes.tags}
                    key={item.id}
                  >
                    <Typography>{item.name}</Typography>

                    <IconButton
                      data-test-id={`delete-item-${i}`}
                      style={{ padding: 0, marginLeft: '8px' }}
                      onClick={() =>
                        handleRemoveTag(item, selectedItems, setSelectedItems)
                      }
                    >
                      <Cancel style={{ fontSize: '16px' }} />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.mr24}>
          <Typography
            variant="h6"
            className={clsx(classes.fieldLabel, {
              [classes.op20]: !getValues('itemRequired'),
            })}
          >
            Select a minimum of [x] Items from this list:
          </Typography>
          <Controller
            control={control}
            name="minimumOfItems"
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!getValues('itemRequired')}
                fullWidth
                inputProps={{
                  'data-test-id': 'minimum-items',
                }}
                onBlur={(a) =>
                  setValue('minimumOfItems', Number(a.target.value).toFixed())
                }
                onChange={(a) =>
                  setValue(
                    'minimumOfItems',
                    numberValidation(a.target.value, {
                      min: 0,
                    })
                  )
                }
                type="number"
                variant="outlined"
                placeholder="1"
                className={classes.defaultInput}
                {...getErrors('minimumOfItems')}
              />
            )}
          />
        </Box>
      </Box>

      <Box
        className={clsx(classes.flexRow, classes.alignItemsEnd, classes.mb24)}
      >
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="categoryRequired"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                onChange={onChange}
                disabled={sectionDisabled}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>Category Required</Typography>
        </Box>

        <Box className={classes.mr24} style={{ marginTop: '18px' }}>
          <Typography
            variant="h6"
            className={clsx(classes.fieldLabel, {
              [classes.op20]: !getValues('categoryRequired'),
            })}
          >
            Select categories for discount:
          </Typography>
          <Box style={{ width: '256px' }}>
            <AutocompleteField
              data-test-id="XojW40lCUITBzEhnpJlk5"
              disabled={!getValues('categoryRequired')}
              options={availableCategories}
              key={JSON.stringify(selectedCategories)}
              onClick={(value) =>
                handleAddTag(value, selectedCategories, setSelectedCategories)
              }
              placeholder="Find category"
              getOptionLabel={(option) => option?.name}
            />
            {!!selectedCategories.length && getValues('categoryRequired') && (
              <Box
                display="flex"
                marginTop="14px"
                style={{ width: '256px' }}
                className={classes.tagsContainer}
              >
                {selectedCategories.map((category, i) => (
                  <Box
                    data-test-id={`category-${i}`}
                    className={classes.tags}
                    key={category.id}
                  >
                    <Typography>{category.name}</Typography>

                    <IconButton
                      data-test-id={`delete-category-${i}`}
                      style={{ padding: 0, marginLeft: '8px' }}
                      onClick={() =>
                        handleRemoveTag(
                          category,
                          selectedCategories,
                          setSelectedCategories
                        )
                      }
                    >
                      <Cancel style={{ fontSize: '16px' }} />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.mr24}>
          <Typography
            variant="h6"
            className={clsx(classes.fieldLabel, {
              [classes.op20]: !getValues('categoryRequired'),
            })}
          >
            Select a minimum of [x] Items from these Categories:
          </Typography>
          <Controller
            control={control}
            name="minimumOfCategories"
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!getValues('categoryRequired')}
                fullWidth
                variant="outlined"
                inputProps={{
                  'data-test-id': 'minimum-categories',
                }}
                onBlur={(a) =>
                  setValue(
                    'minimumOfCategories',
                    Number(a.target.value).toFixed()
                  )
                }
                onChange={(a) =>
                  setValue(
                    'minimumOfCategories',
                    numberValidation(a.target.value, {
                      min: 0,
                    })
                  )
                }
                placeholder="1"
                type="number"
                className={classes.defaultInput}
                {...getErrors('minimumOfCategories')}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}

ItemConditions.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
  ),
  items: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
};

export default ItemConditions;
