import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './Steps.styles';
import { useAddRestaurantContext } from '../../../../context/AddRestaurantContext';
import { SigningIn } from '../../../layout/SigningIn/SigningIn';
import LoginForm from '../../LoginForm';
import RegisterForm from '../../RegisterForm';

export function RestaurantOwnerInfo({ form }) {
  const classes = useStyles();
  const [ownerTab, setOwnerTab] = useState(form.getValues('ownerTab') || 0);

  const formOptions = [
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Restaurant Owner Login
      </Typography>
      <LoginForm
        key="0"
        form={form}
        fieldNames={{
          email: 'loginOwnerEmail',
          password: 'loginOwnerPassword',
        }}
      />
    </>,
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Restaurant Owner Register
      </Typography>
      <RegisterForm
        key="1"
        form={form}
        fieldNames={{
          firstName: 'ownerFirstName',
          lastName: 'ownerLastName',
          phone: 'ownerPhoneNumber',
          email: 'ownerEmail',
          password: 'ownerPassword',
        }}
      />
    </>,
  ];
  const selectedForm = formOptions[ownerTab];

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const { ownerData } = useAddRestaurantContext();

  if (ownerData?.isLoading) {
    return <SigningIn />;
  }

  const handleChangeTab = (_, newTab) => {
    setOwnerTab(newTab);
    form.setValue('ownerTab', newTab);
  };

  return (
    <>
      <Box marginBottom="16px" marginTop="-24px">
        <Tabs
          value={ownerTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{ width: '100%' }}
        >
          <Tab data-test-id="login-tab" label="Login" {...a11yProps(0)} />
          <Tab data-test-id="register-tab" label="Register" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box>{selectedForm}</Box>
    </>
  );
}

RestaurantOwnerInfo.propTypes = {
  form: PropTypes.object,
};

export default RestaurantOwnerInfo;
