import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const _WithFallback = ({ children, fallback, isLoading, repeat }) => {
  const fallbackComponent = useMemo(
    () =>
      Array(repeat)
        .fill()
        .map((_, i) => <React.Fragment key={i}>{fallback}</React.Fragment>),
    [repeat, fallback]
  );

  if (isLoading) return fallbackComponent;
  return children;
};

export const WithFallback = React.memo(_WithFallback);

WithFallback.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.any,
  isLoading: PropTypes.bool,
  repeat: PropTypes.number,
};

WithFallback.defaultProps = {
  repeat: 1,
};
