import React, { useCallback, useEffect, useMemo } from 'react';
import {
  FormControl,
  Typography,
  Checkbox,
  TextField,
  Box,
  Divider,
  Select,
  MenuItem,
} from '@material-ui/core';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowForward } from '@material-ui/icons';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './AddUserModal.styles';
import { useGetErrors } from '../../../hooks/useGetErrors';
import { permissions } from '../../../constants/permissions';
import Modal from '../../layout/Modal';
import RegisterForm from '../RegisterForm';
import { Loading } from '../../layout/Loading';
import {
  searchUserRequest,
  registerUserRequest,
  goToStep,
  selectUser,
  toggleModal,
  listUsersRequest,
  updateUserRoleRequest,
} from '../../../redux/ducks/AppUserDucks';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';

const FormSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Provide a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
  phone: yup.string().required('Phone number is required'),
});

function AddUserModal() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { restaurant } = useRestaurantMenu();

  const isLoading = useSelector((state) => state.appUser.isLoading);
  const user = useSelector((state) => state.appUser.selectedUser);
  const step = useSelector((state) => state.appUser.step);
  const steps = useSelector((state) => state.appUser.steps);
  const open = useSelector((state) => state.appUser.open);

  const setStep = (newStep) => dispatch(goToStep({ step: newStep }));
  const setUser = (user) => dispatch(selectUser({ user }));
  const setOpen = (open) => dispatch(toggleModal({ open }));

  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      permission: 12,
      notifyByEmail: false,
      ...user,
    },
    shouldUnregister: false,
    resolver: yupResolver(FormSchema),
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    formState: { errors },
    getValues,
    control,
  } = methods;
  const { getErrors } = useGetErrors({ errors });

  const formObj = { ...methods, getErrors };

  useEffect(() => {
    dispatch(listUsersRequest({ restaurantId: restaurant?.id }));
  }, [restaurant]);

  useEffect(() => {
    reset({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      permission: 12,
      notifyByEmail: false,
      ...user,
    });
  }, [user, reset]);

  const handleClose = () => {
    setOpen(false);
    setUser({});
    reset();
  };

  const handleSearchUser = useCallback(async () => {
    const hasNoErrors = await trigger(['email']);

    if (hasNoErrors) {
      const email = getValues('email');
      dispatch(searchUserRequest({ email, restaurantId: restaurant?.id }));
    }
  }, [restaurant]);

  const handleOpenRegisterForm = useCallback(() => {
    setStep(steps[3]);
  }, []);

  const handleSetPermission = useCallback(async () => {
    dispatch(
      updateUserRoleRequest({
        permission: getValues('permission'),
        roleId: getValues('id'),
        userId: user?.userId,
        firstName: user?.firstName,
        lastName: user?.lastName,
        restaurantId: restaurant?.id,
      })
    );

    handleClose();
  }, [user, getValues]);

  const handleGoBackToSearch = useCallback(() => {
    setStep(steps[1]);
  }, []);

  const handleRegisterUser = useCallback(async () => {
    const hasNoErrors = await trigger([
      'firstName',
      'lastName',
      'email',
      'password',
      'phone',
    ]);

    if (hasNoErrors) {
      dispatch(
        registerUserRequest({ ...getValues(), restaurantId: restaurant?.id })
      );
    }
  }, [trigger, getValues]);

  const addUserComponents = {
    search: {
      component: (
        <Box>
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Email
          </Typography>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="johndoe@eatzy.com"
                className={classes.formInputBox}
                {...getErrors('email')}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            )}
            name="email"
            control={control}
          />
        </Box>
      ),
      title: 'Type e-mail',
      confirmBtnText: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Next <ArrowForward style={{ marginLeft: '6px', fontSize: '16px' }} />
        </div>
      ),
      onConfirm: handleSearchUser,
      onCancel: handleClose,
    },
    userNotFound: {
      component: (
        <Typography>
          <span className={classes.emailInfo}>{getValues('email')}</span> not
          found. Do you want to register a new user with this e-mail?
        </Typography>
      ),
      title: 'User not found',
      confirmBtnText: 'Register new user',
      cancelBtnText: 'Back',
      onConfirm: handleOpenRegisterForm,
      onCancel: handleGoBackToSearch,
    },
    register: {
      component: (
        <>
          <RegisterForm
            form={formObj}
            fieldNames={{
              firstName: 'firstName',
              lastName: 'lastName',
              email: 'email',
              password: 'password',
              phone: 'phone',
            }}
          />
          <FormControl className={classes.checkboxContainer}>
            <Controller
              render={({ field }) => (
                <Checkbox
                  {...field}
                  defaultValue={false}
                  color="primary"
                  className={classes.checkbox}
                />
              )}
              name="notifyByEmail"
              control={control}
              fullWidth
            />
            <Typography className={classes.checkboxLabel}>
              Notify user via email
            </Typography>
          </FormControl>
        </>
      ),
      title: 'Register user',
      confirmBtnText: 'Register user',
      onConfirm: handleRegisterUser,
      onCancel: handleClose,
    },
    setPermission: {
      component: (
        <Box>
          <Box className={classes.userInfoContainer}>
            <Box className={classes.userIcon}>
              {(getValues('firstName') || user.firstName)?.substring(0, 1)}
              {(getValues('lastName') || user.lastName)?.substring(0, 1)}
            </Box>
            <Box>
              <Typography className={classes.userInfo}>
                {getValues('firstName') || user.firstName}{' '}
                {getValues('lastName') || user.lastName}
              </Typography>
              <Typography className={clsx(classes.userInfo, classes.op60)}>
                {getValues('email') || user.email}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ marginBottom: '20px' }} />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Permission access
          </Typography>
          <FormControl
            error={getErrors('permission').error}
            helperText={getErrors('permission').helperText}
            className={classes.select}
          >
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  variant="outlined"
                  className={classes.formSelectInputBox}
                >
                  {Object.values(permissions).map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="permission"
              control={control}
              fullWidth
            />
          </FormControl>
        </Box>
      ),
      title: 'Select permission access',
      confirmBtnText: 'Set permission access',
      onConfirm: handleSetPermission,
      onCancel: handleClose,
    },
  };

  const addUserSelectedComponent = useMemo(
    () => addUserComponents[step],
    [step]
  );

  return (
    <Modal
      disableActions={isLoading}
      open={open}
      onClose={handleClose}
      title={addUserSelectedComponent.title}
      confirmBtnText={addUserSelectedComponent.confirmBtnText}
      cancelBtnText={addUserSelectedComponent.cancelBtnText}
      onConfirm={addUserSelectedComponent.onConfirm}
      onCancel={addUserSelectedComponent.onCancel}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <div key={step}>{addUserSelectedComponent.component}</div>
      )}
    </Modal>
  );
}

export default AddUserModal;
