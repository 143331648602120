import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { CreateRounded, DeleteRounded, Done } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import storage from '../../../../utils/storage';
import { DELAY } from '../../../../constants/store';

function EditField({ control, index, data, editable }) {
  if (editable) {
    return (
      <>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              inputProps={{ 'data-test-id': `field-${index}` }}
              style={{
                maxWidth: 96,
                marginRight: 4,
              }}
            />
          )}
          defaultValue={data.field}
          name={`meta.additional[${index}].field`}
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              inputProps={{ 'data-test-id': `info-${index}` }}
            />
          )}
          defaultValue={data.info}
          name={`meta.additional[${index}].info`}
          fullWidth
          control={control}
        />
      </>
    );
  }
  return (
    <p>
      <strong>{data.field}: </strong> {data.info}
    </p>
  );
}

EditField.propTypes = {
  control: PropTypes.object,
  data: PropTypes.object,
  index: PropTypes.number,
  editable: PropTypes.bool,
};

function AdditionalInfo({
  control,
  index,
  data,
  reset,
  getValues,
  hasStorage,
}) {
  const [editable, setEditable] = useState(false);

  const handleDelete = async () => {
    const values = {
      ...getValues(),
      loginOwnerPassword: '',
      OwnerPassword: '',
      appPassword: '',
      appUserPassword: '',
    };
    values?.meta?.additional?.splice(index, 1);
    reset(values);
    hasStorage && (await storage.set('restaurantInfo', values, DELAY));
  };

  const handleSaveAdditionalInfo = () => {
    const values = getValues();
    reset(values);
    setEditable(!editable);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flex={1}>
        <EditField
          editable={editable}
          control={control}
          data={data}
          index={index}
        />
      </Box>
      <Box display="flex">
        <IconButton
          data-test-id={`edit-button-${index}`}
          onClick={handleSaveAdditionalInfo}
        >
          {editable ? (
            <Done style={{ fill: '#00ff33', opacity: 0.67 }} />
          ) : (
            <CreateRounded style={{ fill: 'rgba(51, 51, 51, 0.67)' }} />
          )}
        </IconButton>
        <IconButton
          data-test-id={`remove-button-${index}`}
          onClick={handleDelete}
        >
          <DeleteRounded style={{ fill: '#E83C50' }} />
        </IconButton>
      </Box>
    </Box>
  );
}

AdditionalInfo.propTypes = {
  control: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  index: PropTypes.number,
  data: PropTypes.object,
  reset: PropTypes.func,
  getValues: PropTypes.func,
  hasStorage: PropTypes.bool,
};

export function FooterInfo({
  form: { control, getValues, reset },
  hasStorage = false,
}) {
  const additional = getValues('meta')?.additional;

  const handleAdditionalMeta = () => {
    const value = getValues();
    value?.meta?.additional?.push({ field: 'New Field', info: 'New Info' });
    reset(value);
  };

  useEffect(() => {
    const value = getValues();
    let hasAddress = false;
    let hasPhone = false;

    for (let i = 0; i < value?.meta?.additional?.length; i++) {
      if (value.meta.additional[i].field === 'Address') hasAddress = true;
      if (value.meta.additional[i].field === 'Phone') hasPhone = true;
    }

    !hasAddress &&
      value?.meta?.additional?.push({
        field: 'Address',
        info: getValues('address.formattedAddress'),
      });
    !hasPhone &&
      value?.meta?.additional?.push({
        field: 'Phone',
        info: getValues('phone'),
      });
    reset(value);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="32px"
      >
        <Typography variant="h5">Footer Info</Typography>
        <Button
          data-test-id="add-info"
          onClick={handleAdditionalMeta}
          style={{ background: 'rgba(42, 51, 40, 0.05)' }}
        >
          Add info
        </Button>
      </Box>
      {!!additional?.length &&
        additional.map((data, i) => (
          <AdditionalInfo
            key={String(i)}
            data={data}
            index={i}
            control={control}
            reset={reset}
            getValues={getValues}
            hasStorage={hasStorage}
          />
        ))}
    </>
  );
}

FooterInfo.propTypes = {
  form: PropTypes.object,
  hasStorage: PropTypes.bool,
};

export default FooterInfo;
