import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { Popover } from '@material-ui/core';

function ColorPicker({ anchorEl, onClose, color, setColor }) {
  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <SketchPicker color={color} onChange={setColor} disableAlpha />
    </Popover>
  );
}

ColorPicker.propTypes = {
  anchorEl: PropTypes.element,
  color: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setColor: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  anchorEl: null,
  color: '#999999',
};

export default ColorPicker;
