import { api } from './Api';

export const deleteImage = async (data) => {
  await api.upload(`/upload/${data}`, null, { method: 'DELETE' });
};

const loadImage = (url) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onprogress = (e) => {};

    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        resolve(blob);
      } else {
        reject(new Error(`Request failed with status ${xhr.status}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error('Network error occurred'));
    };
    xhr.send();
  });

export const getAllFiles = async () => {
  const response = await api.get('/upload');

  if (response?.length) {
    for (const item of response) {
      const imageUrl = `https:${item.url}?width=200&height=200`;
      // item['url'] = imageUrl;
      try {
        const blob = await loadImage(imageUrl);
        item.url = URL.createObjectURL(blob);
      } catch (error) {}
    }
    return response;
  }
};

export const getConfigFiles = async () => {
  const response = await api.get('/upload/config');
  return response;
};

export const uploadFile = async (file) => {
  if (!file[0]) {
    throw new Error('File not attached');
  }

  const data = new FormData();

  const fileData = file[0];
  data.append('file', fileData, fileData.name);
  const { id } = await api.post('/upload');
  const response = await api.upload(`/upload/${id}`, data);
  return {
    id,
    mime: file[0].type,
    url: response.url,
    size: file[0].size,
  };
};
