import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,
    background: '#FAFAFA',
    boxSizing: 'border-box',
    borderRadius: '2px',

    '& input, textarea': {
      padding: '14px 16px',
      border: '1px solid #D5D5D5',
      fontSize: '16px',
      color: '#000000',
    },

    '& .Mui-disabled': {
      fontSize: '16px',
      color: '#000000',
      borderRadius: '2px',
      border: '1px solid #FAFAFA',

      background: '#FAFAFA',
    },

    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  inputAdornmentEdit: {
    padding: '12.5px 10px',
    background: 'inherit',
    borderRadius: '0px 2px 2px 0px',
    height: '100%',
    color: '#fff',

    '& p': {
      color: '#fff',
      textDecorationLine: 'underline',
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  inputAdornment: {
    padding: '12.5px 10px',
    background: '#F5F3F3 !important',
    borderRadius: '0px 2px 2px 0px',
    height: '100%',
    color: '#AAA',

    '& p': {
      color: '#000',
      textDecorationLine: 'underline',
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
}));
