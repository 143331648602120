import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { Clear, Edit } from '@material-ui/icons';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';
import { useStyles } from './EditBusinessHours.styles';
import { saveRestaurant } from '../../../services/RestaurantsService';
import { Loading } from '../Loading/Loading';
import BusinessHours from '../../input/AddRestaurantForm/Steps/BusinessHours';
import { useGetErrors } from '../../../hooks/useGetErrors/useGetErrors';

const shiftSchema = yup.object().shape({
  open: yup.number().required(),
  close: yup.number().required().moreThan(yup.ref('open')),
});

const partialSchema = yup.object().shape({
  open: yup.bool(),
  shift: yup
    .array()
    .when('open', { is: true, then: yup.array().of(shiftSchema) }),
});

const FormSchema = yup.object().shape({
  business: yup.object().shape({
    monday: partialSchema,
    tuesday: partialSchema,
    wednesday: partialSchema,
    thursday: partialSchema,
    friday: partialSchema,
    saturday: partialSchema,
    sunday: partialSchema,
  }),
  delivery: yup.object().shape({
    same: yup.bool(),
    monday: yup.object().when('same', { is: false, then: partialSchema }),
    tuesday: yup.object().when('same', { is: false, then: partialSchema }),
    wednesday: yup.object().when('same', { is: false, then: partialSchema }),
    thursday: yup.object().when('same', { is: false, then: partialSchema }),
    friday: yup.object().when('same', { is: false, then: partialSchema }),
    saturday: yup.object().when('same', { is: false, then: partialSchema }),
    sunday: yup.object().when('same', { is: false, then: partialSchema }),
  }),
  takeout: yup.object().shape({
    same: yup.bool(),
    monday: yup.object().when('same', { is: false, then: partialSchema }),
    tuesday: yup.object().when('same', { is: false, then: partialSchema }),
    wednesday: yup.object().when('same', { is: false, then: partialSchema }),
    thursday: yup.object().when('same', { is: false, then: partialSchema }),
    friday: yup.object().when('same', { is: false, then: partialSchema }),
    saturday: yup.object().when('same', { is: false, then: partialSchema }),
    sunday: yup.object().when('same', { is: false, then: partialSchema }),
  }),
});

export function EditBusinessHours() {
  const classes = useStyles();
  const { restaurant, edit, setLoading, setRestaurant, setEdit, loading } =
    useRestaurantMenu();
  const {
    getValues,
    reset,
    trigger,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: restaurant,
    shouldUnregister: false,
    resolver: yupResolver(FormSchema),
    mode: 'onChange',
  });
  const { getErrors, hasAnyError } = useGetErrors({ errors });

  useEffect(() => {
    reset(restaurant);
  }, [restaurant, reset]);

  const handleSave = async () => {
    await trigger();
    if (!hasAnyError()) {
      setLoading(true);
      try {
        const response = await saveRestaurant({
          id: restaurant.id,
          data: getValues(),
        });
        setRestaurant(response);
        setLoading(false);
        setEdit(!edit);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancelEdit = () => {
    reset(restaurant);
    setEdit(false);
  };

  if (loading)
    return (
      <Box className={classes.root}>
        <Loading />
      </Box>
    );

  return (
    <form style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Box className={classes.root}>
        <BusinessHours
          form={{ control, watch, getValues, getErrors, reset }}
          disabled={!edit}
        />
      </Box>
      <Box position="fixed" bottom="40px" right="80px">
        {edit ? (
          <Box display="flex">
            <Box style={{ backgroundColor: '#fff', marginLeft: '30px' }}>
              <Button
                className={classes.editButton}
                data-test-id="cancel"
                onClick={handleCancelEdit}
              >
                <Clear fontSize="small" style={{ marginRight: '8px' }} />
                Cancel
              </Button>
            </Box>
            <Box style={{ backgroundColor: '#fff', marginLeft: '30px' }}>
              <Button
                className={classes.saveButton}
                data-test-id="save-info"
                onClick={handleSave}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        ) : (
          <Box style={{ backgroundColor: '#fff', marginLeft: '30px' }}>
            <Button
              className={classes.editButton}
              data-test-id="edit-info"
              onClick={handleEdit}
            >
              <Edit fontSize="small" style={{ marginRight: '8px' }} />
              Edit Info
            </Button>
          </Box>
        )}
      </Box>
    </form>
  );
}
