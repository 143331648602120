import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 256,
    borderTopRightRadius: 16,
    borderTop: '1px solid #d5d5d4',
    borderRight: '1px solid #d5d5d4',
    background: '#fff',
    padding: '32px 24px 83px 0',
    position: 'fixed',
    height: 'calc(100% - 80px)',
    bottom: '0',
    left: '0',
  },

  mobile: {
    position: 'unset',
    border: 'none',
    padding: 0,
    paddingRight: 16,
    paddingBottom: 48,
    minHeight: '0',
  },

  listItem: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    padding: '12px 16px',
    background: 'rgba(0, 0, 0, 0.02)',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },

    '&:not(:last-child)': {
      marginBottom: '6px',
    },
  },
  textMenu: {
    color: '#000',
    fontWeight: 600,
  },
  listSubitem: {
    marginTop: '4px',
    padding: '4px 16px',
    borderRadius: '0 12px 12px 0',
    width: '100%',
    justifyContent: 'start',
    cursor: 'pointer',
    backgroundColor: 'rgba(40, 43, 49, 0.02)',

    '&:hover': {
      backgroundColor: 'rgba(40, 43, 49, 0.05)',
    },
  },
  active: {
    backgroundColor: 'rgba(67, 87, 239, 0.05)',

    '& > p': {
      color: '#4357EF',
    },

    '&:hover': {
      backgroundColor: 'rgba(67, 87, 239, 0.05)',
    },
  },
  restaurantName: {
    fontWeight: '500',
    color: '#000000CC',
  },
  restaurantAddress: {
    fontSize: '10px',
    opacity: '0.6',
  },
  titleWrapper: {
    cursor: 'pointer',
    marginBottom: '24px',
    borderRadius: '0 12px 12px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: '12px 0',
    minHeight: '75px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  published: {
    color: theme.palette.restaurantStatus.active,
    fontWeight: 'bold',
  },
  unpublished: {
    color: theme.palette.restaurantStatus.inactive,
    fontWeight: 'bold',
  },
  draft: {
    color: theme.palette.restaurantStatus.draft,
    fontWeight: 'bold',
  },
  activeStatus: {
    backgroundColor: theme.palette.restaurantStatus.active,
  },
  inactiveStatus: {
    backgroundColor: theme.palette.restaurantStatus.inactive,
  },
  draftStatus: {
    backgroundColor: theme.palette.restaurantStatus.draft,
  },
  statusIcon: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'block',
    marginRight: '8px',
  },
  statusBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
  },
  editMode: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  editModeWrapper: {
    cursor: 'not-allowed',
  },
  stripeWarning: {
    marginLeft: 'auto',
    marginRight: '12px',
  },
}));
