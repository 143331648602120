import { formatResponse } from '../utils/FormatUtils/formatResponse';
import { api } from './Api';

export const registerAppUser = ({ token: { token }, id }) =>
  api.post(`/localareamng/restaurant/${id}/app/user`, {
    token,
  });

export const searchUser = ({ email }) =>
  api.get('/localareamng/users/search', {
    email,
  });

export const listUsers = async (id) => {
  const response = await api.get(`/localareamng/restaurant/${id}/users`);
  return formatResponse(response, 'users');
};

export const addUser = ({ userId, restaurantId, role }) =>
  api.post(`/localareamng/restaurant/${restaurantId}/users`, {
    user_id: userId,
    role_type: role,
  });

export const updateUser = ({ userId, restaurantId, roleId, role }) =>
  api.put(
    `/localareamng/restaurant/${restaurantId}/users/${userId}/roles/${roleId}`,
    {
      role_type: role,
    }
  );

export const deleteUser = ({ userId, restaurantId, role }) =>
  api.delete(
    `/localareamng/restaurant/${restaurantId}/users/${userId}`,
    {},
    { body: { role_type: role } }
  );
