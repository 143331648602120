import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './index.css';
import App from './components/layout/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

serviceWorkerRegistration.unregister();

Bugsnag.start({
  apiKey: 'ea4fc92af30786935ebf8ddfc9edf4f5',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorkerRegistration.register();
reportWebVitals();
