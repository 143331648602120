import React from 'react';
import PropTypes from 'prop-types';
import '@fontsource/montserrat';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        letterSpacing: 0,
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid rgba(60, 85, 232, 0.05)',

        '& > div > div > button ': {
          background: 'rgba(60, 85, 232, 0.03)',
          marginRight: 4,
          borderRadius: '8px 8px 0 0',
          padding: '8px 12px',
          color: 'rgba(60, 85, 232, 0.7)',
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: 0,

        '@media (min-width:600px)': {
          minWidth: 70,
        },
      },
    },
    MuiDropzoneArea: {
      root: {
        display: 'flex',
        minHeight: 0,
        color: '#00000099',
      },
      text: {
        fontSize: 16,
      },
      textContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      icon: {
        width: 24,
        height: 24,
        marginLeft: 24,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#fff',
        },
      },
    },
    MuiTextField: {
      root: {
        '& label.Mui-focused': {
          color: '#3C55E8',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#3C55E8',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: '1px solid #0000001F',
          },
          '&:hover fieldset': {
            border: '1px solid #0000003D',
          },
          '&.Mui-focused fieldset': {
            border: '1px solid #3C55E8',
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: '#F2F2F2',
          color: 'rgba(0,0,0,0.2)',
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#3394ff',
      main: '#007AFF',
      dark: '#0055b2',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ec6b51',
      main: '#E84626',
      dark: '#a2311a',
      contrastText: '#fff',
    },
    restaurantStatus: {
      active: '#3AE02C',
      inactive: '#E84626',
      draft: '#E0B92C',
    },
    background: {
      default: '#fafafa',
      paper: '#fafafa',
      mediumGray: '#666',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {},
    h2: {
      color: '#333',
      fontWeight: 400,
      fontSize: '1.5rem',
    },

    h3: {
      color: '#333',
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    h4: {
      color: '#333',
      fontWeight: 600,
      fontSize: '0.875rem',
    },
    h5: {
      fontWeight: 400,
      color: '#333',
    },
    h6: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    subtitle1: {},
    subtitle2: {
      fontSize: '0.875rem',
      color: 'rgba(51, 51, 51, 0.57)',
      marginRight: '25px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.875rem',
      color: '#333',
      fontWeight: 400,
    },
    body2: {},
    button: {},
    caption: {},
  },
});

export function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

Theme.propTypes = {
  children: PropTypes.node,
};
