/* eslint-disable react/display-name */
import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  Typography,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { AddRounded, Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { useStyles } from './Steps.styles';
import storage from '../../../../utils/storage';
import { weekDay } from '../../../../constants/time';
import { generateList } from '../../../../utils/hours';
import { DELAY } from '../../../../constants/store';

export function IsOpen({ open }) {
  const classes = useStyles();

  return (
    <Typography
      variant="subtitle2"
      className={clsx({
        [classes.closed]: !open,
      })}
    >
      {open ? 'Open' : 'Closed'}
    </Typography>
  );
}

IsOpen.propTypes = {
  open: PropTypes.bool,
};

const hrs = generateList();

export function RenderWeek({
  marginBottom = '0',
  field = 'business',
  control,
  watch,
  handleAddShift,
  handleRemoveShift,
  getErrors,
  disabled,
}) {
  watch();
  const classes = useStyles();
  const hours = useMemo(
    () =>
      hrs.map((h) => (
        <MenuItem key={h.label} value={h.value}>
          {h.label}
        </MenuItem>
      )),
    []
  );

  return (
    <Box display="flex" marginBottom={marginBottom}>
      <FormControl style={{ flex: 1, display: 'flex' }}>
        <FormGroup style={{ flex: 1 }}>
          {weekDay.map((day) => {
            const dayState = watch(`${field}.${day}`);
            const shift = watch(`${field}.${day}.shift`)
              ? watch(`${field}.${day}.shift`)
              : [{ open: 480, close: 900 }];
            return (
              <Box key={day} className={classes.weekWrapper}>
                <Box
                  flex="0.6"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  alignSelf="flex-start"
                  marginTop="2px"
                >
                  <Typography variant="h4" classes={{ h4: classes.weekDay }}>
                    {day}
                  </Typography>
                  <Box className={classes.switchWrapper}>
                    <Controller
                      name={`${field}.${day}.open`}
                      control={control}
                      defaultValue
                      render={({
                        field: { onChange, value, ref, ...props },
                      }) => (
                        <Switch
                          inputRef={ref}
                          color="primary"
                          onChange={onChange}
                          checked={value}
                          disabled={disabled}
                          {...props}
                        />
                      )}
                    />
                    <IsOpen open={dayState?.open} />
                  </Box>
                </Box>
                <Box
                  flex="1"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  className={clsx(classes.select, {
                    [classes.hidden]: !dayState?.open,
                  })}
                >
                  {shift.map((_, i) => (
                    <Box
                      key={`${field}.${day}.${i}`}
                      display={dayState?.open || i === 0 ? 'flex' : 'none'}
                      data-test-id={`${field}-${day}-shift`}
                    >
                      <Box className={classes.selectWrapper}>
                        <Box className={classes.selectInner}>
                          <FormControl
                            variant="outlined"
                            error={
                              getErrors(`${field}.${day}.shift[${i}].open`)
                                .error
                            }
                            className={clsx(classes.selectControl, {
                              [classes.formControlSelect]: i > 0,
                            })}
                          >
                            <Controller
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  disabled={disabled}
                                  className={classes.dropdownSelect}
                                >
                                  <MenuItem value="" disabled>
                                    Select
                                  </MenuItem>
                                  {hours}
                                </Select>
                              )}
                              data-test-id={`${field}-${day}-shift[${i}]-open`}
                              name={`${field}.${day}.shift[${i}].open`}
                              rules={{ required: 'this is required' }}
                              control={control}
                              fullWidth
                              defaultValue={480}
                            />
                          </FormControl>
                        </Box>
                        <p className={classes.divider}>-</p>
                        <Box className={classes.selectInner}>
                          <FormControl
                            variant="outlined"
                            error={
                              getErrors(`${field}.${day}.shift[${i}].close`)
                                .error
                            }
                            className={clsx(classes.selectControl, {
                              [classes.formControlSelect]: i > 0,
                            })}
                          >
                            <Controller
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  disabled={disabled}
                                  className={classes.dropdownSelect}
                                >
                                  <MenuItem value="" disabled>
                                    Select
                                  </MenuItem>
                                  {hours}
                                </Select>
                              )}
                              name={`${field}.${day}.shift[${i}].close`}
                              rules={{ required: 'this is required' }}
                              control={control}
                              fullWidth
                              defaultValue={900}
                            />
                          </FormControl>
                          {!disabled && (
                            <Box display="flex" alignItems="center">
                              {i > 0 ? (
                                <IconButton
                                  className={classes.removeShiftButton}
                                  onClick={() =>
                                    handleRemoveShift(field, day, i)
                                  }
                                  data-test-id={`${field}-${day}-remove-shift-${i}`}
                                >
                                  <Delete fontSize="small" />
                                </IconButton>
                              ) : (
                                <>
                                  {shift.length < 2 && (
                                    <Button
                                      className={clsx(classes.addShiftButton)}
                                      onClick={() => handleAddShift(field, day)}
                                      data-test-id={`${field}-${day}-add-shift`}
                                    >
                                      <AddRounded
                                        className={classes.shiftButtonIcon}
                                        fontSize="small"
                                      />
                                      <p className={classes.shiftButtonText}>
                                        Add Shift
                                      </p>
                                    </Button>
                                  )}
                                </>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  {shift.length < 2 && (
                    <Button
                      className={clsx(classes.addShiftMobile, {
                        [classes.hiddenButton]: !dayState?.open,
                      })}
                      onClick={() => handleAddShift(field, day)}
                      data-test-id={`${field}-${day}-add-shift-mobile`}
                    >
                      <AddRounded
                        className={classes.shiftButtonIcon}
                        fontSize="small"
                      />
                      <p className={classes.shiftButtonText}>Add Shift</p>
                    </Button>
                  )}
                </Box>
              </Box>
            );
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
}

RenderWeek.propTypes = {
  marginBottom: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  field: PropTypes.string,
  control: PropTypes.object,
  watch: PropTypes.func,
  handleAddShift: PropTypes.func,
  handleRemoveShift: PropTypes.func,
  getErrors: PropTypes.func,
  disabled: PropTypes.bool,
};

export function BusinessHours({
  form: { control, watch, getValues, getErrors, reset },
  disabled = false,
}) {
  const classes = useStyles();
  const takeoutSame = watch('takeout.same');
  const deliverySame = watch('delivery.same');

  const handleAddShift = async (field, day) => {
    const newShift = {
      ...getValues(),
      loginOwnerPassword: '',
      OwnerPassword: '',
      appPassword: '',
      appUserPassword: '',
    };
    newShift[`${field}`][`${day}`].shift.push({ open: 900, close: 1200 });
    reset(newShift);
    await storage.set('restaurantInfo', newShift, DELAY);
  };

  const handleRemoveShift = useCallback(
    (field, day, i) => {
      const newShift = getValues();
      newShift[`${field}`][`${day}`].shift.splice(i, 1);
      reset(newShift);
    },
    [getValues, reset]
  );

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Business Hours
      </Typography>
      {getErrors('business').error && (
        <Typography className={classes.error}>
          Close time must be after open time
        </Typography>
      )}
      <Box display="flex">
        <Box flex={1}>
          <RenderWeek
            watch={watch}
            control={control}
            marginBottom="32px"
            field="business"
            handleAddShift={handleAddShift}
            handleRemoveShift={handleRemoveShift}
            getErrors={getErrors}
            disabled={disabled}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="32px"
            alignItems="center"
          >
            <Typography variant="h5" className={classes.formInsideTitle}>
              Delivery Hours
            </Typography>
            <Box>
              <Box className={classes.sameSwitch}>
                <Typography
                  variant="subtitle2"
                  className={classes.formInsideSubtitle}
                >
                  Same as business hours
                </Typography>
                <Controller
                  name="delivery.same"
                  control={control}
                  defaultValue
                  render={({ field: { onChange, value, ...props } }) => (
                    <Switch
                      disabled={disabled}
                      color="primary"
                      onChange={onChange}
                      checked={value}
                      {...props}
                    />
                  )}
                />
              </Box>
              {getErrors('delivery').error && (
                <Typography className={classes.error}>
                  Close time must be after open time
                </Typography>
              )}
            </Box>
          </Box>
          <Collapse in={!deliverySame}>
            <RenderWeek
              watch={watch}
              control={control}
              marginBottom="32px"
              field="delivery"
              getErrors={getErrors}
              handleAddShift={handleAddShift}
              handleRemoveShift={handleRemoveShift}
              disabled={disabled}
            />
          </Collapse>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="32px"
            alignItems="center"
          >
            <Typography variant="h5" className={classes.formInsideTitle}>
              Takeout Hours
            </Typography>
            <Box>
              <Box className={classes.sameSwitch}>
                <Typography
                  variant="subtitle2"
                  className={classes.formInsideSubtitle}
                >
                  Same as business hours
                </Typography>
                <Controller
                  name="takeout.same"
                  defaultValue
                  control={control}
                  render={({ field: { onChange, value, ...props } }) => (
                    <Switch
                      disabled={disabled}
                      color="primary"
                      onChange={onChange}
                      checked={value}
                      {...props}
                    />
                  )}
                />
              </Box>
              {getErrors('takeout').error && (
                <Typography className={classes.error}>
                  Close time must be after open time
                </Typography>
              )}
            </Box>
          </Box>
          <Collapse in={!takeoutSame}>
            <RenderWeek
              watch={watch}
              control={control}
              marginBottom="32px"
              field="takeout"
              getErrors={getErrors}
              handleAddShift={handleAddShift}
              handleRemoveShift={handleRemoveShift}
              disabled={disabled}
            />
          </Collapse>
        </Box>
      </Box>
    </>
  );
}

BusinessHours.propTypes = {
  form: PropTypes.object,
  disabled: PropTypes.bool,
};

export default BusinessHours;
