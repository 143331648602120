import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  Modal as MuiModal,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import { useStyles } from './Modal.styles';

function Modal({
  open,
  children,
  onConfirm,
  onClose,
  onCancel,
  actionBtnColor,
  displayConfirmButton,
  title,
  confirmBtnText,
  cancelBtnText,
  disableActions,
}) {
  const classes = useStyles();

  return (
    <MuiModal
      style={{ outline: 0 }}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.content}>
          <Box className={classes.header}>
            <Typography>{title}</Typography>
            <IconButton
              disabled={disableActions}
              onClick={onClose}
              data-test-id="close-button"
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box className={classes.body}>{children}</Box>
          <Divider />
          <Box className={classes.footer}>
            <Button
              disabled={disableActions}
              className={classes.cancelBtn}
              onClick={onCancel}
              data-test-id="cancel-button"
            >
              {cancelBtnText}
            </Button>
            {displayConfirmButton && (
              <Button
                disabled={disableActions}
                variant="contained"
                data-test-id="confirm-button"
                className={classes.confirmBtn}
                onClick={onConfirm}
                color={actionBtnColor}
              >
                {confirmBtnText}
              </Button>
            )}
          </Box>
        </Box>
      </Fade>
    </MuiModal>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  actionBtnColor: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
  ]),
  displayConfirmButton: PropTypes.bool,
  title: PropTypes.string,
  confirmBtnText: PropTypes.node,
  cancelBtnText: PropTypes.node,
  disableActions: PropTypes.bool,
};

Modal.defaultProps = {
  open: false,
  children: <div />,
  actionBtnColor: 'primary',
  displayConfirmButton: true,
  title: '',
  confirmBtnText: 'Confirm',
  cancelBtnText: 'Cancel',
  disableActions: false,
};

export default Modal;
