import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,

    '& fieldset': {
      borderRadius: 2,
    },

    '& input, textarea': {
      padding: '14px 16px',
      fontSize: '16px',
    },

    '& .Mui-disabled': {
      fontSize: '16px',
      color: '#000000',
      borderRadius: '2px',
      border: '1px solid #FAFAFA',
    },

    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  ownerName: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div:not(:first-child)': {
      marginLeft: '30px',
    },

    [theme.breakpoints.down('xs')]: {
      display: 'block',

      '& > div:not(:first-child)': {
        marginLeft: '0px',
      },
    },
  },
  lowerCase: {
    '& > div > input': {
      textTransform: 'lowercase',
    },
  },
  generatePasswordBtn: {
    marginLeft: '14px',
    marginBottom: '24px',
    marginTop: '-16px',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '15px',
    width: 'fit-content',

    color: theme.palette.primary.main,

    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));
