import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  formInputBoxAutocomplete: {
    background: '#FAFAFA',
    boxSizing: 'border-box',

    '& > .MuiInput-underline:before': {
      border: 'none',
    },
    '& > .MuiInput-underline:after': {
      border: 'none',
    },
    '& > .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },

    '& input, textarea': {
      borderRadius: '2px',
      padding: '18px 14px !important',
      border: '1px solid #D5D5D5',
      fontSize: '16px',
      color: '#000000',
    },

    '& .Mui-disabled': {
      fontSize: '16px',
      color: 'rgba(0,0,0,0.2)',
      borderRadius: '2px',
      border: '1px solid #FAFAFA',

      background: '#FAFAFA',
    },
  },
}));
