import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';

export function SigningIn() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress style={{ marginBottom: 5 }} />
      <Typography>Signing in</Typography>
    </Box>
  );
}
