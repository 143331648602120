import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Switch, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useStyles } from '../AddEditCouponSections.styles';

function UserConditions({ form: { control } }) {
  const classes = useStyles();

  return (
    <Box className={classes.sectionContent}>
      <Box className={classes.alignItemsCenter}>
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="onePerUse"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                onChange={onChange}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>One per user</Typography>
        </Box>
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="onlyNewUser"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                onChange={onChange}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>
            Available only to new users
          </Typography>
        </Box>
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="singleUse"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                onChange={onChange}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>Single Use</Typography>
        </Box>
      </Box>
    </Box>
  );
}

UserConditions.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
  ),
};

export default UserConditions;
