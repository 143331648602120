import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';

import * as yup from 'yup';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';

import { useStyles } from './ResetPasswordForm.styles';

const FormSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export function ResetPasswordForm({ onSubmit, isLoading }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.contents}>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Form
          schema={FormSchema}
          className={classes.form}
          noValidate
          onSubmit={onSubmit}
          aria-label="reset password form"
        >
          <Input
            className={classes.input}
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="email"
            label="Email"
            placeholder="Type your email"
            type="email"
            id="email"
            autoComplete="email"
            inputProps={{ 'data-test-id': 'reset-password:email-input' }}
          />
          <Input
            className={classes.input}
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            placeholder="Type your password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputProps={{ 'data-test-id': 'reset-password:password-input' }}
          />
          <Input
            className={classes.input}
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm your password"
            placeholder="Confirm your password"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
            inputProps={{
              'data-test-id': 'reset-password:confirm-password-input',
            }}
          />
          <Box display="flex" justifyContent="center">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={isLoading}
              data-test-id="reset-password:button"
              aria-label="reset password button"
            >
              Set password
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  backendError: PropTypes.string,
  handleClose: PropTypes.func,
};
