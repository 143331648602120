import Environment from '@eatzy/common-reactjs/Environment';
import { capitalize } from 'lodash';
import { api } from './Api';

export const getLocation = async ({ street, city, state, zip }) => {
  const response = await api.get('/maps/location', {
    key: Environment.getEnv('GOOGLE_API_KEY'),
    address: street,
    city,
    state,
    zip,
  });
  const address = {
    formattedAddress: `${response.formatted_address}`,
    street: `${response.number ? `${response.number} ` : ''}${response.street}`,
    city: `${response.city ? response.city : capitalize(city)}`,
    state: `${response.state || state}`,
    zip: `${response.zipcode || zip}`,
    location: {
      lat: Number(response.latitude),
      lng: Number(response.longitude),
    },
  };

  return address;
};
