import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './NavigationStepper.styles';

import { useNavigation } from './context/navigation';

export function NavigationStepper({
  skip,
  back,
  disableNext,
  goToRestaurants,
  stepValidation,
}) {
  const history = useHistory();
  const classes = useStyles();
  const { nextPage, previousPage, skipPage } = useNavigation();

  const goToNextPage = useCallback(async () => {
    const isValid = await stepValidation();
    if (isValid) nextPage();
  }, [stepValidation, nextPage]);

  return (
    <div className={classes.root}>
      <Button
        data-test-id="KTn3Co15NWdI5xRQGxBjp"
        className={clsx(classes.navigationButton, {
          [classes.hide]: !back,
          [classes.gone]: disableNext,
        })}
        disabled={!back}
        onClick={previousPage}
      >
        Back
      </Button>
      <div>
        <Button
          data-test-id="dgNieun1TK11LzOxXtJRY"
          className={clsx(classes.navigationButton, {
            [classes.hide]: !skip.can,
            [classes.gone]: disableNext,
          })}
          onClick={() => skipPage(skip.skip)}
          disabled={!skip.can}
        >
          Skip
        </Button>
        <Button
          data-test-id="YUBdXYEo273BH5RpNJZTf"
          className={clsx(classes.nextButton, {
            [classes.hide]: disableNext,
            [classes.gone]: disableNext,
          })}
          onClick={goToNextPage}
          disabled={disableNext}
        >
          Next
        </Button>
        <Button
          data-test-id="j8axpusZ4_qgdCT-swHm_"
          className={clsx(classes.nextButton, {
            [classes.gone]: !goToRestaurants,
          })}
          onClick={() => history.push('/restaurants')}
          disabled={!goToRestaurants}
        >
          Go to Restaurants
        </Button>
      </div>
    </div>
  );
}

NavigationStepper.propTypes = {
  skip: PropTypes.object,
  back: PropTypes.bool,
  goToRestaurants: PropTypes.bool,
  disableNext: PropTypes.bool,
  stepValidation: PropTypes.func,
};

NavigationStepper.defaultProps = {
  skip: { can: false, skip: 0 },
  back: false,
  goToRestaurants: false,
  disableNext: false,
  stepValidation: async () => true,
};
