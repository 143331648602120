import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { getPublicConfig } from '../../../../services/StripeService';
import { useNavigation } from '../../NavigationStepper/context/navigation';
import ownerAuth from '../../../../utils/ownerAuthentication';
import { useStyles } from './Steps.styles';
import useNotifications from '../../../../hooks/useNotifications';
import { useAddRestaurantContext } from '../../../../context/AddRestaurantContext';
import OwnerLogin from '../../OwnerLogin';
import { Loading } from '../../../layout/Loading';
import { useRestaurantMenu } from '../../../../context/RestaurantMenuContext';

import buttonImg from '../../../../assets/images/stripe/blue-on-light.png';

export function Connect() {
  const { enqueueSnackbar } = useNotifications();
  const { search } = useLocation();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState(
    'Something went wrong'
  );
  const { nextPage } = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const [clientId, setClientId] = useState('');
  const [tokenError, setTokenError] = useState(false);
  const [ownerProfile, setOwnerProfile] = useState({});
  const { logout, ownerData } = useAddRestaurantContext();
  const { restaurant } = useRestaurantMenu();

  let redirectMode = 'edit';

  useEffect(() => {
    const getClientId = async () => {
      try {
        setTokenError(false);
        await ownerAuth.refreshToken(restaurant?.id);
        const retrivedOwnerProfile = await ownerAuth.getProfile(
          restaurant?.id,
          true
        );
        setOwnerProfile(retrivedOwnerProfile);
        const data = await getPublicConfig();
        setClientId(data.client_id);
      } catch {
        setTokenError(true);
        await logout('owner');
      } finally {
        setIsLoading(false);
      }
    };
    if (!_.isNil(search) && !_.isEmpty(search)) {
      const params = qs.parse(
        search.indexOf('?') >= 0
          ? search.substring(search.indexOf('?') + 1)
          : search
      );

      if (!_.isNil(params.error) && params.error !== '0') {
        setError(true);
        setIsLoading(true);
        getClientId();
        params.error_description &&
          setErrorDescription(params.error_description);
        enqueueSnackbar({
          message: params.error_description || 'Something went wrong!',
          options: {
            variant: 'error',
          },
        });
      }

      if (_.isNil(params.error) || params.error === '0') nextPage();
    } else {
      redirectMode = 'add';

      setIsLoading(true);
      getClientId();
    }
  }, [search, logout, enqueueSnackbar, restaurant, ownerData.isAuthenticated]);

  if (isLoading) return <Loading />;

  if (!ownerProfile.id) {
    return (
      <>
        {tokenError && (
          <Typography className={classes.error}>
            Owner token expired, please go to restaurants and edit the setup
            info
          </Typography>
        )}
        <OwnerLogin />
      </>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h5" className={classes.formTitle}>
        Connect with Stripe
      </Typography>
      <a
        data-test-id="connect-stripe"
        style={{ alignSelf: 'flex-start' }}
        href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&state=${
          ownerProfile.id
        }_${redirectMode}_${
          restaurant?.id || 0
        }&scope=read_write&redirect_uri=${
          window.location.origin
        }/stripeconnect`}
      >
        <img alt="stripe button" src={buttonImg} />
      </a>
      <Typography variant="h6">
        <span className={classes.ownerEmail}>{ownerProfile.email}</span> is set
        as owner user.
      </Typography>
      {error && (
        <Typography className={classes.error}>{errorDescription}</Typography>
      )}
    </Box>
  );
}

export default Connect;
