import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Switch, TextField, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { CalendarToday } from '@material-ui/icons';
import { format } from 'date-fns';
import { useStyles } from '../AddEditCouponSections.styles';
import { DatePicker } from '../../DatePicker';

function PromoConditions({
  form: { getValues, setValue, control, getErrors },
}) {
  const classes = useStyles();

  const startDateEl = useRef();
  const endDateEl = useRef();

  const [popoverElStart, setPopoverElStart] = useState(null);
  const [popoverElEnd, setPopoverElEnd] = useState(null);

  const handleSetCustomDate = (date, fieldName) => {
    setValue(fieldName, date);
  };

  return (
    <Box className={classes.sectionContent}>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="promoRequired"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                data-test-id="promo-switch"
                onChange={onChange}
                checked={value}
                disabled={false}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>Feature as a Promo</Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 2, flexWrap: 'wrap' }}>
          <Box mr={3}>
            <Typography
              variant="h6"
              className={clsx(classes.fieldLabel, {
                [classes.op20]: !getValues('promoRequired'),
              })}
            >
              Title:
            </Typography>
            <Controller
              control={control}
              name="promoItems.title"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="promoItems.title"
                  disabled={!getValues('promoRequired')}
                  fullWidth
                  inputProps={{
                    'data-test-id': 'promoItems-title',
                  }}
                  onBlur={(a) => setValue('promoItems.title', a.target.value)}
                  onChange={(a) => setValue('promoItems.title', a.target.value)}
                  variant="outlined"
                  placeholder="Title Text"
                  className={classes.defaultInput}
                  {...getErrors('promoItems.title')}
                />
              )}
            />
          </Box>
          <Box mr={3}>
            <Typography
              variant="h6"
              className={clsx(classes.fieldLabel, {
                [classes.op20]: !getValues('promoRequired'),
              })}
            >
              Offer:
            </Typography>
            <Controller
              control={control}
              name="promoItems.offer"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="promoItems.offer"
                  disabled={!getValues('promoRequired')}
                  fullWidth
                  inputProps={{
                    'data-test-id': 'promoItems-offer',
                  }}
                  onBlur={(a) => setValue('promoItems.offer', a.target.value)}
                  onChange={(a) => setValue('promoItems.offer', a.target.value)}
                  variant="outlined"
                  placeholder="Offer Text"
                  className={classes.defaultInput}
                  {...getErrors('promoItems.offer')}
                />
              )}
            />
          </Box>
          <Box>
            <Typography
              variant="h6"
              className={clsx(classes.fieldLabel, {
                [classes.op20]: !getValues('promoRequired'),
              })}
            >
              Description:
            </Typography>
            <Controller
              control={control}
              name="promoItems.description"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="promoItems.description"
                  minRows={3}
                  maxRows={6}
                  disabled={!getValues('promoRequired')}
                  fullWidth
                  multiline
                  inputProps={{
                    'data-test-id': 'promoItems-description',
                  }}
                  onBlur={(a) =>
                    setValue('promoItems.description', a.target.value)
                  }
                  onChange={(a) =>
                    setValue('promoItems.description', a.target.value)
                  }
                  variant="outlined"
                  placeholder="Description"
                  className={classes.defaultInput}
                  {...getErrors('promoItems.description')}
                />
              )}
            />
          </Box>
        </Box>
        {getValues('promoRequired') && (
          <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', mt: 1 }}>
            <Box className={classes.mr24}>
              <Typography
                variant="h6"
                className={classes.fieldLabel}
                ref={startDateEl}
              >
                Start Date
              </Typography>
              <TextField
                onClick={() => setPopoverElStart(startDateEl.current)}
                data-test-id="deal-start-date"
                value={format(getValues('promoItems.startDate'), 'MMM d, y')}
                fullWidth
                variant="outlined"
                className={classes.defaultInput}
                InputProps={{
                  endAdornment: <CalendarToday color="disabled" />,
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="h6"
                className={classes.fieldLabel}
                ref={endDateEl}
              >
                End Date
              </Typography>
              <TextField
                onClick={() => setPopoverElEnd(endDateEl.current)}
                data-test-id="deal-end-date"
                value={format(getValues('promoItems.endDate'), 'MMM d, y')}
                fullWidth
                variant="outlined"
                className={classes.defaultInput}
                InputProps={{
                  endAdornment: <CalendarToday color="disabled" />,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <DatePicker
        popoverEl={popoverElStart}
        handleClosePopover={() => setPopoverElStart(null)}
        customDate={getValues('promoItems.startDate')}
        setCustomDate={(date) =>
          handleSetCustomDate(date, 'promoItems.startDate')
        }
      />
      <DatePicker
        popoverEl={popoverElEnd}
        handleClosePopover={() => setPopoverElEnd(null)}
        customDate={getValues('promoItems.endDate')}
        setCustomDate={(date) =>
          handleSetCustomDate(date, 'promoItems.endDate')
        }
      />
    </Box>
  );
}

PromoConditions.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
  ),
};

export default PromoConditions;
