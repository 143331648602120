import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import _ from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';
import ownerAuth from '../../../utils/ownerAuthentication';
import { connectAccount } from '../../../services/StripeService';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';

function RedirectRestaurant() {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState(null);
  const [callbackPage, setCallbackPage] = useState();
  const { restaurant } = useRestaurantMenu();

  const handleConnectAccount = async (account, state) => {
    try {
      const { id } = await ownerAuth.getProfile(restaurant?.id, true);

      if (id === state) {
        await connectAccount({ data: { account } });
      } else {
        console.error(`Wrong owner, id=${id}, state=${state}`);
        setQueryParams(
          `?error=wrong_restaurant_id&error_description=${encodeURI(
            'restaurant id is wrong'
          )}`
        );
      }
    } catch (err) {
      console.error(err);
      setQueryParams(
        `?error=missing_owner_profile&error_description=${encodeURI(
          'owner profile not found'
        )}`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isNil(search) && !_.isEmpty(search)) {
      const params = qs.parse(
        search.indexOf('?') >= 0
          ? search.substring(search.indexOf('?') + 1)
          : search
      );

      if (!_.isEmpty(params.code) && !_.isEmpty(params.state)) {
        const stateParts = params.state.split('_');

        if (stateParts.length === 3) {
          const restaurantId = parseInt(stateParts[2], 10);
          const ownerId = parseInt(stateParts[0], 10);

          setCallbackPage(restaurantId);
          handleConnectAccount(params.code, ownerId);
        } else {
          console.error('stateParts not valid', stateParts);
        }
      }

      if (!_.isNil(params.error)) {
        if (params.error !== '0') {
          setQueryParams(
            `?error=${params.error}&state=${params.state}&error_description=${
              params.error_description
                ? encodeURI(params.error_description)
                : 'no error description'
            }`
          );
        }
      }
    } else {
      setQueryParams(
        `?error=url_not_found&error_description=${encodeURI(
          'url params not found'
        )}`
      );
    }

    setLoading(false);
  }, [search]);

  if (loading) {
    return (
      <Box
        data-test-id="apploading"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100vh"
        style={{ backgroundColor: 'white' }}
      >
        <Box marginBottom={2}>
          <CircularProgress />
        </Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  const targetURL = `/${callbackPage || 'restaurants'}${
    queryParams || '?error=0'
  }`;

  return <Redirect to={targetURL} />;
}

export default RedirectRestaurant;
