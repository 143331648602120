import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  getAllFiles,
  getConfigFiles,
  deleteImage,
  uploadFile,
} from '../services/UploadService';

const GalleryContext = createContext();
export const useGallery = () => useContext(GalleryContext);

export function GalleryProvider({ children }) {
  const contextProps = {
    getAllFiles,
    deleteFile: deleteImage,
    uploadFile,
    getConfigFiles,
  };

  return (
    <GalleryContext.Provider value={contextProps}>
      {children}
    </GalleryContext.Provider>
  );
}

GalleryProvider.propTypes = {
  children: PropTypes.node,
};
