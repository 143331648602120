import { api } from './Api';
import ownerStore from '../utils/ownerStore';

export const getPublicConfig = async () => {
  const response = await api.get('/stripe/config');
  return response;
};

export const connectAccount = async ({ data, restaurantId }) => {
  if (restaurantId) {
    const authInfo = await ownerStore.get(`user-${restaurantId}`);
    await ownerStore.set('user', authInfo);
  }
  await api.post('/stripe/account/connect', {
    data,
  });
};
