import { darken, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  option: {
    border: '1px solid #D5D5D5',
    height: '33px',
    padding: '8px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    background: '#fff',

    '&:hover': {
      background: darken('#fff', 0.03),
    },
  },
  selected: {
    background: 'rgba(0, 122, 255, 0.1)',
    border: '1px solid #007AFF',
    color: '#007AFF',
    '&:hover': {
      background: 'rgba(0, 122, 255, 0.25)',
    },
  },
  disabled: {
    cursor: 'default',
    color: '#D5D5D5',
    border: '1px solid #D5D5D566',

    backgroundColor: '#fff',
    '&:hover': {
      background: '#fff',
    },
  },
}));
