import { makeStyles, darken } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '480px',
  },
  ml8: { marginLeft: '8px' },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
    fontSize: '12px',
  },
  formTextFieldValue: {
    marginBottom: 24,
    background: '#FAFAFA',
    borderRadius: '2px',
    fontSize: '16px',
    height: '48px',

    display: 'flex',
    alignItems: 'center',

    padding: '14px 16px',
  },
  actionButton: {
    background: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '4px',
    padding: '6px 12px',
    alignSelf: 'end',
    minWidth: '122px',
    width: '100%',
    fontWeight: 600,
    fontSize: '16px',

    '&:hover': {
      background: darken(theme.palette.primary.main, 0.25),
    },

    [theme.breakpoints.down(735)]: {
      minWidth: 'unset',
      width: '50px',
      height: '50px',
      position: 'fixed',
      bottom: '30px',
      right: '30px',
      borderRadius: '30%',
      zIndex: '400',
      '& > span': {
        display: 'none',
      },
    },
  },
}));
