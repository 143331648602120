import { makeStyles, lighten } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    width: '100%',
    borderRadius: '4px',
  },
  errorCard: {
    backgroundColor: lighten('#E84626', 0.9),
    border: '1px solid #E84626',
  },
  successCard: {
    backgroundColor: lighten('#66BB6B', 0.9),
    border: '1px solid #66BB6B',
  },
  warningCard: {
    backgroundColor: lighten('#E0B92C', 0.9),
    border: '1px solid #E0B92C',
  },
  errorIcon: {
    color: '#E84626',
    marginRight: '12px',
  },
  successIcon: {
    color: '#66BB6B',
    marginRight: '12px',
  },
  warningIcon: {
    color: '#E0B92C',
    marginRight: '12px',
  },
  typography: {
    fontWeight: 'bold',
    color: '#282E3E',
  },
  actionRoot: {
    padding: '0px 0px 0px 16px',
    justifyContent: 'space-between',
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icons: {
    marginLeft: 'auto',
    color: '#666666',
  },
}));
