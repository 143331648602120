import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from '../AddEditCouponSections.styles';

function BasicInfo({
  form: { getValues, setValue, control, getErrors },
  updateCouponConfig,
}) {
  const classes = useStyles();
  const { allBusinesses, couponsConfig } = useSelector(({ user, coupon }) => ({
    allBusinesses: user.allBusinesses,
    couponsConfig: coupon.config,
  }));

  const handleGenerateCode = () => {
    setValue('code', Math.random().toString(36).substr(2, 8).toUpperCase());
  };

  const ownerTypeLabels = {
    restaurant: 'Restaurant',
    provider: 'Eatzy',
    business: 'Local Delivery',
  };

  const getBusinessName = useCallback(
    (id) => {
      if (!allBusinesses?.length) return null;
      return allBusinesses?.filter((ev) => ev?.id === id)?.[0]?.name;
    },
    [allBusinesses]
  );

  useEffect(() => {
    if (couponsConfig) setValue('business', couponsConfig.business);
  }, [couponsConfig, setValue]);

  return (
    <Box className={clsx(classes.sectionContent, classes.flexRow)}>
      <Box className={classes.mr24}>
        <Typography variant="h6" className={classes.fieldLabel}>
          Coupon name
        </Typography>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              placeholder="Type coupon name"
              className={classes.defaultInput}
              {...getErrors('name')}
            />
          )}
        />
      </Box>

      <Box className={classes.mr24}>
        <Typography variant="h6" className={classes.fieldLabel}>
          Coupon code
        </Typography>
        <Controller
          control={control}
          name="code"
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              placeholder="SUNDAYFUNDAY"
              className={classes.defaultInput}
              {...getErrors('code')}
            />
          )}
        />
        <Typography
          data-test-id="random-code"
          className={classes.generateCodeBtn}
          onClick={handleGenerateCode}
        >
          Generate random code
        </Typography>
      </Box>

      <Box className={classes.mr24}>
        <Typography variant="h6" className={classes.fieldLabel}>
          Coupon owner
        </Typography>
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              value={getValues('owner')}
              variant="outlined"
              {...getErrors('owner')}
              className={clsx(classes.defaultInput, {
                [classes.placeholder]: getValues('owner') === '',
              })}
            >
              <MenuItem value="restaurant">
                {ownerTypeLabels.restaurant}
              </MenuItem>
              <MenuItem value="provider">{ownerTypeLabels.provider}</MenuItem>
              <MenuItem value="business">{ownerTypeLabels.business}</MenuItem>
            </Select>
          )}
          name="owner"
          control={control}
        />
      </Box>

      <Box>
        <Typography variant="h6" className={classes.fieldLabel}>
          Business ID
        </Typography>
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              value={getValues('business')}
              variant="outlined"
              {...getErrors('business')}
              className={clsx(classes.defaultInput, {
                [classes.placeholder]: getValues('business') === '',
              })}
              displayEmpty
              renderValue={(selected) => {
                if (!selected || selected?.length === 0) {
                  return <em>Select business ID</em>;
                }
                return getBusinessName(selected);
              }}
              onChange={(e) => {
                setValue('business', e.target.value);
                if (e.target.value) {
                  updateCouponConfig(e.target.value);
                }
              }}
            >
              <MenuItem disabled value="">
                <em>Select business ID</em>
              </MenuItem>
              {allBusinesses &&
                allBusinesses?.map((ev) => (
                  <MenuItem value={ev?.id} key={ev?.id}>
                    {ev?.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          name="business"
          control={control}
        />
      </Box>
    </Box>
  );
}

BasicInfo.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
  ),
  updateCouponConfig: PropTypes.func,
};

export default BasicInfo;
