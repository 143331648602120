import { getConfig } from '../config';
import { OAuthentication } from '../lib/Authentication';
import {
  deauthorizeAccount,
  fetchRefreshToken,
  getMe,
  postLogin,
  registerAccount,
} from '../services/AccountService';
import ownerStore from './ownerStore';

export const navigateToLogin = () => {};

const defaultProps = {
  loginRequest: postLogin,
  registerRequest: registerAccount,
  getProfileRequest: () => getMe('owner'),
  refreshTokenRequest: fetchRefreshToken,
  deauthorizeRequest: deauthorizeAccount,
  navigateToLogin,
  roleType: getConfig('AUTH_ROLE_TYPE'),
  rolesAllowed: getConfig('AUTH_ROLES_ALLOWED'),
};

export const ownerAuth = new OAuthentication({
  storage: ownerStore,
  ...defaultProps,
});

export default ownerAuth;
