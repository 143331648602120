import { isEqual } from 'lodash';

const sliderImg = [
  'first_slide',
  'second_slide',
  'third_slide',
  'fourth_slide',
  'fifth_slide',
  'sixth_slide',
];
const sliderTitle = [
  'slider_01_title',
  'slider_02_title',
  'slider_03_title',
  'slider_04_title',
  'slider_05_title',
  'slider_06_title',
];
const sliderSubtitle = [
  'slider_01_sub',
  'slider_02_sub',
  'slider_03_sub',
  'slider_04_sub',
  'slider_05_sub',
  'slider_06_sub',
];

const weekToString = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const statusLabel = {
  draft: 'Test Mode',
  published: 'Active',
  unpublished: 'Inactive',
};

const toShift = (shift) => {
  const formattedShift = [];
  shift.map((s) => {
    formattedShift.push({
      open: s.start,
      close: s.end,
    });
    return null;
  });
  return formattedShift;
};

export const hourToObj = (payload, business) => {
  const workingHours = {};
  weekToString.map((day) => {
    const shift = payload.filter((s) => weekToString[s.day_in_week] === day);
    if (shift.length > 0) {
      workingHours[day] = { open: true, shift: toShift(shift) };
    } else {
      workingHours[day] = { open: false };
    }
    return null;
  });
  if (business)
    if (isEqual(workingHours, business)) {
      workingHours.same = true;
    } else {
      workingHours.same = false;
    }

  return workingHours;
};

const workingHoursToObject = (payload) => {
  const obj = { business: {}, delivery: {}, takeout: {} };
  obj.business = hourToObj(payload.business_hours);
  obj.delivery = hourToObj(payload.delivery_hours, obj.business);
  obj.takeout = hourToObj(payload.takeout_hours, obj.business);
  return obj;
};

const textToMeta = (text) => {
  const meta = { additional: [] };

  text.map((t) => {
    if (['description', 'keywords', 'title'].includes(t.name))
      meta[t.name] = t.text;
    else if (t.name.split('_')[0] === 'field') {
      if (t.name.split('_')[1] === 'title')
        meta.additional.push({
          field: text.filter(
            (f) => f.name === `field_title_${t.name.split('_')[2]}`
          )[0]?.text,
          info: text.filter(
            (i) => i.name === `field_text_${t.name.split('_')[2]}`
          )[0]?.text,
        });
    }
    return null;
  });

  return meta;
};

export const mediaToObject = (media, text) => {
  const obj = {
    logo: [],
    favicon: [],
    consumer_restaurant_list_logo: [],
    consumer_restaurant_map_logo: [],
    consumer_restaurant_page_logo: [],
    consumer_restaurant_page_background: [],
    consumer_restaurant_checkout_logo: [],
    consumer_restaurant_stripe_logo: [],
    consumer_restaurant_order_success_logo: [],
    consumer_restaurant_tracking_item_logo: [],
    consumer_restaurant_tracking_details_logo: [],
    consumer_restaurant_history_item_logo: [],
    consumer_restaurant_email_logo: [],
  };

  media.map((m) => {
    if (Object.keys(obj).includes(m.name))
      obj[m.name] = [{ id: m.upload_id, url: `${m.url}?width=200&height=200` }];
    return null;
  });

  obj.menu = [];
  obj.slider = [];

  sliderImg.map((title, i) => {
    const img = media.filter((m) => m.name === title);
    const imgTitle = text.filter((m) => m.name === sliderTitle[i]);
    const subtitle = text.filter((m) => m.name === sliderSubtitle[i]);
    if (img.length > 0 || imgTitle.length > 0 || subtitle.length > 0) {
      obj.slider.push({
        sliderTitle: imgTitle[0]?.text || '',
        sliderSubtitle: subtitle[0]?.text || '',
        image: [
          {
            id: img[0]?.upload_id || '',
            url: img[0]?.url ? `${img[0].url}?width=200&height=200` : '',
          },
        ],
      });
    } else if (obj.slider.length < 3) {
      obj.slider.push({
        sliderTitle: '',
        sliderSubtitle: '',
        image: [],
      });
    }
    return null;
  });

  return obj;
};

const formatRestaurant = (data) => {
  const {
    id,
    name,
    address,
    category,
    tags,
    schedule,
    text,
    phone,
    media,
    description,
    domain,
    appuser,
    owner,
    status,
    ready,
    colors,
  } = data;
  const site = domain
    ? { haveSite: true, site: domain }
    : { haveSite: false, site: '' };

  const res = {
    id,
    name,
    address: {
      ...address,
      formattedAddress: `${address?.street}, ${address?.city}, ${address?.state} ${address?.zip}`,
      location: {
        lat: address.location.latitude,
        lng: address.location.longitude,
      },
      latlng: {
        lat: address.location.latitude,
        lng: address.location.longitude,
      },
    },
    description,
    cuisine: category,
    tags,
    rawTags: '',
    ...workingHoursToObject(schedule),
    meta: textToMeta(text),
    phone,
    appuser: appuser?.id,
    owner,
    ...mediaToObject(media, text),
    ...site,
    status: statusLabel[status],
    publishStatus: status,
    ready,
    colors,
  };
  return res;
};

const formatRestaurantsList = (data) => {
  const newArray = data.map((restaurant) => ({
    ...restaurant,
    status: statusLabel[restaurant.status],
    publishStatus: restaurant.status,
    ready: restaurant.ready,
  }));

  return newArray;
};

const formatCouponsConfig = ({ businesses }) => {
  const brandsList = {};
  const restaurantsList = {};

  businesses?.forEach(({ brands, restaurants }) => {
    brands?.forEach((brand) => {
      brandsList[brand.id] = brand;
    });

    restaurants?.forEach((restaurant) => {
      restaurantsList[restaurant.id] = restaurant;
    });
  });

  return {
    businesses,
    brands: Object.values(brandsList),
    restaurants: Object.values(restaurantsList),
  };
};

export const formatUsersList = (data) =>
  data.map(({ user, role, id }) => ({
    permission: role.type,
    id,
    userId: user.id,
    active: user.active,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
  }));

export const formatResponse = (data, type) => {
  const cb = {
    restaurant: formatRestaurant,
    restaurants: formatRestaurantsList,
    couponsConfig: formatCouponsConfig,
    users: formatUsersList,
  }[type];

  return cb(data);
};
