import fetchIntercept from 'fetch-intercept';
import { matchPath } from 'react-router-dom';
import _ from 'lodash';
import Environment from '@eatzy/common-reactjs/Environment';
import { EXPIRED_TOKEN } from '../../constants/auth';
import { UNAUTHORIZED, FORBIDDEN, NOT_FOUND } from '../../constants/http';
import { logoutRequest } from '../../redux/ducks/UserDucks';
import Authentication from '../../utils/authentication';
import OwnerAuthentication from '../../utils/ownerAuthentication';
import { api } from '../Api';
import { getConfig } from '../../config';

export const authorizationTokenRoutes = [
  { method: 'post', endpoint: '/account/authorize' },
  { method: 'post', endpoint: '/account/refresh' },
  { method: 'post', endpoint: '/account/deauthorize' },
];

export const ownerTokenRoutes = [
  { method: 'post', endpoint: '/stripe/account/connect' },
];

export const checkTypeRoutes = [
  { method: 'get', endpoint: '/account/profile' },
];

const matchesRoute = ({ url, method }, endpoints) => {
  const matchesMethod = endpoints.some(
    (route) => route.method.toLowerCase() === method.toLowerCase()
  );

  const matchesURL = endpoints.some(
    (route) =>
      matchPath(url.pathname, {
        path: route.endpoint,
        exact: true,
        strict: false,
      })
    // eslint-disable-next-line function-paren-newline
  );

  return !!(matchesMethod && matchesURL);
};

export const insertBasicHeaders = async (url, config) => {
  config.headers = {
    ...config.headers,
    'X-App-Version': process.env.REACT_APP_VERSION,
    'X-App-Source': 'lam-web',
    'X-App-OS':
      window?.navigator?.platform ||
      window?.navigator?.userAgentData?.platform ||
      'unknown',
  };

  return [url, config];
};

export const insertUseRandomFakeData = async (url, config) => {
  const useFakeData = Environment.getEnv('API_FAKE_MODE');

  if (useFakeData === 'true' && config?.headers) {
    config.headers = {
      ...config.headers,
      'X-API-FakeData': 'random',
    };
  }

  return [url, config];
};

export const insertAuthorizationTokenInRequest = async (url, config) => {
  if (config.hasRefreshToken) {
    config.headers = {
      ...config.headers,
      authorization: `Basic ${config.refreshToken}`,
    };
  }

  return [url, config];
};

export const insertAccessTokenInRequest = async (url, config, store) => {
  const authToken = await Authentication.getToken();

  if (!authToken || authToken?.isExpired) {
    if (store) store.dispatch(logoutRequest(EXPIRED_TOKEN));
    return;
  }

  config.headers = {
    ...config.headers,
    authorization: `Bearer ${authToken?.token}`,
  };

  return [url, config];
};

export const insertRestaurantOwnerTokenInRequest = async (url, config) => {
  const authToken = await OwnerAuthentication.getToken();

  if (!authToken) {
    return;
  }

  config.headers = {
    ...config.headers,
    authorization: `Bearer ${authToken?.token}`,
  };

  return [url, config];
};

export const originalRequest = {};

export const interceptors = (store) => ({
  async request(url, config) {
    originalRequest.url = url;
    originalRequest.config = config;

    // Modify the url or config here
    const refreshRelated = matchesRoute(
      { url, method: config.method },
      authorizationTokenRoutes
    );

    insertBasicHeaders(url, config);

    const restaurantOwnerRelated =
      matchesRoute({ url, method: config.method }, ownerTokenRoutes) ||
      (matchesRoute({ url, method: config.method }, checkTypeRoutes) &&
        config.type === 'owner');

    const restaurantIdRequest = matchesRoute({ url, method: config.method }, [
      { method: 'get', endpoint: '/localareamng/business/all' },
    ]);

    !(restaurantOwnerRelated || restaurantIdRequest) &&
      insertUseRandomFakeData(url, config);

    originalRequest.refreshRelated = refreshRelated || config?.hasRefreshToken;
    originalRequest.restaurantOwnerRelated = !!restaurantOwnerRelated;

    if (restaurantOwnerRelated) {
      return await insertRestaurantOwnerTokenInRequest(url, config);
    }
    if (refreshRelated || config?.hasRefreshToken) {
      return await insertAuthorizationTokenInRequest(url, config, store);
    }
    return await insertAccessTokenInRequest(url, config, store);
  },

  requestError(error) {
    // Called when an error occurred during another 'request' interceptor call
    return Promise.reject(error);
  },

  async response(response) {
    // Modify the response object
    const data = await response.json();
    return new Promise(async (resolve, reject) => {
      if (response.status === UNAUTHORIZED) {
        reject({ message: 'Unauthorized', status: UNAUTHORIZED });
      }
      if (response.status === FORBIDDEN) {
        reject({ message: data.message, status: FORBIDDEN });
      }
      if (
        response.status === NOT_FOUND &&
        data.message === getConfig('TOKEN_ERROR_MESSAGE')
      ) {
        const { url, config, restaurantOwnerRelated, refreshRelated } =
          originalRequest;

        let req;

        if (restaurantOwnerRelated) {
          req = await insertRestaurantOwnerTokenInRequest(url, config).catch(
            (err) => {
              reject(err);
            }
          );
        } else if (refreshRelated) {
          reject(data);
        } else {
          req = await insertAccessTokenInRequest(url, config, store).catch(
            (err) => {
              reject(err);
            }
          );
        }

        if (!_.isNil(req) && !_.isEmpty(req))
          resolve(
            await api.improvedFetch(
              req[0].pathname + req[0].search,
              {
                ...req[1],
                body: req[1]?.body ? JSON.parse(req[1].body) : undefined,
              },
              1
            )
          );
      }
      if (response.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  },

  responseError(error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});

export default (store) => fetchIntercept.register(interceptors(store));
