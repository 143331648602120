import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Card, CardContent } from '@material-ui/core';
import { forgotPasswordRequest } from '../../../redux/ducks/UserDucks';

import { ForgotPasswordForm } from '../../input/ForgotPasswordForm/ForgotPasswordForm';
import { Footer } from '../../layout/Footer';
import { useStyles } from './ForgotPasswordPage.styles';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';

export function ForgotPasswordPage() {
  const isDesktop = useIsDesktop();
  const classes = useStyles({ isDesktop });
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.user.isLoading);
  const resetRequested = useSelector((state) => state.user.resetRequested);

  const onSubmit = (data) => {
    dispatch(forgotPasswordRequest(data));
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="xs"
        className={classes.rootcontainer}
      >
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item lg>
                    <ForgotPasswordForm
                      onSubmit={onSubmit}
                      isLoading={isLoading}
                      resetRequested={resetRequested}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </>
  );
}
