import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 256,
    borderTopRightRadius: 16,
    borderTop: '1px solid #d5d5d4',
    borderRight: '1px solid #d5d5d4',
    background: '#fff',
    padding: '32px 24px 83px 0',
    position: 'fixed',
    height: 'calc(100% - 80px)',
    bottom: '0',
    left: '0',
  },

  mobile: {
    position: 'unset',
    border: 'none',
    padding: 0,
    paddingRight: 16,
    paddingBottom: 48,
    minHeight: '0',
  },

  listItem: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    padding: '12px 16px',
    background: 'rgba(0, 0, 0, 0.02)',
    marginBottom: 6,

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  },
  listItemIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fill: '#000',
    marginRight: 8,
  },
  textMenu: {
    color: '#000',
    fontWeight: 600,
  },
  active: {
    background: 'rgba(67, 87, 239, 0.05)',

    '& > div > svg': {
      fill: '#4357EF',
    },

    '& > div > p': {
      color: '#4357EF',
    },

    '&:hover': {
      background: 'rgba(67, 87, 239, 0.05)',
    },
  },
  addButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,

    '&:hover': {
      background: 'rgba(67, 87, 239, 0.03)',
    },
  },
  buttonIcon: {
    fill: '#4357EF',
    marginRight: 8,
  },
  buttonText: {
    color: '#4357EF',
    fontWeight: 600,
  },
  activeAdd: {
    background: 'rgba(67, 87, 239, 0.05)',
  },
});
