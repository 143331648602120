import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },

  formInputBox: {
    marginBottom: 24,

    '& fieldset': {
      borderRadius: 2,
    },

    '& input, textarea': {
      padding: '14px 16px',
      fontSize: '16px',
    },

    '& .Mui-disabled': {
      fontSize: '16px',
      color: '#000000',
      borderRadius: '2px',
      border: '1px solid #FAFAFA',
    },

    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  lowerCase: {
    '& > div > input': {
      textTransform: 'lowercase',
    },
  },
}));
