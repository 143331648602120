import _ from 'lodash';

export const numberValidation = (value, { min, max }) => {
  if (isNaN(parseInt(value, 10))) return '';

  if (!_.isNil(min)) {
    if (min >= value) return min;
  }

  if (!_.isNil(max)) {
    if (max <= value) return max;
  }

  return value;
};
