import { formatWorkingHours } from '../FormatUtils';

const sliderImg = [
  'first_slide',
  'second_slide',
  'third_slide',
  'fourth_slide',
  'fifth_slide',
  'sixth_slide',
];

const sliderTitle = (payload) => {
  const array = payload?.slider.map((slide, i) => {
    if (slide?.sliderTitle)
      return {
        name: `slider_${`0${i + 1}`.slice(-2)}_title`,
        locale: 'en',
        text: slide.sliderTitle,
      };
    return undefined;
  });

  return array.filter((d) => d !== undefined);
};

const subtitles = (payload) => {
  const array = payload?.slider.map((slide, i) => {
    if (slide.sliderSubtitle)
      return {
        name: `slider_${`0${i + 1}`.slice(-2)}_sub`,
        locale: 'en',
        text: slide.sliderSubtitle,
      };
    return undefined;
  });

  return array.filter((d) => d !== undefined);
};

const footerField = (payload) => {
  const array = payload.meta?.additional.map((info, i) => ({
    name: `field_title_${`0${i + 1}`.slice(-2)}`,
    locale: 'en',
    text: info.field,
  }));

  return array;
};

const footerInfo = (payload) => {
  const array = payload.meta?.additional.map((info, i) => ({
    name: `field_text_${`0${i + 1}`.slice(-2)}`,
    locale: 'en',
    text: info.info,
  }));

  return array;
};

export const metaToText = (payload) => {
  const array = [];
  if (payload.meta?.title)
    array.push({
      name: 'title',
      locale: 'en',
      text: payload.meta.title,
    });
  if (payload.meta?.description)
    array.push({
      name: 'description',
      locale: 'en',
      text: payload.meta.description,
    });
  if (payload.meta?.keywords)
    array.push({
      name: 'keywords',
      locale: 'en',
      text: payload.meta.keywords,
    });
  if (payload?.slider) {
    array.push(...sliderTitle(payload));
    array.push(...subtitles(payload));
  }
  if (payload?.meta.additional) {
    array.push(...footerField(payload));
    array.push(...footerInfo(payload));
  }

  return array;
};

export const haveSite = (payload) => {
  if (payload.haveSite) {
    return { domain: payload.site };
  }
  return null;
};

export const AddRestaurantETL = async (payload, tokens) => {
  const businessHours = formatWorkingHours(payload.business);

  const { same: deliveryIsSame, ...expectedDeliveryHours } = payload.delivery;
  const { same: takeoutIsSame, ...expectedTakeoutHours } = payload.takeout;

  const deliveryHours = formatWorkingHours(
    deliveryIsSame ? payload.business : expectedDeliveryHours
  );
  const takeoutHours = formatWorkingHours(
    takeoutIsSame ? payload.business : expectedTakeoutHours
  );
  const address = {
    street: payload.address.street,
    city: payload.address.city,
    state: payload.address.state,
    zip: payload.address.zip,
    location: {
      latitude: payload.address.location.lat,
      longitude: payload.address.location.lng,
    },
  };

  const media = () => {
    const imageNames = [
      'logo',
      'favicon',
      'consumer_restaurant_list_logo',
      'consumer_restaurant_map_logo',
      'consumer_restaurant_page_logo',
      'consumer_restaurant_page_background',
      'consumer_restaurant_checkout_logo',
      'consumer_restaurant_stripe_logo',
      'consumer_restaurant_order_success_logo',
      'consumer_restaurant_tracking_item_logo',
      'consumer_restaurant_tracking_details_logo',
      'consumer_restaurant_history_item_logo',
      'consumer_restaurant_email_logo',
    ];

    const array = [];

    const includeImage = (imageName) => {
      if (payload[imageName]?.length > 0)
        array.push({
          name: imageName,
          upload_id: payload[imageName][0].id,
        });
    };

    imageNames.forEach(includeImage);

    if (payload?.menu.length > 0)
      array.push(
        ...payload.menu.map((img, i) => ({
          name: `uploaded_menu_${`0${i + 1}`.slice(-2)}`,
          upload_id: img.id,
        }))
      );
    array.push(
      ...payload.slider.map((slide, i) => {
        if (slide?.image?.length > 0 && slide?.image[0]?.id !== '') {
          return {
            name: sliderImg[i],
            upload_id: slide?.image[0]?.id,
          };
        }
        return undefined;
      })
    );

    return array?.filter((e) => e?.upload_id !== undefined);
  };

  const token = () => {
    if (tokens) {
      const tk = { owner: tokens.owner.token };
      return tk;
    }
  };

  const data = {
    schedule: {
      business_hours: businessHours,
      delivery_hours: deliveryHours,
      takeout_hours: takeoutHours,
    },
    business: payload.businessId,
    text: metaToText(payload),
    name: payload.name,
    address,
    phone: payload.phone,
    media: media(),
    category: payload.cuisine,
    tags: payload.tags,
    colors: payload.colors,
    ...token(),
    description: payload.description,
    ...haveSite(payload),
  };

  if (payload?.fees) {
    data.fees = {
      adjustments: {
        delivery: payload.fees.adjustments.delivery / 100,
        takeout: payload.fees.adjustments.takeout / 100,
      },
      markups: {
        delivery: payload.fees.markups.delivery / 100,
        takeout: payload.fees.markups.takeout / 100,
      },
    };
  }

  return payload.menu_url ? { ...data, menu_url: payload.menu_url } : data;
};
