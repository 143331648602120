import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { capitalize } from 'lodash';
import { useStyles } from './Steps.styles';

function maxValueFormat(val, max) {
  const { length } = String(max);
  if (Number(val.substring(0, length)) > max) {
    return val.substring(0, length - 1);
  }
  return val.substring(0, length);
}

function NumberFormatCustom({ inputRef, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format={(val) => maxValueFormat(val, 100)}
      placeholder="00"
      type="tel"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.any,
};

function PricePercent({ type, fee, feeError, markupError, control }) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginBottom="24px"
      alignItems="center"
    >
      <Box>
        <Typography variant="h4" classes={{ h4: classes.rowHeader }}>
          {capitalize(type)}
        </Typography>
        <Typography variant="h6" classes={{ h6: classes.rowHeaderFee }}>
          {`(${fee}% fee)`}
        </Typography>
      </Box>
      <Box className={classes.columnWrapper}>
        <Box
          className={clsx(classes.inputWrapper, {
            [classes.inputWrapperError]: feeError,
          })}
        >
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                fullWidth
                className={classes.tableInputBox}
                variant="outlined"
              />
            )}
            name={`fees.adjustments.${type}`}
            control={control}
          />
          <Box className={classes.percentage}>%</Box>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: 8 }}>+</strong>
          <Box
            className={clsx(classes.inputWrapper, {
              [classes.inputWrapperError]: markupError,
            })}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  className={classes.tableInputBox}
                  variant="outlined"
                />
              )}
              name={`fees.markups.${type}`}
              control={control}
            />
            <Box className={classes.percentage}>%</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PricePercent.propTypes = {
  type: PropTypes.string,
  fee: PropTypes.number,
  control: PropTypes.object,
  feeError: PropTypes.bool,
  markupError: PropTypes.bool,
};

export function PriceSetup({ form: { getErrors, watch, control } }) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Price Level Setup
      </Typography>
      <Typography variant="body1" className={classes.description}>
        We are charging fees based on the type of order. Choose if customers
        should cover those fees by adding some of it to your baseline prices.
      </Typography>
      <Box>
        <Box className={classes.table}>
          <Box display="flex" justifyContent="flex-end" marginBottom="24px">
            <Box className={classes.columnHeaderWrapper}>
              <Typography variant="h4" className={classes.columnHeader}>
                What restaurant gets
              </Typography>
              <Typography variant="h4" className={classes.columnHeader}>
                Markup price
              </Typography>
            </Box>
          </Box>
          <PricePercent
            type="delivery"
            fee={20}
            markup={0}
            control={control}
            feeError={getErrors('fees.adjustments.delivery').error}
            markupError={getErrors('fees.markups.delivery').error}
          />
          <PricePercent
            type="takeout"
            fee={5}
            markup={0}
            control={control}
            feeError={getErrors('fees.adjustments.takeout').error}
            markupError={getErrors('fees.markups.takeout').error}
          />
        </Box>
        <Typography variant="h4" className={classes.descriptionTitle}>
          Example on a $10 item:
        </Typography>
        <Typography variant="h6" classes={{ h6: classes.descriptionText }}>
          Your customer will pay
          <strong>
            {' '}
            ${10 + (10 * Number(watch('fees.markups.delivery'))) / 100}
          </strong>{' '}
          for Delivery,{' '}
          <strong>
            ${10 + (10 * Number(watch('fees.markups.takeout'))) / 100}{' '}
          </strong>
          for Takeout.
        </Typography>
        <Typography variant="h6" classes={{ h6: classes.descriptionText }}>
          You will receive
          <strong>
            {' '}
            ${(10 * Number(watch('fees.adjustments.delivery'))) / 100}
          </strong>{' '}
          for Delivery,{' '}
          <strong>
            ${(10 * Number(watch('fees.adjustments.takeout'))) / 100}{' '}
          </strong>
          for Takeout.
        </Typography>
      </Box>
    </>
  );
}

PriceSetup.propTypes = {
  form: PropTypes.object,
};

export default PriceSetup;
