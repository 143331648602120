import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Cancel } from '@material-ui/icons';
import { Box, IconButton, Typography } from '@material-ui/core';
import AutocompleteField from '../../AutocompleteField';
import { CheckOptions } from '../../CheckOptions';
import { useStyles } from '../AddEditCouponSections.styles';

function RestaurantConditions({
  form: { getValues, setValue },
  brands,
  restaurants,
}) {
  const brandsEnabled = false;
  const classes = useStyles();
  const [selectedRestaurants, setSelectedRestaurants] = useState(
    getValues('specificRestaurants') || []
  );
  const [selectedBrands, setSelectedBrands] = useState(
    getValues('specificBrands') || []
  );

  useEffect(() => setValue('specificBrands', selectedBrands), [selectedBrands]);
  useEffect(
    () => setValue('specificRestaurants', selectedRestaurants),
    [selectedRestaurants]
  );

  const handleAddTag = (tag, selectedTags, setSelectedTags) => {
    const alreadyAdded = selectedTags.some(
      (selected) => selected.id === tag.id
    );
    if (!alreadyAdded) setSelectedTags([...selectedTags, tag]);
  };

  const handleAddRestaurantMenu = (restaurant) => {
    console.info('Add Restaurant', restaurant);
    // dispatch(getRestaurantMenuRequest({ restaurantId: restaurant.id }));
  };

  const handleRemoveRestaurantMenu = (restaurant) => {
    console.info('Remove Restaurant', restaurant);
    // dispatch(removeRestaurantMenu({ restaurantId: restaurant.id }));
  };

  const handleRemoveTag = (tag, selectedTags, setSelectedTags) => {
    setSelectedTags(selectedTags.filter((selected) => selected.id !== tag.id));
  };

  return (
    <Box className={classes.sectionContent}>
      <Box className={classes.mb24}>
        <CheckOptions
          onChange={(value) => setValue('restaurantConditions', value)}
          defaultValue={['All Restaurants']}
          isRadio
          options={
            brandsEnabled
              ? ['All Restaurants', 'Specific Restaurants', 'Specific Brands']
              : ['All Restaurants', 'Specific Restaurants']
          }
        />
      </Box>
      <Box style={{ width: '410px' }}>
        {getValues('restaurantConditions') === 'Specific Restaurants' && (
          <AutocompleteField
            data-test-id="3-29P3xLolbk9oVOiXwKh"
            options={restaurants}
            key={JSON.stringify(selectedRestaurants)}
            onClick={(value) => {
              handleAddTag(value, selectedRestaurants, setSelectedRestaurants);
              handleAddRestaurantMenu(value);
            }}
            placeholder="Find a restaurant"
            getOptionLabel={(option) => option?.name}
          />
        )}
        {getValues('restaurantConditions') === 'Specific Brands' && (
          <AutocompleteField
            data-test-id="G81ayEiqb3sUVRbWf7gMr"
            options={brands}
            key={JSON.stringify(selectedBrands)}
            onClick={(value) =>
              handleAddTag(value, selectedBrands, setSelectedBrands)
            }
            placeholder="Find a brand"
            getOptionLabel={(option) => option?.name}
          />
        )}
      </Box>
      <Box display="flex" marginTop="14px" className={classes.tagsContainer}>
        {getValues('restaurantConditions') === 'Specific Restaurants' &&
          selectedRestaurants.map((restaurant, i) => (
            <Box
              data-test-id={`restaurant-${i}`}
              className={classes.tags}
              key={restaurant.id}
            >
              <Typography>{restaurant.name}</Typography>

              <IconButton
                data-test-id={`delete-restaurant-${i}`}
                style={{ padding: 0, marginLeft: '8px' }}
                onClick={() => {
                  handleRemoveTag(
                    restaurant,
                    selectedRestaurants,
                    setSelectedRestaurants
                  );
                  handleRemoveRestaurantMenu(restaurant);
                }}
              >
                <Cancel style={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          ))}
        {getValues('restaurantConditions') === 'Specific Brands' &&
          selectedBrands.map((brand, i) => (
            <Box
              data-test-id={`brand-${i}`}
              className={classes.tags}
              key={brand.id}
            >
              <Typography>{brand.name}</Typography>

              <IconButton
                data-test-id={`delete-brand-${i}`}
                style={{ padding: 0, marginLeft: '8px' }}
                onClick={() =>
                  handleRemoveTag(brand, selectedBrands, setSelectedBrands)
                }
              >
                <Cancel style={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

RestaurantConditions.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
  ).isRequired,
  restaurants: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
  ),
  brands: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
  ),
};

RestaurantConditions.defaultProps = {
  restaurants: [],
  brands: [],
};

export default RestaurantConditions;
