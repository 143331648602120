import { takeEvery, call, put } from 'redux-saga/effects';
import {
  searchUserRequest,
  searchUserSuccess,
  listUsersRequest,
  listUsersSuccess,
  registerUserRequest,
  deleteUserRequest,
  updateUserRoleRequest,
  toggleModal,
  goToStep,
  error,
} from '../../ducks/AppUserDucks';
import {
  searchUser,
  listUsers,
  addUser,
  updateUser,
  deleteUser,
} from '../../../services/AppUserService';
import { registerAccount } from '../../../services/AccountService';
import { enqueueSnackbar } from '../../ducks/SnackbarDucks';

function* addNewUser(action) {
  let user = {};

  try {
    const { email } = action.payload;
    user = yield call(searchUser, { email });
  } catch (err) {
    const { email } = action.payload;
    yield put(searchUserSuccess({ user: { email } }));
    yield put(goToStep({ step: 'userNotFound' }));
  }

  if (!user?.id) return;

  try {
    const { restaurantId } = action.payload;

    yield call(addUser, {
      userId: user.id,
      restaurantId,
      role: 12,
    });

    yield put(listUsersRequest({ restaurantId }));

    yield put(
      searchUserSuccess({
        user: {
          ...user,
          userId: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
        },
      })
    );
    yield put(goToStep({ step: 'setPermission' }));
    yield put(
      enqueueSnackbar({
        message: `You added ${user.first_name} ${user.last_name} as Restaurant Operator`,
        options: {
          variant: 'success',
        },
      })
    );
  } catch (err) {
    yield put(error({ message: err.message }));
    yield put(
      enqueueSnackbar({
        message: err.message || 'Something went wrong, we can’t add user',
        options: {
          variant: 'error',
        },
      })
    );
  }
}

function* goToInitialStep(action) {
  const { open } = action.payload;
  if (!open) {
    yield put(goToStep({ step: 'search' }));
  }
}

function* getUsersList(action) {
  try {
    const { restaurantId } = action.payload;
    const usersList = yield call(listUsers, restaurantId);
    yield put(listUsersSuccess({ usersList }));
  } catch (err) {
    yield put(error({ message: err.message }));
    yield put(listUsersSuccess({ usersList: [] }));
  }
}

function* registerAndAddNewUser(action) {
  try {
    const { firstName, lastName, password, email, phone, restaurantId } =
      action.payload;

    yield call(registerAccount, {
      username: email,
      firstname: firstName,
      lastname: lastName,
      password,
      email,
      phone,
      source: 'web',
    });

    const user = yield call(searchUser, { email });

    yield call(addUser, {
      userId: user.id,
      restaurantId,
      role: 12,
    });

    yield put(listUsersRequest({ restaurantId }));

    yield put(
      searchUserSuccess({
        user: { ...user, userId: user.id, firstName, lastName },
      })
    );
    yield put(goToStep({ step: 'setPermission' }));
    yield put(
      enqueueSnackbar({
        message: `You added ${firstName} ${lastName} as Restaurant Operator`,
        options: {
          variant: 'success',
        },
      })
    );
  } catch (err) {
    yield put(error({ message: err.message }));
    yield put(
      enqueueSnackbar({
        message: 'Something went wrong, we can’t add user',
        options: {
          variant: 'error',
        },
      })
    );
  }
}

function* updateUserRole(action) {
  try {
    const { userId, restaurantId, roleId, firstName, lastName, permission } =
      action.payload;

    yield call(updateUser, { userId, restaurantId, roleId, role: permission });

    yield put(listUsersRequest({ restaurantId }));

    yield put(
      enqueueSnackbar({
        message: `${firstName} ${lastName} was updated`,
        options: {
          variant: 'success',
        },
      })
    );
  } catch (err) {
    yield put(error({ message: err.message }));
    yield put(
      enqueueSnackbar({
        message: 'Something went wrong, we can’t update user',
        options: {
          variant: 'error',
        },
      })
    );
  }
}

function* removeUser(action) {
  try {
    const { userId, restaurantId, firstName, lastName, permission } =
      action.payload;

    yield call(deleteUser, { userId, restaurantId, role: permission });

    yield put(listUsersRequest({ restaurantId }));

    yield put(
      enqueueSnackbar({
        message: `${firstName} ${lastName} was removed`,
        options: {
          variant: 'success',
        },
      })
    );
  } catch (err) {
    yield put(error({ message: err.message }));
    yield put(
      enqueueSnackbar({
        message: 'Something went wrong, we can’t remove user',
        options: {
          variant: 'error',
        },
      })
    );
  }
}

export default function* watchRestaurant() {
  yield takeEvery(searchUserRequest, addNewUser);
  yield takeEvery(listUsersRequest, getUsersList);
  yield takeEvery(toggleModal, goToInitialStep);
  yield takeEvery(registerUserRequest, registerAndAddNewUser);
  yield takeEvery(deleteUserRequest, removeUser);
  yield takeEvery(updateUserRoleRequest, updateUserRole);
}
