import React from 'react';
import { Router, Switch } from 'react-router-dom';
import {
  CircularProgress,
  Typography,
  CssBaseline,
  Box,
  Hidden,
} from '@material-ui/core';

import Route from '../../navigation/Routes';
import history from '../../../utils/history';
import { useStyles } from './Root.styles';
import { LoginPage } from '../../page/LoginPage';
import { ForgotPasswordPage } from '../../page/ForgotPasswordPage';
import { ResetPasswordPage } from '../../page/ResetPasswordPage';
import { HomePage } from '../../page/HomePage';
import { ReportsPage } from '../../page/ReportsPage';
import { RestaurantsPage } from '../../page/RestaurantsPage';
import { SettingsPage } from '../../page/SettingsPage';
import { NotFoundPage } from '../../page/NotFoundPage';
import { CouponsPage } from '../../page/CouponsPage';
import { AddRestaurantPage } from '../../page/AddRestaurantPage';
import useAppLoading from '../../../hooks/useAppLoading';
import { RestaurantMenuProvider } from '../../../context/RestaurantMenuContext';
import RedirectRestaurant from '../../navigation/RedirectRestaurant';

import { Header } from '../Header';
import { Menu } from '../Menu';

import Notifier from '../Notifier';
import { RestaurantMenu } from '../RestaurantMenu/RestaurantMenu';
import { RestaurantPage } from '../../page/RestaurantPage/RestaurantPage';

export function WithHeader() {
  return (
    <>
      <Route path="/reports" exact isPrivate component={ReportsPage} />
      <Route path="/restaurants" exact isPrivate component={RestaurantsPage} />
      <Route path="/settings" exact isPrivate component={SettingsPage} />
      <Route path="/coupons" exact isPrivate component={CouponsPage} />
      <RestaurantMenuProvider data-test-id="restaurant-menu">
        <Route path="/add" isPrivate component={AddRestaurantPage} />
      </RestaurantMenuProvider>
    </>
  );
}

export function RedirectRoutes() {
  return (
    <RestaurantMenuProvider data-test-id="restaurant-menu">
      <Route exact path="/stripeconnect" component={RedirectRestaurant} />
    </RestaurantMenuProvider>
  );
}

export function HomeWithHeader() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Header />
      <Box className={classes.pageWrapper}>
        <Hidden smDown>
          <Menu />
        </Hidden>
        <Route path="/" exact isPrivate component={HomePage} />
        <Route
          path={['/reports', '/restaurants', '/settings', '/add', '/coupons']}
          component={WithHeader}
        />
      </Box>
    </Box>
  );
}

export function WithRestaurantMenu() {
  const classes = useStyles();
  return (
    <RestaurantMenuProvider data-test-id="restaurant-menu">
      <Box className={classes.root}>
        <Header menu={RestaurantMenu} />
        <Box className={classes.pageWrapper}>
          <Hidden smDown>
            <RestaurantMenu />
          </Hidden>
          <Route
            path="/:restaurantId"
            exact
            isPrivate
            component={RestaurantPage}
          />
        </Box>
      </Box>
    </RestaurantMenuProvider>
  );
}

export function Root() {
  const classes = useStyles();

  const { isAppLoading } = useAppLoading();

  if (isAppLoading) {
    return (
      <Box
        data-test-id="apploading"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100%"
      >
        <Box marginBottom={2}>
          <CircularProgress />
        </Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.rootbox}>
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/forgot-password" exact component={ForgotPasswordPage} />
          <Route path="/reset-password" exact component={ResetPasswordPage} />
          <Route exact path={['/stripeconnect']} component={RedirectRoutes} />
          <Route
            path={[
              '/reports',
              '/restaurants',
              '/settings',
              '/add',
              '/',
              '/coupons',
            ]}
            exact
            component={HomeWithHeader}
          />
          <Route path={['/:restaurantId']} component={WithRestaurantMenu} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
      <Notifier />
    </Box>
  );
}
