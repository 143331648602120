import Storage, { Engines, Plugins } from '@eatzy/common-reactjs/Storage';
import { isDev, isLocal } from './env';

export const appSetupStore = () => {
  const devMode = isDev();
  const localMode = isLocal();

  const plugins = [];

  if (!devMode && !localMode) plugins.push(Plugins.CompressionPlugin);
  plugins.push(Plugins.ExpirePlugin);

  return Storage.createStore(
    Engines.LocalStorage,
    plugins,
    `${process.env.REACT_APP_VERSION}-appsetup`
  );
};

export default appSetupStore();
