import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    marginBottom: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '24px',
  },
  addUserBtn: {
    background: '#FFFFFF',
    border: '1px solid #D5D5D5',
    borderRadius: '4px',
    fontWeight: 'bold',
    padding: '8px 14px',
  },
  addUserBtnIcon: { marginLeft: '8px' },
  table: {},
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    padding: '6px 16px',
  },
  columnName: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',

    fontSize: '12px',
    color: '#666666',
  },
  lastColumnName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 'bold',

    fontSize: '12px',
    color: '#666666',
  },
  user: {
    padding: '10px 12px',
    width: '100%',

    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',

    background: '#FFFFFF',
    border: '1px solid #D5D5D5',
    borderRadius: '4px',

    marginBottom: '4px',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,

    fontSize: '14px',
    color: '#666666',
  },
  lastCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    fontSize: '14px',
    color: '#666666',
  },
  ml20pc: { marginLeft: '20%' },
  boldText: {
    fontWeight: 'bold',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    color: '#666666',
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  actionBtn: {
    margin: 0,
    padding: 0,
    marginLeft: '14px',
  },
  deleteBtnIcon: {
    color: theme.palette.secondary.main,
  },
  blueText: { color: theme.palette.primary.main },
  noUsersFoundText: {
    marginTop: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.background.mediumGray,

    background: '#fff',
    border: '1px solid #D5D5D5',
    borderRadius: 4,
    padding: '12px 16px',
  },
}));
