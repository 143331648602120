import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  couponsConfigRequest,
  couponsConfigSuccess,
  getRestaurantMenuRequest,
  getRestaurantMenuSuccess,
  getBusinessCouponsRequest,
  getBusinessCouponsSuccess,
  deleteCoupon,
  couponDeleted,
  addCoupon,
  couponAdded,
  addPromos,
  promoAdded,
  error,
} from '../../ducks/CouponDucks';
import { enqueueSnackbar } from '../../ducks/SnackbarDucks';
import {
  getCouponsConfig,
  getRestaurantCouponsConfig,
  getBusinessCoupons,
  deleteCoupon as deleteCouponCall,
  addCoupon as addCouponCall,
  addPromosCall,
} from '../../../services/CouponsService';

function* couponsConfig(action) {
  try {
    const config = yield call(getCouponsConfig, action.payload.businessId);
    yield put(
      couponsConfigSuccess({
        config,
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* getBusinessCouponsSaga(action) {
  try {
    const coupons = yield call(getBusinessCoupons, action.payload.businessId);
    yield put(
      getBusinessCouponsSuccess({
        coupons,
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* RestaurantCouponsConfig(action) {
  try {
    const menus = yield select((state) => state.coupon.menus);

    let restaurantConfig;

    if (!!menus && !!menus[action.payload.restaurantId]) {
      restaurantConfig = menus[action.payload.restaurantId];
    } else {
      restaurantConfig = yield call(
        getRestaurantCouponsConfig,
        action.payload.restaurantId
      );
    }

    yield put(
      getRestaurantMenuSuccess({
        restaurantConfig: { [action.payload.restaurantId]: restaurantConfig },
        items: restaurantConfig.items.map((item) => ({
          restId: action.payload.restaurantId,
          ...item,
        })),
        categories: restaurantConfig.categories.map((category) => ({
          restId: action.payload.restaurantId,
          ...category,
        })),
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* deleteCouponSaga(action) {
  try {
    yield call(deleteCouponCall, action.payload.couponId);
    yield put(couponDeleted());
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* addCouponSaga(action) {
  try {
    const newCoupon = yield call(addCouponCall, action.payload.couponToAdd);
    yield put(
      couponAdded({
        ...newCoupon,
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* addPromosSaga(action) {
  try {
    const newPromos = yield call(addPromosCall, action.payload);
    yield put(
      promoAdded({
        ...newPromos,
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

export default function* watchUser() {
  yield takeEvery(couponsConfigRequest, couponsConfig);
  yield takeEvery(getRestaurantMenuRequest, RestaurantCouponsConfig);
  yield takeEvery(getBusinessCouponsRequest, getBusinessCouponsSaga);
  yield takeEvery(deleteCoupon, deleteCouponSaga);
  yield takeEvery(addCoupon, addCouponSaga);
  yield takeEvery(addPromos, addPromosSaga);
}
