import React from 'react';
import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './AddressModal.styles';

export function AddressModal({
  openModal,
  handleCloseModal,
  searching,
  locationError,
  handleSearchAddress,
  address,
  setAddress,
}) {
  const classes = useStyles();

  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        data-test-id="modal"
        style={{ top: '35%', left: '50%', transform: 'translate(-50%, -50%)' }}
        className={classes.modalPaper}
      >
        <h2 id="simple-modal-title" style={{ marginBottom: '10px' }}>
          Search address
        </h2>
        {searching ? (
          <>
            <CircularProgress /> <Typography>Searching for location</Typography>
          </>
        ) : (
          <>
            {locationError && (
              <Typography className={classes.error}>
                Error on locating address
              </Typography>
            )}
            <TextField
              fullWidth
              variant="outlined"
              name="street"
              placeholder="Street"
              className={classes.formInputBox}
              onChange={handleChange}
              defaultValue={address.street}
              autoFocus
              inputProps={{
                'data-test-id': 'modal-street',
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              name="city"
              inputProps={{
                'data-test-id': 'modal-city',
              }}
              placeholder="City"
              className={classes.formInputBox}
              onChange={handleChange}
              defaultValue={address.city}
            />
            <TextField
              fullWidth
              variant="outlined"
              name="state"
              inputProps={{
                'data-test-id': 'modal-state',
              }}
              placeholder="State"
              className={classes.formInputBox}
              onChange={handleChange}
              defaultValue={address.state}
            />
            <TextField
              fullWidth
              variant="outlined"
              name="zip"
              inputProps={{
                'data-test-id': 'modal-zip',
              }}
              placeholder="Zipcode"
              className={classes.formInputBox}
              onChange={handleChange}
              defaultValue={address.zip}
            />
            <Button
              data-test-id="modal-button"
              color="primary"
              className={classes.searchButton}
              onClick={() => handleSearchAddress(address)}
            >
              Search
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}

AddressModal.propTypes = {
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  searching: PropTypes.bool,
  locationError: PropTypes.bool,
  handleSearchAddress: PropTypes.func,
  address: PropTypes.object,
  setAddress: PropTypes.func,
};
