import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import qs from 'qs';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useAddRestaurantContext } from '../../../context/AddRestaurantContext';
import { useGetErrors } from '../../../hooks/useGetErrors/useGetErrors';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import LoginForm from '../LoginForm';
import { useStyles } from './OwnerLogin.styles';

const FormSchema = yup.object().shape({
  loginOwnerEmail: yup
    .string()
    .email('Invalid e-mail')
    .required('E-mail is required'),
  loginOwnerPassword: yup.string().required('Password is required'),
});

function OwnerLogin({ email }) {
  const classes = useStyles();
  const { ownerData, login } = useAddRestaurantContext();

  const methods = useForm({
    defaultValues: { loginOwnerEmail: email },
    shouldUnregister: false,
    resolver: yupResolver(FormSchema),
    mode: 'onChange',
  });

  const {
    getValues,
    trigger,
    formState: { errors },
  } = methods;

  const { enqueueSnackbar } = useNotifications();
  const { search } = useLocation();

  const { getErrors } = useGetErrors({ errors });

  const formObj = { ...methods, getErrors };

  useEffect(() => {
    if (!_.isNil(search) && !_.isEmpty(search)) {
      const params = qs.parse(
        search.indexOf('?') >= 0
          ? search.substring(search.indexOf('?') + 1)
          : search
      );

      if (params.error && params.error !== '0') {
        enqueueSnackbar({
          message: params.error_description || 'Something went wrong!',
          options: {
            variant: 'error',
          },
        });
      }
    }
  }, [search, enqueueSnackbar]);

  const handleLoginIn = async () => {
    const hasNoError = await trigger(['loginOwnerEmail', 'loginOwnerPassword']);
    if (hasNoError) {
      const { loginOwnerEmail, loginOwnerPassword } = getValues();
      await login('owner', loginOwnerEmail, loginOwnerPassword);
    }
  };

  if (ownerData?.isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress style={{ marginBottom: 5 }} />
        <Typography>Signing in</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root} data-test-id="owner-data-root">
      <Box display="flex" flex={1} flexDirection="column">
        <LoginForm
          form={formObj}
          fieldNames={{
            email: 'loginOwnerEmail',
            password: 'loginOwnerPassword',
          }}
        />
      </Box>
      <Button
        data-test-id="login-button"
        className={classes.loginButton}
        onClick={handleLoginIn}
      >
        Connect with Stripe
      </Button>
    </Box>
  );
}

OwnerLogin.propTypes = {
  email: PropTypes.string,
};

OwnerLogin.defaultProps = {
  email: '',
};

export default OwnerLogin;
