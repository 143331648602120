import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import { Cancel, AttachMoney } from '@material-ui/icons';
import { Controller } from 'react-hook-form';
import { CheckOptions } from '../../CheckOptions';
import { useStyles } from '../AddEditCouponSections.styles';
import { numberValidation } from '../../../../utils/numberValidation';
import AutocompleteField from '../../AutocompleteField';

function DiscountConditions({
  form: { getValues, setValue, control, getErrors },
  items,
}) {
  const classes = useStyles();

  const [freeItems, setFreeItems] = useState(getValues('freeItems') || []);

  const availableItems = useMemo(() => {
    const availableRestaurants = getValues('specificRestaurants') || [];
    if (_.isNil(availableRestaurants) || availableRestaurants.length <= 0) {
      return items;
    }

    const restaurantIds = availableRestaurants.map(
      (restaurant) => restaurant.id
    );
    const result = items.filter(
      (item) =>
        restaurantIds.length > 0 &&
        restaurantIds.filter((rId) => item.restaurants.includes(rId)).length > 0
    );
    return result;
  }, [getValues('specificRestaurants')]);

  const handleAddTag = (tag, selectedTags, setSelectedTags) => {
    const alreadyAdded = selectedTags.some(
      (selected) => selected.id === tag.id
    );
    if (!alreadyAdded) setSelectedTags([...selectedTags, tag]);
  };

  const handleRemoveTag = (tag, selectedTags, setSelectedTags) => {
    setSelectedTags(selectedTags.filter((selected) => selected.id !== tag.id));
  };

  useEffect(() => setValue('freeItems', freeItems), [freeItems]);

  return (
    <Box className={classes.sectionContent}>
      <Box className={classes.mb24}>
        <CheckOptions
          onChange={(value) => setValue('discountType', value)}
          defaultValue={['Flat amount']}
          isRadio
          options={[
            'Percent discount',
            'Flat amount',
            'Free item',
            'Free delivery',
          ]}
        />
      </Box>

      {getValues('discountType') === 'Flat amount' && (
        <Box>
          <Typography variant="h6" className={classes.fieldLabel}>
            Type amount
          </Typography>
          <Box className={classes.alignItemsCenter}>
            <Box className={classes.startAdornment}>
              <AttachMoney className={classes.startAdornmentIcon} />
            </Box>
            <Controller
              control={control}
              name="discountAmount"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  inputProps={{
                    'data-test-id': 'discount-amount',
                  }}
                  fullWidth
                  variant="outlined"
                  onBlur={(a) =>
                    setValue(
                      'discountAmount',
                      Number(a.target.value).toFixed(2)
                    )
                  }
                  onChange={(a) =>
                    setValue(
                      'discountAmount',
                      numberValidation(a.target.value, {
                        min: 0,
                      })
                    )
                  }
                  placeholder="50.00"
                  className={classes.defaultInput}
                  {...getErrors('discountAmount')}
                />
              )}
            />
          </Box>
        </Box>
      )}
      {getValues('discountType') === 'Percent discount' && (
        <Box>
          <Typography variant="h6" className={classes.fieldLabel}>
            Type amount
          </Typography>
          <Box className={classes.alignItemsCenter}>
            <Box className={classes.startAdornment}>
              <Typography className={classes.startAdornmentText}>%</Typography>
            </Box>
            <Controller
              control={control}
              name="discountPercentage"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  inputProps={{
                    'data-test-id': 'discount-percentage',
                  }}
                  fullWidth
                  variant="outlined"
                  onBlur={(a) =>
                    setValue(
                      'discountPercentage',
                      Number(a.target.value).toFixed(2)
                    )
                  }
                  onChange={(a) =>
                    setValue(
                      'discountPercentage',
                      numberValidation(a.target.value, {
                        min: 0,
                        max: 100,
                      })
                    )
                  }
                  placeholder="50 %"
                  className={classes.defaultInput}
                  {...getErrors('discountPercentage')}
                />
              )}
            />
          </Box>
        </Box>
      )}
      {getValues('discountType') === 'Free item' && (
        <Box>
          <AutocompleteField
            data-test-id="prjc_p9XdIcUGaQZgLkUQ"
            options={availableItems}
            key={JSON.stringify(freeItems)}
            onClick={(value) => {
              handleAddTag(value, freeItems, setFreeItems);
            }}
            placeholder="Find item"
            getOptionLabel={(option) => option?.name}
          />
          {!!freeItems.length && (
            <Box
              display="flex"
              marginTop="14px"
              style={{ width: '256px' }}
              className={classes.tagsContainer}
            >
              {freeItems.map((item, i) => (
                <Box
                  data-test-id={`item-${i}`}
                  className={classes.tags}
                  key={item.id}
                >
                  <Typography>{item.name}</Typography>

                  <IconButton
                    data-test-id={`delete-item-${i}`}
                    style={{ padding: 0, marginLeft: '8px' }}
                    onClick={() =>
                      handleRemoveTag(item, freeItems, setFreeItems)
                    }
                  >
                    <Cancel style={{ fontSize: '16px' }} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

DiscountConditions.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
  ),
  items: PropTypes.arrayOf(PropTypes.object),
};

export default DiscountConditions;
