import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { useStyles } from './StripeAccountInfo.styles';

function StripeAccountInfo({ stripeInfo }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box display="flex" flex={1} flexDirection="column">
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Stripe username
        </Typography>
        <Typography className={classes.formTextFieldValue}>
          {stripeInfo.email}
        </Typography>
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Stripe account #
        </Typography>
        <Typography className={classes.formTextFieldValue}>
          {stripeInfo.stripe?.account}
        </Typography>
      </Box>
      <Button
        data-test-id="stripe-dashboard-button"
        className={classes.actionButton}
        onClick={() => window.open('https://dashboard.stripe.com/')}
      >
        Open the Stripe Dashboard
        <OpenInNew className={classes.ml8} />
      </Button>
    </Box>
  );
}

StripeAccountInfo.propTypes = {
  stripeInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    stripe: PropTypes.shape({ account: PropTypes.string.isRequired }),
  }).isRequired,
};

export default StripeAccountInfo;
