import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import ownerAuth from '../utils/ownerAuthentication';
import ownerStore from '../utils/ownerStore';
import appSetupStore from '../utils/appSetupStore';
import appSetupAuth from '../utils/appSetupAuthentication';
import { useNotifications } from '../hooks/useNotifications';
import { useRestaurantMenu } from './RestaurantMenuContext';

export const instance = {
  owner: {
    store: ownerStore,
    auth: ownerAuth,
  },
  appSetup: {
    store: appSetupStore,
    auth: appSetupAuth,
  },
};

export const INITIAL_STATE = {
  ownerData: {
    isAuthenticated: false,
    isLoading: false,
    isError: false,
  },
  appSetupData: {
    isAuthenticated: false,
    isLoading: false,
    isError: false,
  },
};

export const AddRestaurantContext = createContext({
  ...cloneDeep(INITIAL_STATE),
});

export const useAddRestaurantContext = () => useContext(AddRestaurantContext);

export function AddRestaurantContextProvider({ children }) {
  const restaurantMenu = useRestaurantMenu();
  const { enqueueSnackbar } = useNotifications();

  const [ownerData, setOwnerData] = useState({
    isAuthenticated: false,
    isLoading: false,
    isError: false,
  });

  const [appSetupData, setAppSetupData] = useState({
    isAuthenticated: false,
    isLoading: false,
    isError: false,
  });

  useEffect(() => {
    const checkIsOwnerAuthenticated = async () => {
      const ownerToken = await instance.owner.auth.getToken(
        restaurantMenu?.restaurant?.id
      );
      setOwnerData((old) => ({
        ...old,
        isAuthenticated: !!ownerToken && !ownerToken.isExpired,
      }));
    };
    const checkIsAppSetupAuthenticated = async () => {
      const appSetupToken = await instance.appSetup.auth.getToken();
      setAppSetupData((old) => ({
        ...old,
        isAuthenticated: !!appSetupToken && !appSetupToken.isExpired,
      }));
    };
    checkIsOwnerAuthenticated();
    checkIsAppSetupAuthenticated();
  }, [setAppSetupData, setOwnerData, restaurantMenu]);

  const login = useCallback(
    async (type, email, password) => {
      const setData = {
        owner: setOwnerData,
        appSetup: setAppSetupData,
      }[type];

      const authInstance = {
        owner: instance.owner.auth,
        appSetup: instance.appSetup.auth,
      }[type];

      try {
        setData((old) => ({
          ...old,
          isLoading: true,
          isError: false,
        }));
        const response = await authInstance.login(
          email,
          password,
          restaurantMenu?.restaurant?.id
        );
        setData((old) => ({
          ...old,
          isLoading: false,
          isError: false,
          isAuthenticated: true,
        }));

        return response;
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });

        setData((old) => ({
          ...old,
          isLoading: false,
          isError: true,
          isAuthenticated: false,
        }));

        throw err;
      }
    },
    [enqueueSnackbar, restaurantMenu]
  );

  const register = useCallback(
    async (type, { username, firstname, lastname, password, email, phone }) => {
      const setData = {
        owner: setOwnerData,
        appSetup: setAppSetupData,
      }[type];

      const authInstance = {
        owner: instance.owner.auth,
        appSetup: instance.appSetup.auth,
      }[type];

      setData((old) => ({
        ...old,
        isLoading: true,
        isError: false,
      }));

      try {
        await authInstance.register({
          username,
          firstname,
          lastname,
          password,
          email,
          phone,
          source: 'web',
        });
        return await login(type, email, password);
      } catch (err) {
        setData((old) => ({
          ...old,
          isLoading: false,
          isError: true,
        }));
        throw err;
      }
    },
    // eslint-disable-next-line
    []
  );

  const getToken = useCallback(
    async (type) => {
      const authInstance = {
        owner: instance.owner.auth,
        appSetup: instance.appSetup.auth,
      }[type];

      return await authInstance.getToken(restaurantMenu?.restaurant?.id);
    },
    [restaurantMenu]
  );

  const logout = useCallback(
    async (type) => {
      const authInstance = {
        owner: instance.owner.auth,
        appSetup: instance.appSetup.auth,
      }[type];

      const setData = {
        owner: setOwnerData,
        appSetup: setAppSetupData,
      }[type];

      setData((old) => ({
        ...old,
        isLoading: false,
        isError: false,
        isAuthenticated: false,
      }));

      return await authInstance.logout(
        null,
        {
          shouldRedirect: false,
          shouldDeauthorized: false,
        },
        restaurantMenu?.restaurant?.id
      );
    },
    [restaurantMenu]
  );

  const reset = useCallback(async () => {
    await logout('owner');
    await logout('appSetup');
  }, [logout]);

  return (
    <AddRestaurantContext.Provider
      value={{
        ownerData,
        appSetupData,
        login,
        register,
        logout,
        getToken,
        reset,
      }}
    >
      {children}
    </AddRestaurantContext.Provider>
  );
}

AddRestaurantContextProvider.propTypes = {
  children: PropTypes.node,
};
