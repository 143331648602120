import React, { useMemo } from 'react';
import qs from 'qs';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { AddRestaurantContextProvider } from '../../../context/AddRestaurantContext';
import { AddRestaurantForm } from '../../input/AddRestaurantForm';
import {
  NavigationProvider,
  steps,
  stepsDefinition,
} from '../../input/NavigationStepper/context/navigation';

export function AddRestaurantPage() {
  const { search } = useLocation();

  const params =
    !_.isNil(search) && !_.isEmpty(search)
      ? qs.parse(
          search.indexOf('?') >= 0
            ? search.substring(search.indexOf('?') + 1)
            : search
        )
      : [];

  const initialStepRedirect = useMemo(() => {
    if (!_.isNil(params?.error)) {
      return steps[stepsDefinition.STRIPE_CONNECT];
    }

    return steps[stepsDefinition.INFO];
  }, [params]);

  return (
    <AddRestaurantContextProvider>
      <NavigationProvider initialStep={initialStepRedirect}>
        <AddRestaurantForm />
      </NavigationProvider>
    </AddRestaurantContextProvider>
  );
}
