import React, { useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { SearchRounded } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import { WithFallback } from '../../layout/WithFallback';
import { useRestaurantList } from './RestaurantsPage.hooks';
import useNotifications from '../../../hooks/useNotifications/useNotifications';

import { useStyles } from './RestaurantsPage.styles';

const recordsPerPage = 10;

export function RestaurantsPage() {
  const { enqueueSnackbar } = useNotifications();
  const { search } = useLocation();
  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState({
    'Test Mode': {
      checked: true,
      label: 'Test Mode',
    },
    Inactive: {
      checked: true,
      label: 'Inactive',
    },
    Active: {
      checked: true,
      label: 'Active',
    },
  });
  const [searchedRestaurant, setSearchedRestaurant] = useState('');
  const classes = useStyles();
  const { restaurantList, isFetching, isError, refetch, totalPages } =
    useRestaurantList({
      searchedValue: searchedRestaurant,
      recordsPerPage,
      filterStatus,
    });

  const offset = useMemo(() => (page - 1) * recordsPerPage, [page]);

  const handleStatusFilter = useCallback(
    (status) => {
      setFilterStatus({
        ...filterStatus,
        [status]: {
          checked: !filterStatus[status].checked,
          id: status,
        },
      });
    },
    [filterStatus]
  );

  const handleDebounceSearchedValue = useCallback(
    _.debounce((inputValue) => {
      setSearchedRestaurant(inputValue);
      setPage(1);
    }, 500),
    []
  );

  useEffect(() => {
    if (search) {
      const params = qs.parse(
        search.indexOf('?') >= 0
          ? search.substring(search.indexOf('?') + 1)
          : search
      );

      if (params.error && params.error !== '0') {
        enqueueSnackbar({
          message: params.error_description || 'Something went wrong!',
          options: {
            variant: 'error',
          },
        });
      }
    }
  }, [search, enqueueSnackbar]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  if (isError) {
    return (
      <Box alignItems="center" justifyContent="center" display="flex" p={2}>
        <Typography>We had a problem loading the requested items</Typography>
        <Button
          data-test-id="XqyeVyZiOgjzjbgLmjnJG"
          padding={2}
          onClick={refetch}
        >
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <div className={classes.contentWrapper}>
      <Box>
        <div className={classes.contentHeader}>
          <h2 data-test-id="contentTitle" className={classes.contentTitle}>
            Restaurants
          </h2>
          <Box className={classes.filters}>
            <FormGroup className={classes.statusFilters}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.check}
                    data-test-id="test-mode"
                    checked={filterStatus['Test Mode'].checked}
                    onClick={() => handleStatusFilter('Test Mode')}
                  />
                }
                label="Test Mode"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    data-test-id="inactive"
                    className={classes.check}
                    checked={filterStatus.Inactive.checked}
                    onClick={() => handleStatusFilter('Inactive')}
                  />
                }
                label="Inactive"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    data-test-id="active"
                    className={classes.check}
                    checked={filterStatus.Active.checked}
                    onClick={() => handleStatusFilter('Active')}
                  />
                }
                label="Active"
              />
            </FormGroup>

            <TextField
              variant="outlined"
              placeholder="Search"
              onChange={(e) => handleDebounceSearchedValue(e.target.value)}
              className={classes.searchBox}
              aria-label="search input"
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </div>
        <RestaurantList
          restaurantList={restaurantList}
          isLoading={isFetching}
          searchedValue={searchedRestaurant}
          offset={offset}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginBottom="50px"
      >
        <Pagination count={totalPages} page={page} onChange={handleChange} />
      </Box>
    </div>
  );
}

export function RestaurantList({ restaurantList, isLoading, offset }) {
  const classes = useStyles();
  return (
    <WithFallback
      isLoading={isLoading}
      fallback={
        <Box marginBottom={1}>
          <RestaurantListItemSkeleton />
        </Box>
      }
      repeat={10}
    >
      {restaurantList?.length > 0 ? (
        restaurantList
          ?.map((restaurant) => (
            <Box marginBottom={1} key={restaurant.id}>
              <RestaurantListItem restaurant={restaurant} />
            </Box>
          ))
          .slice(offset, recordsPerPage + offset)
      ) : (
        <Box
          className={classes.listWrapper}
          style={{ justifyContent: 'center' }}
        >
          <Typography variant="h3">No Restaurant Available</Typography>
        </Box>
      )}
    </WithFallback>
  );
}

RestaurantList.propTypes = {
  restaurantList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
      phone: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  searchedValue: PropTypes.string,
  offset: PropTypes.number,
};

export function RestaurantListItemSkeleton() {
  const classes = useStyles();
  return (
    <Box className={classes.listWrapper} data-test-id="restaurant:list-wrapper">
      <Box
        className={classes.listItem}
        data-test-id="restaurant:list-item-skeleton"
      >
        <p className={classes.restaurantTitle}>
          <Skeleton width={50} />
        </p>
        <Box display="flex">
          <p className={classes.address}>
            <Skeleton width={100} />
          </p>
          <p>
            <Skeleton width={60} />
          </p>
        </Box>
      </Box>
    </Box>
  );
}

export function RestaurantListItem({ restaurant }) {
  const classes = useStyles();

  const history = useHistory();

  const handleViewClick = () => {
    const { id } = restaurant;
    history.push(`/${id}`);
  };

  return (
    <Box
      className={classes.listWrapper}
      data-test-id="restaurant:list-wrapper"
      onClick={handleViewClick}
    >
      <Box className={classes.listItem} data-test-id="restaurant:list-item">
        <Box className={classes.itemFirstInfo}>
          <p className={classes.restaurantTitle}>{restaurant?.name}</p>
          {restaurant?.live_website && (
            <a
              data-test-id="mfeNnt9JuK2ds_-XilFy9"
              onClick={(e) => e.stopPropagation()}
              className={classes.restaurantDomain}
              href={restaurant.live_website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {restaurant.live_website}
            </a>
          )}
        </Box>
        <Box className={classes.itemInfo}>
          <p className={classes.address}>{restaurant?.address}</p>
          <p>{restaurant?.phone}</p>
        </Box>
        <Box className={classes.status}>
          <Typography className={classes[restaurant?.publishStatus]}>
            {restaurant?.status}
          </Typography>
          <span
            className={clsx(classes.statusIcon, {
              [classes.draftStatus]: restaurant?.status === 'Test Mode',
              [classes.activeStatus]: restaurant?.status === 'Active',
              [classes.inactiveStatus]: restaurant?.status === 'Inactive',
            })}
          />
        </Box>
      </Box>
    </Box>
  );
}

RestaurantListItem.propTypes = {
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    live_website: PropTypes.string,
    status: PropTypes.string,
    publishStatus: PropTypes.string,
  }),
};
