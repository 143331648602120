import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formInputBox: {
    marginBottom: 24,

    '& > div > input': {
      padding: '14px 16px',
    },
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E0E0E0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    color: '#FF3100',
  },
  searchButton: {
    flex: 1,
    width: '100%',
    background: 'rgba(67, 87, 239, 0.1)',
    '&:hover': {
      background: 'rgba(67, 87, 239, 0.15)',
    },
  },
}));
