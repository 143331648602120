import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dropzoneSliderThumb: {
    display: 'inline-flex',
    borderRadius: '2px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  dropzoneImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '2px',
  },
  showImage: {
    display: 'none',
  },
  deleteLogo: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    padding: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 4px 0px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  sliderInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down(670)]: {
      flexDirection: 'column',

      '& > div': {
        width: '100%',
      },
    },
  },
  sliderImage: {
    flex: '1',
    display: 'flex',
    width: '256px',
    minHeight: '160px',
    maxHeight: '160px',
    marginBottom: '32px',
    cursor: 'pointer',
    position: 'relative',

    [theme.breakpoints.down(670)]: {
      width: 'unset',
      minHeight: '200px',
      maxHeight: '200px',
    },
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,
    background: '#FAFAFA',
    boxSizing: 'border-box',
    borderRadius: '2px',

    '& input, textarea': {
      padding: '14px 16px',
      border: '1px solid #D5D5D5',
      fontSize: '16px',
      color: '#000000',
    },

    '& .Mui-disabled': {
      fontSize: '16px',
      color: '#000000',
      borderRadius: '2px',
      border: '1px solid #FAFAFA',

      background: '#FAFAFA',
    },

    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  deleteSliderButton: {
    padding: '6px 16px',
    background: 'rgba(255, 49, 0, 0.04)',
    color: '#FF3100',
    fontWeight: 'medium',

    '& > span > svg': {
      fill: '#FF3100',
      marginRight: '4px',
    },
  },
  dropzone: {
    display: 'flex',
    border: '1px dashed #D5D5D5',
    borderRadius: 2,
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 16,
    flexDirection: 'column',
    width: '100%',
  },
  dropzoneDisabled: {
    border: 'none',
    background: '#FAFAFA',
  },
  tabs: {
    width: 'fit-content !important',
    minHeight: '0px',
    '& > .MuiTabs-scroller > span': {
      display: 'none',
    },

    '& > .MuiTabs-scroller > .MuiTabs-flexContainer > .Mui-selected': {
      border: '1px solid #007AFF',
      color: '#007AFF !important',
      background: 'rgba(0, 122, 255, 0.05) !important',
    },
  },
  tab: {
    padding: '6px 20px !important',
    marginLeft: '4px',

    background: '#FFF !important',
    border: '1px solid #D5D5D5',
    color: '#333333 !important',
    borderRadius: '32px !important',
    fontSize: '14px',
    lineHeight: '17px',
    minHeight: '0px',

    textTransform: 'capitalize',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addTabBtn: {
    marginLeft: '12px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabPanel: {
    '& > div': {
      padding: '46px',
      paddingBottom: '24px',
      marginBottom: '24px',
      border: '1px solid #D5D5D5',
      borderRadius: '2px',
    },
  },
}));
