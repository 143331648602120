import React from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import Environment from '@eatzy/common-reactjs/Environment';
import { defaultStore } from '../../../redux/stores/GlobalStore';
import { Root } from '../Root';
import Wrapper from '../Wrapper';
import registerInterceptors from '../../../services/interceptors/AccountServiceInterceptors';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

Environment.parseInitialEnv();

registerInterceptors(defaultStore);

function App() {
  return (
    <Wrapper>
      <QueryClientProvider client={queryClient}>
        <Root />
      </QueryClientProvider>
    </Wrapper>
  );
}

export default App;
