import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mapContainer: {
    [theme.breakpoints.down(735)]: {
      marginLeft: '0',
      marginBottom: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  mapStyle: {
    height: '292px',

    [theme.breakpoints.down(735)]: {
      width: '100%',
    },
  },
}));
