import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker as StaticDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Box, Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './DatePicker.styles';

export function DatePicker({
  popoverEl,
  handleClosePopover,
  customDate,
  setCustomDate,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const closeDatePicker = () => {
    setIsOpen(false);
    handleClosePopover();
  };

  const handleSelectedDate = (date) => {
    setCustomDate(date);
    closeDatePicker();
  };

  useEffect(() => {
    if (popoverEl) setIsOpen(true);
    else setIsOpen(false);
  }, [popoverEl]);

  return (
    <Popover
      open={isOpen}
      anchorEl={popoverEl}
      onClose={closeDatePicker}
      className={classes.popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box data-test-id="custom-date" padding="16px">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            calendars={1}
            value={customDate}
            className={classes.calendarWrapper}
            onChange={handleSelectedDate}
          />
        </MuiPickersUtilsProvider>
      </Box>
    </Popover>
  );
}

DatePicker.propTypes = {
  popoverEl: PropTypes.element,
  handleClosePopover: PropTypes.func,
  customDate: PropTypes.instanceOf(Date),
  setCustomDate: PropTypes.func,
};
