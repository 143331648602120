import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

export function Loading() {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress style={{ marginBottom: 5 }} />
      <Typography>Loading info...</Typography>
    </Box>
  );
}
