import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Cancel, Clear, Edit, Check } from '@material-ui/icons';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStyles } from './EditRestaurantInfo.styles';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';
import { Loading } from '../Loading';
import { saveRestaurant } from '../../../services/RestaurantsService';
import { getLocation } from '../../../services/LocationService';
import { AddressModal } from '../../input/AddressModal';
import { Map } from '../../input/Map/Map';
import { isValidUrl } from '../../../utils/isValidUrl';
import { PhoneNumberInput } from '../../input/PhoneNumberInput';
import { useGetErrors } from '../../../hooks/useGetErrors';
import useNotifications from '../../../hooks/useNotifications';

const FormSchema = yup.object().shape({
  name: yup.string().required('Restaurant name is required'),
  description: yup.string().required('Restaurant description is required'),
  address: yup.object().shape({
    formattedAddress: yup.string().required('Restaurant address is required'),
  }),
  phone: yup
    .string()
    .matches(/(\\([0-9]{3}\\)\\ ?)?[0-9]{3}-[0-9]{4}/, 'Number format is wrong')
    .required('Restaurant phone is required'),
  tags: yup.array().required('Add at least one tag').min(1),
  rawTags: yup.string().nullable(),
  cuisine: yup.string().required('Add category type').nullable(),
  haveSite: yup.bool().required(),
  site: yup.string().when('haveSite', {
    is: true,
    then: yup
      .string()
      .test('is-url-valid', 'Insert a valid web site', (value) =>
        isValidUrl(value)
      )
      .required('Web Site required'),
  }),
});

export function EditRestaurantInfo() {
  const classes = useStyles();
  const {
    restaurant,
    edit,
    setEdit,
    loading,
    setLoading,
    setRestaurant,
    template,
  } = useRestaurantMenu();
  const [address, setAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
  });
  const {
    getValues,
    control,
    reset,
    setValue,
    trigger,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: restaurant,
    shouldUnregister: false,
    resolver: yupResolver(FormSchema),
    mode: 'onChange',
  });
  const [position, setPosition] = useState(null);
  const [tags, setTags] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [searching, setSearching] = useState(false);
  const { getErrors, hasAnyError } = useGetErrors({ errors });
  const { enqueueSnackbar } = useNotifications();

  useEffect(() => {
    reset(restaurant);
    setTags(getValues('tags'));
    setPosition(getValues('address')?.latlng);
    setAddress(restaurant?.address);
  }, [restaurant, reset, getValues]);

  const handleSave = async () => {
    await trigger();
    if (!hasAnyError()) {
      setLoading(true);
      try {
        const response = await saveRestaurant({
          id: restaurant.id,
          data: getValues(),
        });
        setRestaurant(response);
        setLoading(false);
        setEdit(!edit);
      } catch (err) {
        enqueueSnackbar({
          message: err.message
            ? `Error: ${err.message}`
            : 'Something went wrong! Try again later.',
          options: {
            variant: 'error',
          },
        });
        setLoading(false);
      }
    }
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancelEdit = () => {
    reset(restaurant);
    setEdit(false);
  };

  const handleSearchAddress = useCallback(
    async (address) => {
      setSearching(true);
      try {
        const response = await getLocation(address);
        setPosition(response.location);
        setValue('address', {
          ...response,
          latlng: { lat: response.location.lat, lng: response.location.lng },
        });
        handleCloseModal();
        setSearching(false);
      } catch (err) {
        setValue('address', address);
        setLocationError(true);
        setSearching(false);
      }
    },
    [setValue]
  );

  const handleChangePosition = (pos) => {
    setPosition(pos);
    setValue('address.latlng', pos);
  };

  const handleAddTag = () => {
    if (getValues('rawTags')) {
      const newTags = tags;
      newTags.push(...getValues('rawTags').split(','));
      setTags(newTags);
      reset({ ...getValues(), tags: newTags, rawTags: '' });
    }
  };

  const handleDeleteTag = async (i) => {
    const newTags = tags;
    newTags.splice(i, 1);
    setTags(newTags);
    reset({ ...getValues(), tags: newTags });
    await trigger('tags');
  };

  const handleOpenModal = () => {
    setLocationError(false);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (loading)
    return (
      <Box flex={1}>
        <Loading />
      </Box>
    );

  return (
    <form style={{ display: 'flex', flex: 1 }}>
      <Box className={classes.root}>
        <Box className={classes.formTitle}>
          <Typography variant="h2">Restaurant Info</Typography>
          {edit ? (
            <Box display="flex">
              <Button
                className={classes.editButton}
                data-test-id="cancel"
                onClick={handleCancelEdit}
              >
                <Clear fontSize="small" />
                <p style={{ marginLeft: '8px' }}>Cancel</p>
              </Button>
              <Button
                className={classes.saveButton}
                data-test-id="save-info"
                onClick={handleSave}
              >
                <Check fontSize="small" />
                <p style={{ marginLeft: '8px' }}>Save Changes</p>
              </Button>
            </Box>
          ) : (
            <Button
              className={classes.editButton}
              data-test-id="edit-info"
              onClick={handleEdit}
            >
              <Edit fontSize="small" />
              <p style={{ marginLeft: '8px' }}>Edit Info</p>
            </Button>
          )}
        </Box>
        <Box className={classes.content}>
          <Box flex="1.25">
            <Typography variant="h6" className={classes.formTextFieldLabel}>
              Restaurant name
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  disabled={!edit}
                  variant="outlined"
                  placeholder="Restaurant Name"
                  className={classes.formInputBox}
                  {...getErrors('name')}
                />
              )}
              name="name"
              control={control}
            />
            <Typography variant="h6" className={classes.formTextFieldLabel}>
              Phone Number
            </Typography>
            <PhoneNumberInput
              control={control}
              classes={classes}
              disabled={!edit}
              getErrors={getErrors}
              name="phone"
            />
            <Typography variant="h6" className={classes.formTextFieldLabel}>
              Cuisine category
            </Typography>
            <Box marginBottom="24px">
              <Controller
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    options={template.categories}
                    data-test-id="cuisine-autocomplete"
                    freeSolo
                    disablePortal
                    blurOnSelect
                    autoSelect
                    disabled={!edit}
                    ListboxProps={{
                      style: { maxHeight: '30vh', textTransform: 'capitalize' },
                    }}
                    renderInput={({ inputProps, ...params }) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Cuisine Type"
                        style={{ marginBottom: '0px', padding: '0px' }}
                        inputProps={{
                          ...inputProps,
                          style: {
                            padding: '5px 7px',
                            textTransform: 'capitalize',
                          },
                        }}
                        {...getErrors('cuisine')}
                      />
                    )}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                    }}
                    {...props}
                  />
                )}
                defaultValue={getValues('cuisine')}
                name="cuisine"
                control={control}
              />
            </Box>
            <Typography variant="h6" className={classes.formTextFieldLabel}>
              Tags
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box flex={1}>
                <Controller
                  render={({ field: { onChange, ref, ...props } }) => (
                    <Autocomplete
                      options={template?.tags || []}
                      data-test-id="tags-autocomplete"
                      freeSolo
                      disablePortal
                      blurOnSelect
                      disabled={!edit}
                      autoSelect
                      ListboxProps={{
                        style: {
                          maxHeight: '30vh',
                          textTransform: 'capitalize',
                        },
                      }}
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Type tags like “Pizza”, “Tacos”, “Lobster Ro…”"
                          fullWidth
                          className={clsx(classes.formInputBox)}
                          style={{ marginBottom: 0 }}
                          inputProps={{
                            ...inputProps,
                            style: {
                              padding: '5px',
                              textTransform: 'capitalize',
                            },
                          }}
                          {...getErrors('tags')}
                        />
                      )}
                      onChange={(_, newValue) => {
                        onChange(newValue);
                      }}
                      value={getValues('rawTags')}
                      ref={ref}
                      {...props}
                    />
                  )}
                  defaultValue={getValues('rawTags')}
                  name="rawTags"
                  control={control}
                />
              </Box>
              <Button
                disabled={!edit}
                data-test-id="add-tag"
                className={classes.addTagButton}
                onClick={handleAddTag}
              >
                ADD TAG
              </Button>
            </Box>
            <Box display="flex" marginTop="14px" marginBottom="24px">
              {tags?.map((tag, i) => (
                <Box
                  data-test-id={`tags-${i}`}
                  className={classes.tags}
                  key={tag}
                >
                  {tag}
                  <IconButton
                    data-test-id={`delete-tag-${i}`}
                    style={{ padding: 0, marginLeft: '8px' }}
                    disabled={!edit}
                    onClick={() => handleDeleteTag(i)}
                  >
                    <Cancel style={{ fontSize: '16px' }} />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Box className={classes.switchWrapper}>
              <Controller
                name="haveSite"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, ...props } }) => (
                  <Switch
                    disabled={!edit}
                    color="primary"
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    {...props}
                  />
                )}
              />
              <Typography
                variant="h6"
                className={classes.formTextFieldLabel}
                style={{ margin: 0 }}
              >
                I have an existing website
              </Typography>
            </Box>
            <Typography variant="h6" className={classes.formTextFieldLabel}>
              What is the domain name of your website?
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  placeholder="ex: www.chowfoodandbeverage.com"
                  className={clsx(classes.formInputBox)}
                  disabled={!watch('haveSite') || !edit}
                  {...getErrors('site')}
                />
              )}
              name="site"
              control={control}
            />
            <Typography
              variant="h6"
              className={classes.formTextFieldLabel}
              style={{ marginTop: '24px' }}
            >
              Tagline or Brief Description
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  id="description"
                  placeholder="Quick, healthy Vietnamese food with a modern twist"
                  className={clsx(
                    classes.formInputBox,
                    classes.descriptionMultiline
                  )}
                  disabled={!edit}
                  minRows={2}
                  maxRows={2}
                  multiline
                  inputProps={{
                    style: { fontSize: '16px' },
                  }}
                  {...getErrors('description')}
                />
              )}
              name="description"
              control={control}
            />
          </Box>
          <Box flex="1">
            <Typography variant="h6" className={classes.formTextFieldLabel}>
              Restaurant Address
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  data-test-id="RSru-o4dy_gCBuwL1qu9y"
                  {...field}
                  fullWidth
                  variant="outlined"
                  placeholder="Restaurant Address"
                  className={classes.formInputBox}
                  onClick={handleOpenModal}
                  onFocus={handleOpenModal}
                  disabled={!edit}
                  {...getErrors('address.formattedAddress')}
                />
              )}
              name="address.formattedAddress"
              control={control}
            />
            <AddressModal
              openModal={openModal}
              handleCloseModal={handleCloseModal}
              searching={searching}
              locationError={locationError}
              handleSearchAddress={handleSearchAddress}
              address={address}
              setAddress={setAddress}
            />
            <Map
              position={position}
              setPosition={handleChangePosition}
              setValue={setValue}
              disabled={!edit}
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
}
