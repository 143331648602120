import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './AutocompleteField.styles';

function AutocompleteField({
  onClick,
  placeholder,
  options,
  getOptionLabel,
  disabled,
}) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box flex={1}>
        <Autocomplete
          options={options}
          data-test-id="autocomplete"
          freeSolo
          disablePortal
          blurOnSelect
          disableClearable
          getOptionLabel={getOptionLabel}
          autoSelect
          disabled={disabled}
          ListboxProps={{
            style: {
              maxHeight: '30vh',
              textTransform: 'capitalize',
            },
          }}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={placeholder}
              fullWidth
              className={clsx(classes.formInputBoxAutocomplete)}
              style={{ marginBottom: '0px', padding: '0px' }}
              inputProps={inputProps}
            />
          )}
          onChange={(e, newValue) => {
            if (e.type === 'click') onClick(newValue);
          }}
        />
      </Box>
    </Box>
  );
}

AutocompleteField.propTypes = {
  onClick: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ])
  ),
  disabled: PropTypes.bool,
};

AutocompleteField.defaultProps = {
  placeholder: '',
  options: [],
  disabled: false,
};

export default AutocompleteField;
