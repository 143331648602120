import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    margin: '0 auto',
    padding: '32px 32px',
    maxWidth: '960px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '24px',
    border: '1px solid #E0E0E0',
    width: '100%',
    height: 'max-content',

    [theme.breakpoints.down(732)]: {
      margin: '0 8px',
      maxWidth: 'calc(100% - 16px)',
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusText: {
    fontSize: '16px',
    lineHeight: '16px',
    marginLeft: '8px',
  },
  title: {
    marginBottom: '12px',
    fontSize: '24px',
    lineHeight: '29px',
  },
  mb24: {
    marginBottom: '24px',
  },
  error: {
    color: theme.palette.restaurantStatus.inactive,
  },
  success: {
    color: '#29C90F',
  },
  formTitle: {
    marginBottom: 32,
  },
  changeUser: {
    padding: '6px 12px',
    width: '256px',
    fontWeight: 600,
    fontSize: '16px',
  },
  ownerEmail: {
    color: '#3C55E8',
    fontWeight: 'bold',
  },
}));
