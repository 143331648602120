import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dropzoneImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '2px',
  },
  showImage: {
    display: 'none',
  },
  deleteLogo: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    padding: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 4px 0px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
  image: {
    flex: '1',
    display: 'flex',
    marginBottom: '32px',
    cursor: 'pointer',
    position: 'relative',

    [theme.breakpoints.down(670)]: {
      width: 'unset',
    },
  },

  dropzone: {
    display: 'flex',
    border: '1px dashed #D5D5D5',
    borderRadius: 2,
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  dropzoneDisabled: {
    border: 'none',
    background: '#FAFAFA',
  },
}));
