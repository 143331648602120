import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import { format } from 'date-fns';
import AddEditCoupon from '../../input/AddEditCoupon';
import { useStyles } from './CouponsPage.styles';

import {
  getBusinessCouponsRequest,
  couponsConfigRequest,
  deleteCoupon,
  addPromos,
} from '../../../redux/ducks/CouponDucks';

import { Loading } from '../../layout/Loading';

const ownerTypeLabels = {
  restaurant: 'Restaurant',
  provider: 'Eatzy',
  business: 'Local Delivery',
};

const parseExpires = (rules) => {
  let expiration = 'Never';
  if (_.isEmpty(rules)) {
    return expiration;
  }

  expiration = rules.map((rule) => {
    if (rule.type === 'validity') {
      const expirationDate = new Date(rule.params.value.to * 1000);
      return format(expirationDate, 'MM/dd/yyyy');
    }
  });

  return expiration;
};

const parseEffects = (effects) => {
  if (_.isEmpty(effects)) {
    return 'No Discount';
  }

  const firstEffect = effects[0];
  if (firstEffect.type === 'percentage') {
    return `${firstEffect.params.value}%`;
  }
  if (firstEffect.type === 'flat') {
    return `$${firstEffect.params.value}`;
  }
  if (firstEffect.type === 'freedelivery') {
    return 'Free Delivery';
  }
  if (firstEffect.type === 'freeitem') {
    return 'Free Item';
  }
  return 'Unknown Discount';
};

function Coupon({ couponInfo, handleDeleteCoupon }) {
  const classes = useStyles();
  return (
    <Box className={classes.coupon}>
      <Typography className={classes.cell}>{couponInfo.name}</Typography>
      <Typography className={clsx(classes.cell, classes.boldText)}>
        {couponInfo.code}
      </Typography>
      <Typography className={classes.cell}>
        {parseExpires(couponInfo.rules)}
      </Typography>
      <Typography className={clsx(classes.cell, classes.boldText)}>
        {parseEffects(couponInfo.effects)}
      </Typography>
      <Typography className={clsx(classes.cell, classes.boldText)}>
        {ownerTypeLabels[couponInfo.owner]}
      </Typography>
      <Box className={classes.lastCell}>
        <IconButton
          data-test-id="MCWUwNm06Pdl7fMePRuAi"
          className={classes.actionBtn}
          onClick={() => handleDeleteCoupon(couponInfo.id)}
        >
          <Delete
            data-test-id="remove-coupon-btn"
            className={classes.deleteBtnIcon}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

Coupon.propTypes = {
  couponInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    business: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    created: PropTypes.number.isRequired,
    expires: PropTypes.number,
    owner: PropTypes.string.isRequired,
    effects: PropTypes.array.isRequired,
    rules: PropTypes.array.isRequired,
  }).isRequired,
  handleDeleteCoupon: PropTypes.func.isRequired,
};

export function CouponsPage() {
  const classes = useStyles();
  const [addCoupon, setAddCoupon] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [couponData, setCouponData] = useState([]);
  const [selectedBusinessID, setSelectedBusinessID] = useState();
  const [promoData, setPromoData] = useState({});
  const dispatch = useDispatch();

  const {
    couponsInfo,
    couponsConfig,
    currentBusiness,
    isCouponLoading,
    deletedCoupon,
    couponAdded,
    couponToAdd,
    allBusinesses,
  } = useSelector(({ coupon, user }) => ({
    couponsInfo: coupon.coupons,
    couponsConfig: coupon.config,
    deletedCoupon: coupon.deletedCoupon,
    couponAdded: coupon.couponAdded,
    couponToAdd: coupon.couponToAdd,
    currentBusiness: user.currentBusiness,
    isCouponLoading: coupon.isLoading,
    allBusinesses: user.allBusinesses,
  }));

  useEffect(() => {
    if (_.isNil(couponsInfo) && currentBusiness) {
      dispatch(
        getBusinessCouponsRequest({
          businessId: currentBusiness.id,
        })
      );
    } else {
      setCouponData(couponsInfo);
    }
  }, [couponsInfo]);

  useEffect(() => {
    if (_.isNil(couponsConfig) && currentBusiness) {
      dispatch(
        couponsConfigRequest({
          businessId: currentBusiness.id,
        })
      );
    }
  }, [couponsConfig]);

  useEffect(() => {
    if (deletedCoupon && selectedBusinessID) {
      dispatch(
        getBusinessCouponsRequest({
          businessId: selectedBusinessID,
        })
      );
    }
  }, [deletedCoupon]);

  useEffect(() => {
    if (couponAdded) {
      if (promoData && Object.keys(promoData).length > 0) {
        const requestData = {
          coupon: couponAdded?.hash,
          offer: promoData?.offer,
          title: promoData?.title,
          description: promoData?.description,
          start: Math.floor(promoData.startDate / 1000),
          end: Math.floor(promoData.endDate / 1000),
          group: 0,
        };
        const data = {
          promos: requestData,
          id: promoData.id,
        };
        dispatch(addPromos(data));
      }
      if (couponToAdd || currentBusiness) {
        const id = couponToAdd?.business ?? currentBusiness?.id;
        setSelectedBusinessID(id);
        dispatch(
          getBusinessCouponsRequest({
            businessId: id,
          })
        );
      }
      handleClose();
    }
  }, [couponAdded]);

  useEffect(() => {
    if (!_.isNil(selectedBusinessID)) {
      dispatch(
        getBusinessCouponsRequest({
          businessId: selectedBusinessID,
        })
      );
    }
  }, [selectedBusinessID]);

  const handleClose = () => {
    setSelectedCoupon({});
    setAddCoupon(false);
  };

  const handleDeleteCoupon = (couponId) => {
    dispatch(
      deleteCoupon({
        couponId,
      })
    );
  };

  const getBusinessName = useCallback(
    (id) => {
      if (!allBusinesses?.length) return null;
      return allBusinesses?.filter((ev) => ev?.id === id)?.[0]?.name;
    },
    [allBusinesses]
  );

  if (isCouponLoading)
    return (
      <Box className={classes.root}>
        <Loading />
      </Box>
    );

  if (addCoupon)
    return (
      <AddEditCoupon
        coupon={selectedCoupon}
        onClose={handleClose}
        setPromoData={setPromoData}
      />
    );

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.title}>Coupons</Typography>
          <Select
            value={selectedBusinessID ?? ''}
            variant="outlined"
            className={classes.defaultInput}
            displayEmpty
            renderValue={(selected) => {
              if (!selected || selected?.length === 0) {
                return <em>Select business ID</em>;
              }

              return getBusinessName(selected);
            }}
            onChange={(e) => setSelectedBusinessID(e.target.value)}
          >
            <MenuItem disabled value="">
              <em>Select business ID</em>
            </MenuItem>
            {allBusinesses &&
              allBusinesses?.map((ev) => (
                <MenuItem value={ev?.id} key={ev?.id}>
                  {ev?.name}
                </MenuItem>
              ))}
          </Select>
        </Box>
        <Button
          className={classes.addCouponBtn}
          data-test-id="add-new-coupon-btn"
          onClick={() => setAddCoupon(true)}
          disabled={_.isNil(couponsConfig)}
        >
          Add new coupon
          <Add className={classes.addCouponBtnIcon} />
        </Button>
      </Box>
      <Box className={classes.table}>
        <Box className={classes.tableHeader}>
          <Typography className={classes.columnName}>Title</Typography>
          <Typography className={classes.columnName}>Code</Typography>
          <Typography className={classes.columnName}>Expires</Typography>
          <Typography className={classes.columnName}>Value</Typography>
          <Typography className={classes.columnName}>Owner</Typography>
          <Typography className={classes.lastColumnName}>Actions</Typography>
        </Box>
        {couponData?.length ? (
          couponData.map((couponInfo) => (
            <Coupon
              key={couponInfo.id}
              couponInfo={couponInfo}
              handleDeleteCoupon={handleDeleteCoupon}
            />
          ))
        ) : (
          <Typography className={classes.emptyFont}>
            There is no coupon data for the selected business, please select
            another business ID
          </Typography>
        )}
      </Box>
    </Box>
  );
}
