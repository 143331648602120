import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import {
  AddRounded,
  Home,
  Settings,
  Storefront,
  Timeline,
  LocalOffer,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './Menu.styles';

function MenuItem({ to, icon, label }) {
  const classes = useStyles();
  const location = useLocation();
  const Icon = icon;
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <ListItem
        className={clsx(classes.listItem, {
          [classes.active]: location.pathname === to,
        })}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <Icon className={classes.icon} fontSize="small" />
          <p className={classes.textMenu}>{label}</p>
        </ListItemIcon>
      </ListItem>
    </Link>
  );
}

MenuItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.string,
};

export function Menu({ mobile }) {
  const location = useLocation();
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, { [classes.mobile]: mobile })}>
      <List component="nav">
        <MenuItem to="/" icon={Home} label="Home" />
        <MenuItem to="/reports" icon={Timeline} label="Reports" />
        <MenuItem to="/restaurants" icon={Storefront} label="Restaurants" />
        <MenuItem to="/settings" icon={Settings} label="Settings" />
        <MenuItem to="/coupons" icon={LocalOffer} label="Coupons" />
      </List>
      <Link
        className={clsx(classes.addButton, {
          [classes.activeAdd]: location.pathname === '/add',
        })}
        to="/add"
        style={{ textDecoration: 'none' }}
      >
        <AddRounded fontSize="small" className={classes.buttonIcon} />
        <p className={classes.buttonText}>Add New Restaurant</p>
      </Link>
    </div>
  );
}

Menu.propTypes = {
  mobile: PropTypes.bool,
};
