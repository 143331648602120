import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px 0 0',
    flex: 1,
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      margin: '0 8px',
    },
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 45,
    [theme.breakpoints.down('sm')]: {
      justifyContent: '',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginBottom: 20,
    },
  },
  contentTitle: {
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchBox: {
    borderRadius: 8,
    background: '#fff',

    '& > * > *': {
      border: 'none',
    },

    '& > div': {
      paddingLeft: 16,
      borderRadius: 8,
    },

    '& > div > div > svg': {
      fill: 'rgba(42, 51, 40, 0.45)',
    },

    '& > div > input': {
      padding: '8px 16px 8px 0',
      fontWeight: 500,
      fontSize: 14,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  listWrapper: {
    display: 'flex',
    background: '#fff',
    border: '1px solid #D5D5D5',
    borderRadius: 4,
    padding: '12px 16px',
    justifyContent: 'space-between',

    '&:hover': {
      cursor: 'pointer',
      background: '#f5f5f5',
    },

    [theme.breakpoints.down(710)]: {
      flexDirection: 'column',
    },
  },
  listItem: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',

    [theme.breakpoints.down(710)]: {
      flexDirection: 'column',
    },
  },
  itemFirstInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0.5',
  },
  itemInfo: {
    display: 'flex',
    flex: 1,
    marginLeft: '8px',
    [theme.breakpoints.down(710)]: {
      marginTop: '8px',
      marginBottom: '12px',
      marginLeft: '0px',
    },
    [theme.breakpoints.down(510)]: {
      flexDirection: 'column',
    },
  },
  restaurantTitle: {
    fontWeight: 600,
  },
  restaurantDomain: {
    textDecorationLine: 'underline',
    width: 'fit-content',
    color: '#666666',
    fontSize: '14px',
    '&:hover': {
      color: '#333333',
    },
  },
  address: {
    marginRight: 16,
    [theme.breakpoints.down(510)]: {
      marginBottom: '8px',
    },
  },
  link: {
    fontWeight: 600,
    textTransform: 'uppercase',
    textDecorationLine: 'underline',
    color: '#4357EF',
    padding: 0,

    '&:hover': {
      background: 'rgba(67, 87, 239, 0.05)',
      textDecorationLine: 'underline',
    },

    [theme.breakpoints.down(710)]: {
      background: 'rgba(67, 87, 239, 0.05)',

      '&:hover': {
        background: 'rgba(67, 87, 239, 0.15)',
        textDecorationLine: 'underline',
      },
    },
  },
  published: {
    color: theme.palette.restaurantStatus.active,
  },
  unpublished: {
    color: theme.palette.restaurantStatus.inactive,
  },
  draft: {
    color: theme.palette.restaurantStatus.draft,
  },
  activeStatus: {
    backgroundColor: theme.palette.restaurantStatus.active,
  },
  inactiveStatus: {
    backgroundColor: theme.palette.restaurantStatus.inactive,
  },
  draftStatus: {
    backgroundColor: theme.palette.restaurantStatus.draft,
  },
  statusIcon: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'block',
    marginLeft: '6px',
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  check: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  },
  statusFilters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      marginBottom: '10px',
      width: '100%',
    },
  },
}));
