import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Switch, TextField, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { AttachMoney } from '@material-ui/icons';
import { useStyles } from '../AddEditCouponSections.styles';
import { numberValidation } from '../../../../utils/numberValidation';

function ServiceTypeConditions({ form: { control, getErrors, setValue } }) {
  const classes = useStyles();

  return (
    <Box className={classes.sectionContent}>
      <Box className={classes.alignItemsCenter}>
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="delivery"
            control={control}
            defaultValue
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                onChange={onChange}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>Delivery</Typography>
        </Box>
        <Box className={clsx(classes.mr24, classes.alignItemsCenter)}>
          <Controller
            name="takeout"
            control={control}
            defaultValue
            render={({ field: { onChange, value, ref, ...props } }) => (
              <Switch
                inputRef={ref}
                color="primary"
                onChange={onChange}
                checked={value}
                {...props}
              />
            )}
          />
          <Typography className={classes.ml6}>Takeout</Typography>
        </Box>
        <Box>
          <Typography variant="h6" className={classes.fieldLabel}>
            Minimum order amount
          </Typography>
          <Box className={classes.alignItemsCenter}>
            <Box className={classes.startAdornment}>
              <AttachMoney className={classes.startAdornmentIcon} />
            </Box>
            <Controller
              control={control}
              name="minOrderAmount"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  inputProps={{
                    'data-test-id': 'min-order-amount',
                  }}
                  onBlur={(a) =>
                    setValue(
                      'minOrderAmount',
                      Number(a.target.value).toFixed(2)
                    )
                  }
                  onChange={(a) =>
                    setValue(
                      'minOrderAmount',
                      numberValidation(a.target.value, {
                        min: 0,
                      })
                    )
                  }
                  fullWidth
                  variant="outlined"
                  placeholder="50.00"
                  className={classes.defaultInput}
                  {...getErrors('minOrderAmount')}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ServiceTypeConditions.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
  ),
};

export default ServiceTypeConditions;
