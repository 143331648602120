import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 32px',
    alignItems: 'center',
    background: 'white',
    borderBottom: '1px solid #D5D5D5',
    position: 'fixed',
    width: '100%',
    zIndex: '1001',

    [theme.breakpoints.down('sm')]: {
      margin: '8px',
      display: 'flex',
      justifyContent: 'unset',
      padding: '16px',
      alignItems: 'center',
      background: 'white',
      borderRadius: '12px',
      marginBottom: '32px',
      border: 'none',
      boxShadow: '0 3px 3px #0000000F',
    },
  },
  logo: {
    height: 24,
    width: 24,
    marginRight: 8,
  },
  title: {
    fontSize: 12,
    fontWeight: 800,
    color: '#333',

    '& > span': {
      color: '#C70000',
    },
  },
  logOutButton: {
    backgroundColor: 'rgba(42, 51, 40, 0.05)',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 8,
    padding: '8px 16px',

    [theme.breakpoints.down('sm')]: {
      padding: '2px 4px',
    },
  },
  link: {
    textDecoration: 'none',
  },

  iconButton: {
    padding: '0',
    margin: '0 12px',

    '& > svg': {
      fill: '#333',
    },
  },
  drawer: {
    marginTop: 8,
    borderTopRightRadius: 16,
    maxHeight: '100vh',
    paddingRight: 8,
    backgroundColor: '#fff',
  },
}));
