import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  selectedUser: {},
  usersList: [],
  isLoading: false,
  error: null,
  open: false,
  step: 'search',
  steps: {
    1: 'search',
    2: 'userNotFound',
    3: 'register',
    4: 'setPermission',
  },
};

const appUserSlice = createSlice({
  name: 'appUser',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    toggleModal(state, action) {
      state.open = action.payload?.open;
    },
    goToStep(state, action) {
      state.step = action.payload?.step;
    },
    selectUser(state, action) {
      state.selectedUser = action.payload?.user;
    },
    searchUserRequest(state) {
      state.isLoading = true;
      state.selectedUser = {};
    },
    searchUserSuccess(state, action) {
      return {
        ...state,
        selectedUser: action.payload?.user,
        isLoading: false,
        error: null,
      };
    },
    listUsersRequest(state) {
      state.isLoading = true;
    },
    listUsersSuccess(state, action) {
      return {
        ...state,
        usersList: action.payload?.usersList,
        isLoading: false,
        error: null,
      };
    },
    error(state, action) {
      state.error = action?.payload?.message || 'Unknown Error';
      state.isLoading = false;
    },
    clearErrors(state) {
      state.error = null;
      state.isLoading = false;
    },
    registerUserRequest(state) {
      state.isLoading = true;
    },
    deleteUserRequest(state) {
      state.isLoading = true;
    },
    updateUserRoleRequest(state) {
      state.isLoading = true;
    },
  },
});

export const {
  searchUserRequest,
  searchUserSuccess,
  error,
  clearErrors,
  toggleModal,
  goToStep,
  selectUser,
  listUsersRequest,
  listUsersSuccess,
  registerUserRequest,
  deleteUserRequest,
  updateUserRoleRequest,
} = appUserSlice.actions;

export default appUserSlice.reducer;
