import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  rootbox: {
    // background:
    //   "url(https://cdn1.eegeo.com/blog-content/20170212-sitepoint/leaflet-osm.png)",
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pageWrapper: {
    display: 'flex',
    flex: 1,
    padding: '80px 32px 0 288px',

    [theme.breakpoints.down('sm')]: {
      padding: '120px 8px 0px',
    },
  },
}));
