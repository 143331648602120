import React, { useCallback, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { AddPhotoAlternate, DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useStyles } from './ImageUpload.styles';
import UserGallery from '../../layout/UserGallery';

export function ImagePreview({ file, disabled, text }) {
  const classes = useStyles();
  const [fileLoaded, setFileLoaded] = useState(false);

  if (file && file.id !== '') {
    return (
      <div className={classes.dropzoneThumb}>
        {!fileLoaded && (
          <CircularProgress
            style={{ margin: 'auto' }}
            data-test-id="progress"
          />
        )}
        <img
          data-test-id={file?.name}
          alt="Image preview"
          src={file?.url}
          onLoad={() => setFileLoaded(true)}
          className={clsx(classes.dropzoneImg, {
            [classes.showImage]: !fileLoaded,
          })}
        />
      </div>
    );
  }

  return (
    <section
      className={clsx(classes.dropzone, {
        [classes.dropzoneDisabled]: disabled,
      })}
    >
      <AddPhotoAlternate
        style={{
          fontSize: 24,
          fill: `${disabled ? 'rgba(0,0,0,0.3)' : '#333'}`,
        }}
      />
      {!!text && (
        <p
          style={{
            color: `${disabled ? 'rgba(0,0,0,0.3)' : '#333'}`,
            marginTop: 4,
            fontSize: '14px',
          }}
        >
          {text}
        </p>
      )}
    </section>
  );
}

ImagePreview.propTypes = {
  file: PropTypes.object,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

function ImageUpload({
  disabled,
  setFile,
  file,
  text,
  componentSize,
  imageSize,
}) {
  const classes = useStyles();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const handleDeleteImage = useCallback(
    (e) => {
      e.stopPropagation();
      setFile([]);
    },
    [setFile]
  );

  const handleCloseGallery = () => {
    setIsGalleryOpen(false);
  };

  const handleOpenGallery = () => {
    if (!disabled) setIsGalleryOpen(true);
  };

  return (
    <Box>
      <Box
        data-test-id="drop-area"
        className={classes.image}
        onClick={handleOpenGallery}
        style={componentSize}
      >
        <ImagePreview file={file} disabled={disabled} text={text} />
        {file?.id && !disabled && (
          <Box
            display="block"
            height="0"
            alignSelf="flex-end"
            onClick={handleDeleteImage}
            style={{
              cursor: 'pointer',
              height: '0',
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            data-test-id="delete-logo-image"
          >
            <Box className={classes.deleteLogo}>
              <DeleteOutline
                style={{
                  fontSize: 14,
                  fill: '#FF3100',
                  filter: 'drop-shadow( 1px 1px 2px #fff)',
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      {isGalleryOpen && (
        <UserGallery
          open={isGalleryOpen}
          onClose={handleCloseGallery}
          setFile={setFile}
          {...imageSize}
        />
      )}
    </Box>
  );
}

ImageUpload.propTypes = {
  setFile: PropTypes.func.isRequired,
  file: PropTypes.object,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  componentSize: PropTypes.objectOf(PropTypes.string),
  imageSize: PropTypes.objectOf(PropTypes.number),
};

ImageUpload.defaultProps = {
  file: undefined,
  disabled: false,
  text: undefined,
  componentSize: { height: '165px', width: '315px' },
  imageSize: { height: 330, width: 630 },
};

export default ImageUpload;
