export const isValidUrl = (url) => {
  try {
    const { protocol, host, username, password, port } = new URL(
      url.split(':').length > 1 ? url : `http://${url}`
    );

    const parsedHost = host.split('.');
    const format = /[ `!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;

    return (
      parsedHost.length >= 2 &&
      parsedHost.every((item) => item.length >= 2 && !format.test(item)) &&
      !username &&
      !password &&
      !port &&
      ['http:', 'https:'].includes(protocol)
    );
  } catch {
    return false;
  }
};
