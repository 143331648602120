import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, List, ListItem, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Skeleton } from '@material-ui/lab';
import { ArrowBackRounded } from '@material-ui/icons';
import { useStyles } from './RestaurantMenu.styles';
import { useRestaurantMenu } from '../../../context/RestaurantMenuContext';
import { WithFallback } from '../WithFallback/WithFallback';
import StatusModal from '../../input/StatusModal';

export function MenuItem({
  label,
  component,
  setPage,
  setSelectedSubItem,
  selectedSubItem,
}) {
  const classes = useStyles();

  return (
    <>
      <ListItem
        data-test-id="q6x9QRqq--4FpA6HoC5V_"
        onClick={setPage}
        className={clsx(classes.listItem, {
          [classes.active]: component?.label === label,
        })}
      >
        <p className={classes.textMenu}>{label}</p>
      </ListItem>
      {component?.label === label && component?.subItems.length > 0 && (
        <Box marginBottom="6px">
          {component?.subItems.map((subItem, i) => (
            <ListItem
              data-test-id="IhVYXIPaxSXxKxUI55874"
              key={subItem.label}
              onClick={() => setSelectedSubItem(i)}
              className={clsx(classes.listSubitem, {
                [classes.active]: selectedSubItem === i,
              })}
            >
              <p className={classes.textMenu}>{subItem.label}</p>
              {!!subItem?.warning && (
                <p className={clsx(classes.textMenu, classes.stripeWarning)}>
                  !
                </p>
              )}
            </ListItem>
          ))}
        </Box>
      )}
    </>
  );
}

MenuItem.propTypes = {
  label: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  component: PropTypes.shape({
    label: PropTypes.string,
    component: PropTypes.node,
    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        component: PropTypes.node,
      })
    ),
  }),
  setPage: PropTypes.func,
  setSelectedSubItem: PropTypes.func,
  selectedPage: PropTypes.number,
  selectedSubItem: PropTypes.number,
};

export function InfoSkeleton() {
  return (
    <Box flex="1">
      <Skeleton />
      <Skeleton />
    </Box>
  );
}

export function RestaurantInformation({ restaurant, loading }) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flex="1"
      justifyContent="start"
      marginLeft="16px"
      paddingRight="16px"
    >
      <ArrowBackRounded style={{ marginRight: '8px' }} />
      <WithFallback isLoading={loading} fallback={<InfoSkeleton />}>
        <Box>
          <Typography className={classes.restaurantName}>
            {restaurant?.name}
          </Typography>
          <Typography className={classes.restaurantAddress}>
            {restaurant?.address.formattedAddress}
          </Typography>
        </Box>
      </WithFallback>
    </Box>
  );
}

RestaurantInformation.propTypes = {
  loading: PropTypes.bool,
  restaurant: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      formattedAddress: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
  }),
};

export function RestaurantMenu({ mobile }) {
  const classes = useStyles();
  const {
    restaurant,
    loading,
    component,
    setPage,
    setSelectedSubItem,
    selectedSubItem,
    edit,
    setError,
  } = useRestaurantMenu();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClickBack = () => {
    if (!edit) {
      history.push('/restaurants');
    }
  };

  const handleChangeTab = (index) => {
    setError(false);
    if (!edit) {
      setPage(index);
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.mobile]: mobile,
        [classes.editModeWrapper]: edit,
      })}
    >
      <div
        className={clsx({
          [classes.editMode]: edit,
        })}
      >
        <Box
          onClick={handleClickBack}
          data-test-id="title"
          className={classes.titleWrapper}
        >
          <RestaurantInformation restaurant={restaurant} loading={loading} />
        </Box>
        <List component="nav" style={{ padding: '0px' }}>
          <Button
            data-test-id="ioOWqRob8ds1-8JvoinoR"
            onClick={() => setOpen(true)}
            disabled={!restaurant?.ready}
            className={clsx(classes.listItem, classes.statusBtn)}
          >
            <span
              className={clsx(classes.statusIcon, {
                [classes.draftStatus]: restaurant?.status === 'Test Mode',
                [classes.activeStatus]: restaurant?.status === 'Active',
                [classes.inactiveStatus]: restaurant?.status === 'Inactive',
              })}
            />
            <Typography className={classes[restaurant?.publishStatus]}>
              {restaurant?.status}
            </Typography>
          </Button>
          <MenuItem
            data-test-id="first:nav"
            label="Restaurant Info"
            component={component}
            setPage={() => handleChangeTab(0)}
            setSelectedSubItem={setSelectedSubItem}
            selectedSubItem={selectedSubItem}
          />
          <MenuItem
            data-test-id="second:nav"
            label="Setup Info"
            component={component}
            setPage={() => handleChangeTab(1)}
            setSelectedSubItem={setSelectedSubItem}
            selectedSubItem={selectedSubItem}
          />
          <MenuItem
            data-test-id="third:nav"
            label="Business Hours"
            component={component}
            setPage={() => handleChangeTab(2)}
            setSelectedSubItem={setSelectedSubItem}
            selectedSubItem={selectedSubItem}
          />
        </List>
        <StatusModal open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}

RestaurantMenu.propTypes = {
  mobile: PropTypes.bool,
};
