import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavigationStepper } from '../NavigationStepper';
import { useStyles } from './AddRestaurantForm.styles';

import { RestaurantInfo } from './Steps/RestaurantInfo';
import { RestaurantOwnerInfo } from './Steps/RestaurantOwnerInfo';
import { BusinessHours } from './Steps/BusinessHours';
import { PriceSetup } from './Steps/PriceSetup';
import { UploadMenu } from './Steps/UploadMenu';
import { WebContent } from './Steps/WebContent';
import { HomePageSlider } from './Steps/HomePageSlider';
import { FooterInfo } from './Steps/FooterInfo';
import { AppSetup } from './Steps/AppSetup';
import { Connect } from './Steps/Connect';
import { Connected } from './Steps/Connected';
import { Complete } from './Steps/Complete';

import {
  useNavigation,
  stepsDefinition,
} from '../NavigationStepper/context/navigation';
import { getBusiness, getTemplate } from '../../../services/RestaurantsService';

import storage from '../../../utils/storage';

import { useAddRestaurantContext } from '../../../context/AddRestaurantContext';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import { useGetErrors } from '../../../hooks/useGetErrors/useGetErrors';
import { DELAY } from '../../../constants/store';
import { isValidUrl } from '../../../utils/isValidUrl';
import { Loading } from '../../layout/Loading/Loading';

const shiftSchema = yup.object().shape({
  open: yup.number().required(),
  close: yup.number().required().moreThan(yup.ref('open')),
});

const partialSchema = yup.object().shape({
  open: yup.bool(),
  shift: yup
    .array()
    .when('open', { is: true, then: yup.array().of(shiftSchema) }),
});

const FormSchema = yup.object().shape({
  business: yup.object().shape({
    monday: partialSchema,
    tuesday: partialSchema,
    wednesday: partialSchema,
    thursday: partialSchema,
    friday: partialSchema,
    saturday: partialSchema,
    sunday: partialSchema,
  }),
  delivery: yup.object().shape({
    same: yup.bool(),
    monday: yup.object().when('same', { is: false, then: partialSchema }),
    tuesday: yup.object().when('same', { is: false, then: partialSchema }),
    wednesday: yup.object().when('same', { is: false, then: partialSchema }),
    thursday: yup.object().when('same', { is: false, then: partialSchema }),
    friday: yup.object().when('same', { is: false, then: partialSchema }),
    saturday: yup.object().when('same', { is: false, then: partialSchema }),
    sunday: yup.object().when('same', { is: false, then: partialSchema }),
  }),
  takeout: yup.object().shape({
    same: yup.bool(),
    monday: yup.object().when('same', { is: false, then: partialSchema }),
    tuesday: yup.object().when('same', { is: false, then: partialSchema }),
    wednesday: yup.object().when('same', { is: false, then: partialSchema }),
    thursday: yup.object().when('same', { is: false, then: partialSchema }),
    friday: yup.object().when('same', { is: false, then: partialSchema }),
    saturday: yup.object().when('same', { is: false, then: partialSchema }),
    sunday: yup.object().when('same', { is: false, then: partialSchema }),
  }),
  haveSite: yup.bool().required(),
  site: yup.string().when('haveSite', {
    is: true,
    then: yup
      .string()
      .test('is-url-valid', 'Insert a valid web site', (value) =>
        isValidUrl(value)
      )
      .required('Web Site required'),
  }),
  name: yup.string().required('Restaurant name is required'),
  businessId: yup.string().required('Business ID is required'),
  description: yup.string().required('Restaurant description is required'),
  address: yup.object().shape({
    formattedAddress: yup.string().required('Restaurant address is required'),
  }),
  phone: yup.string().required('Restaurant phone is required'),
  ownerTab: yup.number().required('Select a valid tab'),
  appSetupTab: yup.number().required('Select a valid tab'),
  loginOwnerEmail: yup
    .string()
    .email('Provide a valid email')
    .required('Owner email is required'),
  loginOwnerPassword: yup.string().required('Password is required'),
  ownerFirstName: yup.string().required('Owner first name is required'),
  ownerLastName: yup.string().required('Owner last name is required'),
  ownerPhoneNumber: yup.string().required('Main phone number is required'),
  ownerEmail: yup
    .string()
    .email('Provide a valid email')
    .required('Owner email is required'),
  ownerPassword: yup.string().required('Password is required'),
  appUserFirstName: yup.string().required('App User first name is required'),
  appUserLastName: yup.string().required('App User last name is required'),
  appUserPhoneNumber: yup.string().required('App User phone is required'),
  appUserEmail: yup
    .string()
    .email('Provide a valid email')
    .required('App User email is required'),
  appUserPassword: yup.string().required('Password is required'),
  appEmail: yup.string().required('User is required'),
  appPassword: yup.string().required('Password is required'),
  slider: yup.array().of(
    yup.object().shape({
      sliderTitle: yup.string().required('Slider title is required'),
    })
  ),
  fee: yup.object().shape({
    adjustments: yup.object().shape({
      delivery: yup.number().moreThan(-1).lessThan(101).required(),
      takeout: yup.number().moreThan(-1).lessThan(101).required(),
    }),
    markups: yup.object().shape({
      delivery: yup.number().moreThan(-1).lessThan(101).required(),
      takeout: yup.number().moreThan(-1).lessThan(101).required(),
    }),
  }),
  meta: yup.object().shape({
    title: yup.string().required('Restaurant title is required'),
    description: yup.string().required('Restaurant description is required'),
    keywords: yup.string().required('Restaurant keywords is required'),
    additional: yup.array().of(
      yup.object().shape({
        field: yup.string().required('Additional field is required'),
        info: yup.string().required('Additional info is required'),
      })
    ),
  }),
  tags: yup.array().required('Add at least one tag').min(1),
  menu_url: yup
    .string()
    .test(
      'is-url-valid',
      'Insert a valid web site',
      (value) => !value || isValidUrl(value)
    )
    .nullable(),
  menu: yup.array().when('menu_url', {
    is: (menu_url) => !menu_url,
    then: yup.array().required().min(1),
  }),
  cuisine: yup.string().required('Add cuisine type').nullable(),
});

export const initial = {
  name: '',
  businessId: '',
  address: {
    formattedAddress: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    location: {
      lat: 0,
      lng: 0,
    },
  },
  description: '',
  phone: '',
  ownerTab: 0,
  appSetupTab: 0,
  loginOwnerEmail: '',
  loginOwnerPassword: '',
  ownerFirstName: '',
  ownerLastName: '',
  ownerPhoneNumber: '',
  ownerEmail: '',
  ownerPassword: '',
  haveSite: false,
  site: '',
  appUserFirstName: '',
  appUserLastName: '',
  appUserPhoneNumber: '',
  appUserEmail: '',
  appUserPassword: '',
  business: {
    monday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    tuesday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    wednesday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    thursday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    friday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    saturday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    sunday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
  },
  delivery: {
    same: true,
    monday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    tuesday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    wednesday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    thursday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    friday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    saturday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    sunday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
  },
  takeout: {
    same: true,
    monday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    tuesday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    wednesday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    thursday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    friday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    saturday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
    sunday: {
      open: true,
      shift: [
        {
          open: 480,
          close: 900,
        },
      ],
    },
  },
  appEmail: '',
  appPassword: '',
  slider: [
    {
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    },
    {
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    },
    {
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    },
  ],
  fees: {
    adjustments: {
      delivery: 80,
      takeout: 95,
    },
    markups: {
      delivery: 0,
      takeout: 0,
    },
  },
  meta: {
    title: '',
    description: '',
    keywords: '',
    additional: [],
  },
  menu: [],
  logo: [],
  favicon: [],
  tags: [],
  rawTags: '',
  cuisine: '',
};

export function StepComponent({
  back,
  skip,
  goToRestaurants,
  disableNext,
  stepValidation,
  children,
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.component, {
        [classes.removeJustify]: disableNext && goToRestaurants,
      })}
    >
      {children.length > 0 ? (
        children.map((component) => (
          <div key={component.key} className={classes.container}>
            {component}
          </div>
        ))
      ) : (
        <div className={classes.container}>{children}</div>
      )}
      <NavigationStepper
        back={back}
        skip={skip}
        goToRestaurants={goToRestaurants}
        disableNext={disableNext}
        stepValidation={stepValidation}
      />
    </div>
  );
}

StepComponent.propTypes = {
  children: PropTypes.node,
  back: PropTypes.bool,
  skip: PropTypes.object,
  goToRestaurants: PropTypes.bool,
  disableNext: PropTypes.bool,
  stepValidation: PropTypes.func,
};

export function AddRestaurantForm() {
  const { step } = useNavigation();
  const classes = useStyles();
  const [template, setTemplate] = useState({});
  const [businessId, setBusinessId] = useState([]);
  const [loadError, setLoadError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: initial,
    resolver: yupResolver(FormSchema),
  });

  const {
    reset,
    trigger,
    formState: { errors },
    getValues,
  } = methods;

  const { getErrors } = useGetErrors({ errors });

  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const setStorage = async (data) => {
    await storage.set('restaurantInfo', data, DELAY);
  };

  const triggerValidation = async (fields) => await trigger(fields);

  const formObj = { ...methods, getErrors, template, businessId };

  const { enqueueSnackbar } = useNotifications();
  const { login, register, appSetupData } = useAddRestaurantContext();

  const appSetupStepValidation = async () => {
    if (appSetupData?.isAuthenticated) return true;
    const { appSetupTab } = getValues();
    let fieldValidation;

    if (appSetupTab === 0)
      fieldValidation = await triggerValidation(['appEmail', 'appPassword']);
    else
      fieldValidation = await triggerValidation([
        'appUserFirstName',
        'appUserLastName',
        'appUserPhoneNumber',
        'appUserEmail',
        'appUserPassword',
      ]);

    if (fieldValidation) {
      const {
        appEmail,
        appPassword,
        appUserFirstName,
        appUserLastName,
        appUserPhoneNumber,
        appUserEmail,
        appUserPassword,
      } = getValues();
      try {
        if (appSetupTab === 0) {
          await login('appSetup', appEmail, appPassword);
        } else {
          await register('appSetup', {
            firstname: appUserFirstName,
            lastname: appUserLastName,
            phone: appUserPhoneNumber,
            username: appUserEmail,
            email: appUserEmail,
            password: appUserPassword,
          });
        }
        return true;
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
        return false;
      }
    }
    return false;
  };

  const ownerStepValidation = async () => {
    const { ownerTab } = getValues();
    let fieldValidation;

    if (ownerTab === 0)
      fieldValidation = await triggerValidation([
        'loginOwnerEmail',
        'loginOwnerPassword',
      ]);
    else
      fieldValidation = await triggerValidation([
        'ownerFirstName',
        'ownerLastName',
        'ownerPhoneNumber',
        'ownerEmail',
        'ownerPassword',
      ]);

    if (fieldValidation) {
      const {
        loginOwnerEmail,
        loginOwnerPassword,
        ownerFirstName,
        ownerLastName,
        ownerPhoneNumber,
        ownerEmail,
        ownerPassword,
      } = getValues();
      try {
        if (ownerTab === 0) {
          await login('owner', loginOwnerEmail, loginOwnerPassword);
        } else {
          await register('owner', {
            firstname: ownerFirstName,
            lastname: ownerLastName,
            phone: ownerPhoneNumber,
            username: ownerEmail,
            email: ownerEmail,
            password: ownerPassword,
          });
        }

        return true;
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
        return false;
      }
    }
    return false;
  };

  const shouldStoreInfo = {
    [stepsDefinition.INFO]: true,
    [stepsDefinition.LOGIN]: true,
    [stepsDefinition.HOURS]: true,
    [stepsDefinition.PRICE_LEVEL]: true,
    [stepsDefinition.UPLOAD_MENU]: true,
    [stepsDefinition.SEO_CONTENT]: true,
    [stepsDefinition.APP_SETUP]: true,
    [stepsDefinition.SETUP_SUCCESS]: true,
    [stepsDefinition.STRIPE_CONNECT]: false,
    [stepsDefinition.STRIPE_SUCCESS]: false,
  };

  const selectedForm = {
    [stepsDefinition.INFO]: (
      <StepComponent
        stepValidation={() =>
          triggerValidation([
            'name',
            'businessId',
            'phone',
            'address',
            'tags',
            'cuisine',
            'haveSite',
            'site',
            'description',
          ])
        }
      >
        <RestaurantInfo form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.LOGIN]: (
      <StepComponent back stepValidation={() => ownerStepValidation()}>
        <RestaurantOwnerInfo form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.HOURS]: (
      <StepComponent
        back
        stepValidation={() =>
          triggerValidation(['business', 'takeout', 'delivery'])
        }
      >
        <BusinessHours form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.PRICE_LEVEL]: (
      <StepComponent stepValidation={() => triggerValidation(['fees'])} back>
        <PriceSetup form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.UPLOAD_MENU]: (
      <StepComponent
        stepValidation={() => triggerValidation(['menu', 'menu_url'])}
        back
        skip={{ can: true, skip: 1 }}
      >
        <UploadMenu form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.SEO_CONTENT]: (
      <StepComponent
        stepValidation={() => triggerValidation(['meta', 'slider'])}
        back
        skip={{ can: true, skip: 1 }}
      >
        <WebContent key="content" form={formObj} />
        <HomePageSlider key="slider" form={formObj} />
        <FooterInfo key="footer" form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.APP_SETUP]: (
      <StepComponent
        stepValidation={() => appSetupStepValidation()}
        back
        skip={{ can: true, skip: 1 }}
      >
        <AppSetup form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.SETUP_SUCCESS]: (
      <StepComponent>
        <Complete form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.STRIPE_CONNECT]: (
      <StepComponent back disableNext goToRestaurants>
        <Connect form={formObj} />
      </StepComponent>
    ),
    [stepsDefinition.STRIPE_SUCCESS]: (
      <StepComponent disableNext goToRestaurants>
        <Connected form={formObj} />
      </StepComponent>
    ),
  };

  const selected = useMemo(
    () => selectedForm[step.index],
    [step, selectedForm]
  );

  const loadConfigInfo = async () => {
    setIsLoading(true);
    try {
      await getTemplate().then((data) => {
        setTemplate(data);
      });
      await getBusiness().then((data) => {
        setBusinessId(data);
      });
      setLoadError(false);
    } catch {
      setLoadError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    storage
      .get('restaurantInfo')
      .then((data) => {
        const initialData = data || initial;
        reset(initialData);
        setIsFirstLoading(false);
      })
      .catch(() => {
        setIsFirstLoading(false);
      });
    loadConfigInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shouldStoreInfo[step.id] && !isFirstLoading) {
      const formValues = {
        ...getValues(),
        loginOwnerPassword: '',
        OwnerPassword: '',
        appPassword: '',
        appUserPassword: '',
      };
      setStorage(formValues);
    }
  }, [step, isFirstLoading, getValues]);

  if (isLoading)
    return (
      <Box className={classes.root}>
        <Loading />
      </Box>
    );

  if (loadError) {
    return (
      <Box className={classes.root}>
        <Box
          p={2}
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography>We had a problem loading this page</Typography>
          <Button
            data-test-id="vziLxq3J2rEWCdCO-68N6"
            padding={2}
            onClick={loadConfigInfo}
          >
            Retry
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <form>{selected}</form>
    </Box>
  );
}
