import { api } from './Api';
import { formatResponse } from '../utils/FormatUtils/formatResponse';
import { AddRestaurantETL } from '../utils/ExtractTransformLoad/AddRestaurantETL';

export const getTemplate = async () => {
  const response = await api.get('/localareamng/restaurant/template');
  return response;
};

export const getBusiness = async () => {
  const response = await api.get('/localareamng/business/all');
  return response;
};

export const getRestaurantList = async () => {
  const response = await api.get('/localareamng/restaurant/all');
  return formatResponse(response, 'restaurants');
};

export const getRestaurant = async ({ id }) => {
  const response = await api.get(`/localareamng/restaurant/${id}`);
  return formatResponse(response, 'restaurant');
};

export const publishRestaurant = async ({ id, publish }) => {
  const response = await api.post(`/localareamng/restaurant/${id}/publish`, {
    publish,
  });
  return response;
};

export const saveRestaurant = async ({ id, data }) => {
  const {
    name,
    address,
    category,
    tags,
    schedule,
    phone,
    media,
    text,
    description,
    domain,
    colors,
  } = await AddRestaurantETL(data);

  const response = await api.post(`/localareamng/restaurant/${id}`, {
    name,
    address,
    category,
    tags,
    schedule,
    text,
    phone,
    media,
    description,
    domain,
    ...(colors?.main && { colors }),
  });
  const formatted = formatResponse({ ...data, ...response }, 'restaurant');

  return formatted;
};
