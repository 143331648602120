import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from './AddEditCoupon.styles';
import { useGetErrors } from '../../../hooks/useGetErrors';
import {
  BasicInfo,
  DateTimeConditions,
  DiscountConditions,
  ServiceTypeConditions,
  UserConditions,
  RestaurantConditions,
  ItemConditions,
} from '../AddEditCouponSections';
import {
  addCoupon,
  couponsConfigRequest,
} from '../../../redux/ducks/CouponDucks';
import PromoConditions from '../AddEditCouponSections/PromoConditions';

const FormSchema = yup.object().shape({
  name: yup.string().required('Add coupon name'),
  code: yup.string().required('Add coupon code'),
  owner: yup.string().required('Add coupon owner'),
  business: yup.string().required('Business ID is required'),
  startDate: yup.date().required('Add start date'),
  endDate: yup.date().required('Add end date'),
  specificDays: yup.bool(),
  days: yup.array(),
  specificHours: yup.bool(),
  startTime: yup.number(),
  endTime: yup.number(),
  delivery: yup.bool(),
  takeout: yup.bool(),
  catering: yup.bool(),
  onePerUse: yup.bool(),
  onlyNewUser: yup.bool(),
  restaurantConditions: yup.string().required('Add restaurant conditions'),
  specificRestaurants: yup.array(),
  specificBrands: yup.array(),
  itemRequired: yup.bool(),
  items: yup.array(),
  categoryRequired: yup.bool(),
  categories: yup.array(),
  freeItems: yup.array(),
  minimumOfItems: yup.number(),
  minimumOfCategories: yup.number(),
  discountType: yup.string().required('Add discount type'),
  discountAmount: yup.number().required('Add discount amount'),
  minOrderAmount: yup.number().required(''),
  discountPercentage: yup.number().required(''),
  promoRequired: yup.bool(),
  promoItems: yup.object().shape({
    title: yup.string().required('Add deal name'),
    offer: yup.string().required('Add deal offer text'),
    description: yup.string(),
    startDate: yup.date().required('Add start date'),
    endDate: yup.date().required('Add end date'),
  }),
});

const startDate = new Date();
const endDate = new Date();
endDate.setDate(startDate.getDate() + 7);

const initial = {
  name: '',
  code: '',
  owner: 'business',
  business: '',
  minOrderAmount: undefined,
  startDate,
  endDate,
  specificDays: false,
  days: [],
  specificHours: false,
  startTime: '480',
  endTime: '480',
  delivery: true,
  takeout: true,
  catering: false,
  onePerUse: false,
  onlyNewUser: false,
  itemRequired: false,
  categoryRequired: false,
  discountType: 'Flat amount',
  restaurantConditions: 'All Restaurants',
  discountPercentage: null,
  discountAmount: null,
  promoRequired: false,
  promoItems: {
    title: '',
    offer: '',
    description: '',
    startDate,
    endDate,
  },
};

const daysMapping = {
  Sunday: 6,
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
};

const formToJson = (business, formData) => {
  const couponData = {
    business: formData.business || business.id,
    name: formData.name,
    code: formData.code,
    owner: formData.owner,
    rules: {},
    effects: {},
  };

  // Rules
  if (formData.onePerUse === true || formData.onlyNewUser === true) {
    couponData.rules.customer = {
      one_time_use: formData.onePerUse,
      only_new_customers: formData.onlyNewUser,
    };
  }

  if (formData.singleUse === true) {
    couponData.rules.one_time = true;
  }

  if (formData.specificDays === true && formData.days.length > 0) {
    couponData.rules.week_days = [];
    formData.days.forEach((dayName) => {
      couponData.rules.week_days.push(daysMapping[dayName]);
    });
    couponData.rules.week_days = couponData.rules.week_days.sort();
  }

  couponData.rules.service_types = [];
  if (formData.takeout === true) {
    couponData.rules.service_types.push('takeout');
  }
  if (formData.delivery === true) {
    couponData.rules.service_types.push('delivery');
  }

  if (
    !_.isNil(formData.minOrderAmount) &&
    parseFloat(formData.minOrderAmount) > 0
  ) {
    couponData.rules.min_order_amount = parseFloat(formData.minOrderAmount);
  }

  if (!_.isNil(formData.startDate) && !_.isNil(formData.endDate)) {
    couponData.rules.validity = {
      from: Math.floor(formData.startDate / 1000),
      to: Math.floor(formData.endDate / 1000),
    };
  }

  if (
    formData.specificHours === true &&
    !_.isNil(formData.startTime) &&
    !_.isNil(formData.endTime)
  ) {
    couponData.rules.time_range = {
      from: parseInt(formData.startTime, 10),
      to: parseInt(formData.endTime, 10),
    };
  }

  if (
    !_.isNil(formData.specificRestaurants) &&
    formData.specificRestaurants.length > 0
  ) {
    couponData.rules.restaurants = [];
    formData.specificRestaurants.forEach((restaurantData) => {
      couponData.rules.restaurants.push(restaurantData.id);
    });
    couponData.rules.restaurants = couponData.rules.restaurants.sort();

    if (
      (!_.isNil(formData.items) && formData.items.length > 0) ||
      (!_.isNil(formData.categories) && formData.categories.length > 0)
    ) {
      couponData.rules.menu = {
        minimum: 1,
      };

      if (!_.isNil(formData.items) && formData.items.length > 0) {
        couponData.rules.menu.items = [];

        formData.items.forEach((item) => {
          couponData.rules.menu.items.push(item.id);
        });

        if (
          !_.isNil(formData.minimumOfItems) &&
          parseInt(formData.minimumOfItems, 10) > 1
        ) {
          couponData.rules.menu.minimum = parseInt(formData.minimumOfItems, 10);
        }
      } else if (
        !_.isNil(formData.categories) &&
        formData.categories.length > 0
      ) {
        couponData.rules.menu.categories = [];
        formData.categories.forEach((category) => {
          couponData.rules.menu.categories.push(category.id);
        });

        if (
          !_.isNil(formData.minimumOfCategories) &&
          parseInt(formData.minimumOfCategories, 10) > 1
        ) {
          couponData.rules.menu.minimum = parseInt(
            formData.minimumOfCategories,
            10
          );
        }
      }
    }
  }

  // Effects
  if (
    !_.isNil(formData.discountAmount) &&
    parseFloat(formData.discountAmount) > 0
  ) {
    couponData.effects.flat_discount = parseFloat(formData.discountAmount);
  }

  if (
    !_.isNil(formData.discountPercentage) &&
    parseFloat(formData.discountPercentage) > 0
  ) {
    couponData.effects.percentage_discount =
      parseFloat(formData.discountPercentage) * 0.01;
  }

  if (!_.isNil(formData.freeItems) && formData.freeItems.length > 0) {
    couponData.effects.free_items = [];
    formData.freeItems.forEach((item) => {
      couponData.effects.free_items.push(item.id);
    });
  }

  if (
    !_.isNil(formData.discountType) &&
    formData.discountType === 'Free delivery'
  ) {
    couponData.effects.free_delivery = true;
  }

  return couponData;
};

const jsonToForm = (jsonData) => {
  const couponData = initial;

  if (!_.isNil(jsonData)) {
    if (!_.isNil(jsonData.name)) {
      couponData.name = jsonData.name;
    }
    if (!_.isNil(jsonData.code)) {
      couponData.code = jsonData.code;
    }
    if (!_.isNil(jsonData.owner)) {
      couponData.owner = jsonData.owner;
    }
    if (!_.isNil(jsonData.business)) {
      couponData.business = jsonData.business;
    }
  }

  return couponData;
};

function AddEditCoupon({ onClose, setPromoData }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { couponToAdd, currentBusiness, couponsConfig, restaurantList } =
    useSelector(({ coupon, user }) => ({
      couponToAdd: coupon.couponToAdd,
      couponsConfig: coupon.config,
      currentBusiness: user.currentBusiness,
      restaurantList: coupon.config.restaurants,
    }));

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: jsonToForm(couponToAdd),
    resolver: yupResolver(FormSchema),
  });

  const handleSave = () => {
    if (hasAnyError()) {
      console.error('ERRORS', formValues);
    }

    const formValues = getValues();

    const requestData = formToJson(currentBusiness, formValues);

    dispatch(
      addCoupon({
        couponToAdd: requestData,
      })
    );

    if (formValues.promoRequired) {
      setPromoData({ ...formValues.promoItems, id: formValues.business });
    } else {
      setPromoData({});
    }
  };

  const {
    getValues,
    watch,
    formState: { errors },
  } = methods;

  const { getErrors, hasAnyError } = useGetErrors({ errors });

  const formObj = { ...methods, getErrors };

  watch();

  useEffect(() => {
    if (_.isNil(couponToAdd)) {
      console.info('Coupon Added');
    }
  }, [couponToAdd]);

  const updateCouponConfig = (id) => {
    dispatch(
      couponsConfigRequest({
        businessId: id,
      })
    );
  };

  function SectionDivider({ children }) {
    return (
      <Box className={classes.dividerContainer}>
        {!!children && (
          <Typography className={classes.dividerTitle}>{children}</Typography>
        )}
        <span className={classes.divider} />
      </Box>
    );
  }

  SectionDivider.propTypes = {
    children: PropTypes.node,
  };

  return (
    <form style={{ display: 'flex', flex: 1 }}>
      <Box className={classes.root} data-test-id="add-new-coupon-form">
        <Box className={classes.header}>
          <Typography className={classes.title}>Add new coupon</Typography>
          <Box>
            <Button
              className={classes.cancelBtn}
              data-test-id="cancel-add-new-coupon-btn"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              data-test-id="hAdvZGW4Tmx1ehiKy0hyu"
              className={classes.saveBtn}
              onClick={handleSave}
            >
              Save Coupon
            </Button>
          </Box>
        </Box>
        <SectionDivider>Basic Information</SectionDivider>

        <BasicInfo
          form={formObj}
          updateCouponConfig={(id) => updateCouponConfig(id)}
        />

        <SectionDivider>Validation Conditions</SectionDivider>
        <Box className={classes.sectionContent}>
          <Typography className={classes.subSectionTitle}>
            Select Date/Time Conditions:
          </Typography>

          <DateTimeConditions form={formObj} />

          <Typography className={classes.subSectionTitle}>
            Select Service Type Conditions:
          </Typography>
          <ServiceTypeConditions form={formObj} />

          <Typography className={classes.subSectionTitle}>
            Select User Conditions:
          </Typography>
          <UserConditions form={formObj} />

          <Typography className={classes.subSectionTitle}>
            Select Restaurant Conditions:
          </Typography>

          <RestaurantConditions
            form={formObj}
            brands={couponsConfig.brands}
            restaurants={restaurantList}
            business={couponsConfig.business}
          />

          <Typography className={classes.subSectionTitle}>
            Select Item Conditions:
          </Typography>
          <Typography className={classes.subSectionDescription}>
            If checked, users will be eligible for a discount only after they
            add to cart selected item
          </Typography>

          <ItemConditions
            items={couponsConfig.items}
            categories={couponsConfig.categories}
            form={formObj}
          />
        </Box>
        <SectionDivider>Discount Value</SectionDivider>
        <DiscountConditions form={formObj} items={couponsConfig.items} />

        <SectionDivider>Feature as a Promo</SectionDivider>
        <PromoConditions form={formObj} />

        <SectionDivider />
        <Box className={classes.footer}>
          <Button
            data-test-id="xJp3m-2W5xXW2HgjxzNHd"
            className={classes.cancelBtn}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-test-id="hv1N8Dojq2GDcCFZSPxd6"
            className={classes.saveBtn}
            onClick={handleSave}
          >
            Save Coupon
          </Button>
        </Box>
      </Box>
    </form>
  );
}

AddEditCoupon.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddEditCoupon;
