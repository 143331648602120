import React, { useCallback, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { PhoneNumberInput } from '../PhoneNumberInput';
import { useStyles } from './RegisterForm.styles';

function RegisterForm({ form: { getErrors, control, setValue }, fieldNames }) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );

  const handleGeneratePassword = () => {
    setValue(
      fieldNames.password,
      Math.random().toString(36).substr(2, 8).toUpperCase()
    );
    setShowPassword(true);
  };

  return (
    <Box display="flex">
      <Box flex={1}>
        <Box className={classes.ownerName}>
          <Box flex={1}>
            <Typography variant="h6" className={classes.formTextFieldLabel}>
              First name
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  id={fieldNames.firstName}
                  placeholder="First Name"
                  fullWidth
                  variant="outlined"
                  className={classes.formInputBox}
                  {...getErrors(fieldNames.firstName)}
                />
              )}
              control={control}
              name={fieldNames.firstName}
            />
          </Box>
          <Box flex={1}>
            <Typography variant="h6" className={classes.formTextFieldLabel}>
              Last name
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Last Name"
                  id={fieldNames.lastName}
                  fullWidth
                  variant="outlined"
                  className={classes.formInputBox}
                  {...getErrors(fieldNames.lastName)}
                />
              )}
              control={control}
              name={fieldNames.lastName}
            />
          </Box>
        </Box>
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Phone number
        </Typography>
        <PhoneNumberInput
          control={control}
          name={fieldNames.phone}
          getErrors={getErrors}
          classes={classes}
        />
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Email
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              id={fieldNames.email}
              placeholder="Type your email"
              type="email"
              className={clsx(classes.formInputBox, classes.lowerCase)}
              variant="outlined"
              fullWidth
              {...getErrors(fieldNames.email)}
            />
          )}
          control={control}
          name={fieldNames.email}
        />
        <Typography variant="h6" className={classes.formTextFieldLabel}>
          Password
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Type your password"
              id={fieldNames.password}
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                'data-test-id': 'login:password-input',
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.showPassword}
                  >
                    <IconButton
                      data-test-id="toggle-password-visibility"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <Visibility data-test-id="visibility" />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className={classes.formInputBox}
              variant="outlined"
              fullWidth
              {...getErrors(fieldNames.password)}
            />
          )}
          control={control}
          name={fieldNames.password}
        />
        <Typography
          data-test-id="generate-random-password"
          className={classes.generatePasswordBtn}
          onClick={handleGeneratePassword}
        >
          Generate random password
        </Typography>
      </Box>
    </Box>
  );
}

RegisterForm.propTypes = {
  form: PropTypes.object,
  fieldNames: PropTypes.objectOf(PropTypes.string),
};

export default RegisterForm;
