import React, { useEffect } from 'react';
import {
  Component,
  useRestaurantMenu,
} from '../../../context/RestaurantMenuContext';
import { Error } from '../../layout/Error/Error';
import { EditRestaurantInfo } from '../../layout/EditRestaurantInfo';
import { EditWebSiteInfo } from '../../layout/EditWebSiteInfo';
import { AddRestaurantContextProvider } from '../../../context/AddRestaurantContext';
import { EditStripe } from '../../layout/EditStripe';
import { useStyles } from './RestaurantPage.styles';
import { EditBusinessHours } from '../../layout/EditBusinessHours';
import { UsersSetup } from '../../layout/UsersSetup';

export function RestaurantPage() {
  const { error, setEdit, restaurant } = useRestaurantMenu();
  const classes = useStyles();

  useEffect(() => {
    if (error) {
      setEdit(false);
    }
  }, [error]);

  if (error) {
    return <Error className={classes.root} />;
  }

  return (
    <AddRestaurantContextProvider>
      <Component
        key={restaurant?.id}
        components={[
          {
            label: 'Restaurant Info',
            component: <EditRestaurantInfo />,
            subItems: [],
          },
          {
            label: 'Setup Info',
            subItems: [
              {
                label: 'Stripe Info',
                component: <EditStripe />,
                warning: !restaurant?.owner?.stripe?.active,
              },
              { label: 'Website Info', component: <EditWebSiteInfo /> },
              { label: 'Users', component: <UsersSetup /> },
            ],
          },
          {
            label: 'Business Hours',
            component: <EditBusinessHours />,
            subItems: [],
          },
        ]}
      />
    </AddRestaurantContextProvider>
  );
}
