import React, { useCallback, useState } from 'react';
import { Box, Typography, Button, Divider } from '@material-ui/core';
import { AddPhotoAlternate } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { useStyles } from './WebsiteImages.styles';
import ImageUpload from '../ImageUpload';
import UserGallery from '../../layout/UserGallery';

export function WebsiteImages({
  disabled = false,
  form: { setValue, getValues },
}) {
  const classes = useStyles();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [files, setFiles] = useState({
    consumer_restaurant_list_logo: [],
    consumer_restaurant_map_logo: [],
    consumer_restaurant_page_logo: [],
    consumer_restaurant_page_background: [],
    consumer_restaurant_checkout_logo: [],
    consumer_restaurant_stripe_logo: [],
    consumer_restaurant_order_success_logo: [],
    consumer_restaurant_tracking_item_logo: [],
    consumer_restaurant_tracking_details_logo: [],
    consumer_restaurant_history_item_logo: [],
    consumer_restaurant_email_logo: [],
    ...getValues(),
  });
  const [selectedImageId, setSelectedImageId] = useState(undefined);

  const handleSetFile = useCallback(
    (newFile, imageId) => {
      const image = images.find(({ id }) => id === imageId);
      setFiles({ ...files, [imageId]: newFile });
      setValue(image.id, newFile);
    },
    [files]
  );

  const handleOpenGallery = (imageId) => {
    setSelectedImageId(imageId);
    setIsGalleryOpen(true);
  };

  const images = [
    {
      id: 'consumer_restaurant_list_logo',
      title: 'Restaurant list logo',
      text: 'Upload a 630 x 330 pixel ICO, PNG, GIF, or JPG to display on restaurants list page.',
      imageSize: { height: 330, width: 630 },
      size: { height: '165px', width: '315px' },
      buttonText: 'Add restaurant logo',
      displayDropAreaText: true,
    },
    {
      id: 'consumer_restaurant_map_logo',
      title: 'Restaurant map logo',
      text: 'Upload a 968 x 500 pixel PNG, GIF, or JPG to display on map page.',
      imageSize: { height: 500, width: 968 },
      size: { height: '162px', width: '315px' },
      buttonText: 'Add restaurant map logo',
      displayDropAreaText: true,
    },
    {
      id: 'consumer_restaurant_page_logo',
      title: 'Restaurant page logo',
      text: 'Upload a 350 x 140 pixel PNG, GIF, or JPG to display over the cover image on the restaurant page.',
      imageSize: { height: 140, width: 350 },
      size: { height: '70px', width: '175px' },
      buttonText: 'Add restaurant page logo',
      displayDropAreaText: true,
    },
    {
      id: 'consumer_restaurant_page_background',
      title: 'Restaurant page background',
      text: 'Upload a 1440 x 416 pixel PNG, GIF, or JPG to display as cover image on the restaurant page',
      imageSize: { height: 416, width: 1440 },
      size: { height: '91px', width: '315px' },
      buttonText: 'Add restaurant page cover',
      displayDropAreaText: true,
    },
    {
      id: 'consumer_restaurant_checkout_logo',
      title: 'Checkout page logo',
      text: 'Upload a 256 x 100 pixel PNG, GIF, or JPG to display on the checkout page.',
      imageSize: { height: 100, width: 256 },
      size: { height: '50px', width: '128px' },
      buttonText: 'Add checkout page logo',
      displayDropAreaText: true,
    },
    {
      id: 'consumer_restaurant_stripe_logo',
      title: 'Logo for Stripe',
      text: 'Upload a 256 x 100 pixel PNG, GIF, or JPG to display on Stripe.',
      imageSize: { height: 100, width: 256 },
      size: { height: '50px', width: '128px' },
      buttonText: 'Add restaurant logo',
      displayDropAreaText: false,
    },
    {
      id: 'consumer_restaurant_order_success_logo',
      title: 'Order success logo',
      text: 'Upload a 256 x 100 pixel PNG, GIF, or JPG to display on the order success page.',
      imageSize: { height: 100, width: 256 },
      size: { height: '50px', width: '128px' },
      buttonText: 'Add order success logo',
      displayDropAreaText: true,
    },

    {
      id: 'consumer_restaurant_tracking_item_logo',
      title: 'Order tracking logo',
      text: 'Upload a 96 x 96 pixel PNG, GIF, or JPG to display on the order tracking page.',
      imageSize: { height: 96, width: 96 },
      size: { height: '48px', width: '48px' },
      buttonText: 'Add order tracking logo',
      displayDropAreaText: false,
    },
    {
      id: 'consumer_restaurant_tracking_details_logo',
      title: 'Tracking details logo',
      text: 'Upload a 96 x 96 pixel PNG, GIF, or JPG to display on the tracking details page.',
      imageSize: { height: 96, width: 96 },
      size: { height: '48px', width: '48px' },
      buttonText: 'Add order tracking details logo',
      displayDropAreaText: false,
    },
    {
      id: 'consumer_restaurant_history_item_logo',
      title: 'Restaurant history logo',
      text: 'Upload a 96 x 96 pixel PNG, GIF, or JPG to display on users order history page.',
      imageSize: { height: 96, width: 96 },
      size: { height: '48px', width: '48px' },
      buttonText: 'Add order history page logo',
      displayDropAreaText: false,
    },
    {
      id: 'consumer_restaurant_email_logo',
      title: 'Email logo',
      text: 'Upload a 512 x 512 pixel PNG, GIF, or JPG to display in email sent to users.',
      imageSize: { height: 512, width: 512 },
      size: { height: '256px', width: '256px' },
      buttonText: 'Add email logo',
      displayDropAreaText: true,
    },
  ];

  const getSizes = (imageId) =>
    images.find(({ id }) => id === imageId)?.imageSize;

  return (
    <>
      {images.map((image) => (
        <Box key={image.id}>
          <Box className={classes.infoWrapper}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" className={classes.formTextFieldLabel}>
                {image.title}
              </Typography>
              <ImageUpload
                setFile={(newFile) => handleSetFile(newFile, image.id)}
                file={files[image.id][0]}
                disabled={disabled}
                text={image.displayDropAreaText ? 'Add image' : ''}
                componentSize={image.size}
                imageSize={image.imageSize}
              />
            </Box>
            <Box
              style={{
                marginLeft: '32px',
                width: '60%',
              }}
            >
              <Typography>{image?.text}</Typography>
              <Button
                className={classes.addImageBtn}
                variant="outlined"
                data-test-id="add-image-btn"
                disabled={disabled}
                onClick={() => handleOpenGallery(image.id)}
              >
                <AddPhotoAlternate className={classes.addImageBtnIcon} />
                <Typography className={classes.addImageBtnText}>
                  Add image
                </Typography>
              </Button>
            </Box>
          </Box>
          <Divider />
        </Box>
      ))}
      {isGalleryOpen && (
        <UserGallery
          open={isGalleryOpen}
          onClose={() => setIsGalleryOpen(false)}
          setFile={(newFile) => handleSetFile(newFile, selectedImageId)}
          {...getSizes(selectedImageId)}
        />
      )}
    </>
  );
}

WebsiteImages.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.shape({
    setValue: PropTypes.func,
    getValues: PropTypes.func,
  }),
};
