import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useGetErrors } from '../../../hooks/useGetErrors';

export default function Form({
  defaultValues,
  schema,
  children,
  onSubmit,
  ...rest
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const { getErrors } = useGetErrors({ errors });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...rest}>
      {React.Children.map(children, (child) =>
        child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                control,
                ...getErrors(child.props.name),
                key: child.props.name,
              },
            })
          : child
      )}
    </form>
  );
}

Form.propTypes = {
  defaultValues: PropTypes.any,
  schema: PropTypes.any,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
};
