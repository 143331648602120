import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  header: {
    marginBottom: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '24px',
    marginRight: '16px',
  },
  addCouponBtn: {
    background: '#FFFFFF',
    border: '1px solid #D5D5D5',
    borderRadius: '4px',
    fontWeight: 'bold',
    padding: '8px 14px',
  },
  addCouponBtnIcon: { marginLeft: '8px' },
  table: {},
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: '4fr 4fr 4fr 4fr 4fr 4fr',
    padding: '6px 16px',
  },
  emptyFont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#666666',
    marginTop: '16px',
  },
  columnName: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',

    fontSize: '12px',
    color: '#666666',
  },
  lastColumnName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 'bold',

    fontSize: '12px',
    color: '#666666',
  },
  coupon: {
    padding: '10px 12px',
    width: '100%',

    display: 'grid',
    gridTemplateColumns: '4fr 4fr 4fr 4fr 4fr 4fr',

    background: '#FFFFFF',
    border: '1px solid #D5D5D5',
    borderRadius: '4px',

    marginBottom: '4px',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,

    fontSize: '14px',
    color: '#666666',
  },
  lastCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    fontSize: '14px',
    color: '#666666',
  },
  ml20pc: { marginLeft: '20%' },
  boldText: {
    fontWeight: 'bold',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    color: '#666666',
    '&.Mui-checked': {
      color: '#007AFF',
    },
  },
  actionBtn: {
    margin: 0,
    padding: 0,
    marginLeft: '14px',
  },
  deleteBtnIcon: {
    color: '#FF005C',
  },
  defaultInput: {
    background: '#FAFAFA',
    borderRadius: '2px',
    width: '256px',
    padding: '0px',

    '& fieldset': {
      border: '1px solid #0000001F',
    },

    '& .MuiInputBase-root': { borderRadius: '2px' },
    '& .MuiOutlinedInput-input': { padding: '12px' },
  },
}));
