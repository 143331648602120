import { makeStyles, darken } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    margin: '0 auto',
    padding: '48px 32px',
    maxWidth: '960px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '24px',
    border: '1px solid #E0E0E0',
    width: '100%',
    height: 'max-content',

    [theme.breakpoints.down(732)]: {
      margin: '0 8px',
      maxWidth: 'calc(100% - 16px)',
    },
  },
  formTitle: {
    marginBottom: 32,
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,

    '& > div > input': {
      padding: '14px 16px',
    },
  },
  editButton: {
    background: 'rgba(67, 87, 239, 0.1)',
    color: '#3C55E8',
    borderRadius: '4px',
    fontWeight: 500,
    padding: '6px 12px',
    alignSelf: 'end',
    minWidth: '122px',
    textTransform: 'uppercase',

    '&:hover': {
      background: darken('rgba(67, 87, 239, 0.1)', 0.25),
    },

    [theme.breakpoints.down(735)]: {
      minWidth: 'unset',
      width: '50px',
      height: '50px',
      position: 'fixed',
      bottom: '30px',
      right: '30px',
      borderRadius: '30%',
      zIndex: '400',
      '& > span': {
        display: 'none',
      },
    },
  },
  saveButton: {
    background: '#3C55E8',
    color: '#fff',
    borderRadius: '4px',
    fontWeight: 500,
    marginLeft: '30px',
    padding: '6px 12px',
    alignSelf: 'end',
    minWidth: '122px',
    textTransform: 'uppercase',

    '&:hover': {
      background: darken('#3C55E8', 0.25),
    },

    [theme.breakpoints.down(735)]: {
      minWidth: 'unset',
      width: '50px',
      height: '50px',
      position: 'fixed',
      bottom: '30px',
      right: '30px',
      borderRadius: '30%',
      zIndex: '400',
      '& > span': {
        display: 'none',
      },
    },
  },
}));
