import { useQuery } from 'react-query';
import { getRestaurantList } from '../../../services/RestaurantsService';

export const useRestaurantList = ({
  searchedValue,
  recordsPerPage,
  filterStatus,
}) => {
  const {
    data: resolvedData,
    isFetching,
    isError,
    refetch,
    ...others
  } = useQuery(['restaurant-list'], async () => await getRestaurantList(), {
    placeholderData: [],
    keepPreviousData: true,
  });

  const restaurantList = resolvedData?.filter(
    (restaurant) =>
      restaurant.name.toLowerCase().includes(searchedValue?.toLowerCase()) &&
      filterStatus[restaurant?.status].checked
  );
  const totalPages = Math.ceil((restaurantList?.length || 0) / recordsPerPage);

  return {
    restaurantList,
    totalPages,
    isFetching,
    isError,
    refetch,
    ...others,
  };
};
