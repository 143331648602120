import { parse, startOfDay, differenceInMinutes } from 'date-fns';

export const stringToDayOfWeek = (dayOfWeek) =>
  ({
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
  }[dayOfWeek] ?? -1);

export const hourToMinutesFromMidnight = (hour) => {
  const selectedHourDate = parse(hour, 'HH', new Date());
  const startOfDayDate = startOfDay(selectedHourDate);
  const minutesFromMidnight = differenceInMinutes(
    selectedHourDate,
    startOfDayDate
  );
  return minutesFromMidnight;
};

const formatWorkingHours = (workingHours) => {
  const allShifts = Object.entries(workingHours).map(([dayOfWeek, value]) => {
    const dayOfWeekIndex = stringToDayOfWeek(dayOfWeek);
    if (dayOfWeekIndex === -1) return null;
    if (!value.open) return null;

    return value.shift.map(({ open, close }) => ({
      type: 'weekly',
      day_in_week: dayOfWeekIndex,
      start: open,
      end: close,
    }));
  });

  const flattenArray = [].concat.apply([], allShifts).filter(Boolean);

  return flattenArray;
};

export default formatWorkingHours;
