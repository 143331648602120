import { Box, Typography } from '@material-ui/core';
import { DoneOutlineOutlined } from '@material-ui/icons';
import React, { useEffect } from 'react';
import appSetupStore from '../../../../utils/appSetupStore';
import ownerStore from '../../../../utils/ownerStore';

import { useStyles } from './Steps.styles';

export function Connected() {
  const classes = useStyles();
  useEffect(() => {
    const clearStorage = async () => {
      await ownerStore.remove('user');
      await appSetupStore.remove('user');
    };

    clearStorage();
  }, []);

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        Connect with Stripe
      </Typography>
      <Box
        border="1px solid #E0E0E0"
        borderRadius="12px"
        display="flex"
        padding="20px 24px"
        alignItems="center"
      >
        <DoneOutlineOutlined style={{ fill: '#3C55E8', marginRight: 14 }} />
        <p style={{ color: '#3C55E8' }}>
          You successfully connected with Stripe!
        </p>
      </Box>
    </>
  );
}

export default Connected;
